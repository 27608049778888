import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/UserContextProvider';
import { ButtonContainer } from '../../utils/GlobalStyles';
import { GET_LOGGED_IN_USER } from '../../config';
import { IconButton, InputAdornment } from '@mui/material';

import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import axiosInstance from '../../services/axiosInstance';
import { setUserObject } from '../../states/actions';
import { useDispatch } from 'react-redux';
import { InputBox } from '../../utils/InputBox';

type LoginPageProps = {
    user_email: string;
    setEmail: (val: string) => void;
    user_password: string;
    setPassword: (val: string) => void;
    hasAccount: boolean;
    setHasAccount: (val: boolean) => void;
    clearInputs: () => void;
    reEnterPassword: string;
    setReEnterPassword: (val: string) => void;
    emailError: string;
    setEmailError: (val: string) => void;
    passwordError: string;
    setPasswordError: (val: string) => void;
    clearErrors: () => void;
};

const LoginPage = (props: LoginPageProps) => {
    const { loginUser } = useAuth();
    const dispatch = useDispatch();

    const {
        user_email,
        setEmail,
        user_password,
        setPassword,
        hasAccount,
        setHasAccount,
        clearInputs,
    } = props;

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleKeyDown = (event: { key: string }) => {
        if (event.key === 'Enter') {
            handleLogin(user_email, user_password);
        }
    };

    let navigate = useNavigate();

    const handleLogin = (email: string, password: string) => {
        loginUser(email, password)
            .then(() => {
                axiosInstance
                    .get(GET_LOGGED_IN_USER)
                    .then((res) => {
                        const data = res.data;
                        dispatch(setUserObject(data));
                        if (data.setup_complete === false) {
                            navigate('/first-login', { replace: true });
                        } else {
                            navigate('/home', { replace: true });
                        }
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => {
                switch (err.code) {
                    case 'auth/invalid-email':
                        toast.error('Invalid Email');
                        break;
                    case 'auth/user-disabled':
                        toast.error("User's account has been disabled");
                        break;
                    case 'auth/user-not-found':
                        toast.error("User doesn't exist");
                        break;
                    case 'auth/wrong-password':
                        toast.error('Wrong password');
                        break;
                    default:
                        break;
                }
            });
    };

    return (
        <>
            <Heading>Login Now</Heading>

            <InputBox
                val={user_email}
                setVal={setEmail}
                label={'Enter email'}
                type={'text'}
                itemProps={null}
            />

            <InputBox
                val={user_password}
                setVal={setPassword}
                onKeyDown={handleKeyDown}
                label={'Enter password'}
                type={showPassword ? 'text' : 'password'}
                itemProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end"
                            >
                                {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <ForgotPassword
                    onClick={() => {
                        setHasAccount(!hasAccount);
                        clearInputs();
                    }}
                >
                    Forget Password?
                </ForgotPassword>
            </div>

            <ButtonContainer>
                <LoginButton
                    onClick={() => {
                        handleLogin(user_email, user_password);
                    }}
                >
                    Login
                </LoginButton>
            </ButtonContainer>
        </>
    );
};

const Heading = styled.h3`
    padding: 2% 0;
`;

const ForgotPassword = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;

    &:hover {
        color: #646464;
    }
`;

const LoginButton = styled.button`
    background: #5ea73c;
    width: 100%;
    padding: 12px 8px;
    color: white;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #5ea73c;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
`;

export default LoginPage;
