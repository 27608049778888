import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonContainer, ButtonFooter } from '../../utils/GlobalStyles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/UserContextProvider';

interface Props {
    heading: string;
    text: string;
    subText?: string;
    btnText: string;
    btnColor: string;
    selectedField: {};
    func: () => void;
    handleClose: () => void;
}

const ClearFieldModal = (props: Props) => {
    const { heading, text, btnText, selectedField, func, btnColor } = props;

    return (
        <ClearFieldWrapper>
            <Heading>{heading}</Heading>
            <Text>{text}</Text>
            {props.subText && <Text>{props.subText}</Text>}
            <ButtonCont>
                <ButtonFooter onClick={() => props.handleClose()}>Cancel</ButtonFooter>
                <ClearButton
                    btnColor={btnColor}
                    onClick={() => {
                        func();
                    }}
                >
                    {btnText}
                </ClearButton>
            </ButtonCont>
        </ClearFieldWrapper>
    );
};

const ClearFieldWrapper = styled.div`
    padding: 1em;
    color: #414149;
`;

const Heading = styled.h1`
    margin: 0;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #9ba298;
`;

const Text = styled.p`
    margin: 1em 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
`;

const ClearButton = styled.div<{ btnColor: string }>`
    background: ${(props) => props.btnColor};
    padding: 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

const ButtonCont = styled(ButtonContainer)`
    padding: 1rem 0 0 0;
`;

export default ClearFieldModal;
