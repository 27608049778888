import {
    CREATE_SINGLE_WORKER,
    GET_ALL_WORKERS,
    UPDATE_SINGLE_WORKER,
} from '../../config';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import axiosInstance from '../../services/axiosInstance';
import { selectedUser, userObject } from '../../states/selectors';

async function get_all_workers() {
    const selectedAccId = selectedUser();

    const url = selectedAccId
        ? `${GET_ALL_WORKERS}?uid=${selectedAccId}`
        : GET_ALL_WORKERS;

    return axiosInstance({
        method: 'GET',
        url: url,
    }).then((response) => response.data);
}

const update_worker = (data: {
    category: string | number;
    worker: { [x: string]: any };
    id: string | null;
}) => {
    const selectedAccId = selectedUser();

    const url = selectedAccId
        ? `${UPDATE_SINGLE_WORKER}?uid=${selectedAccId}`
        : UPDATE_SINGLE_WORKER;

    return axiosInstance({
        method: 'POST',
        url: url,
        data: {
            uid: data.id,
            disabled: !data.worker[data.category],
        },
    }).then((res) => res.data);
};

const deleteWorker = (id: string) => {
    return axiosInstance({
        method: 'DELETE',
        url: GET_ALL_WORKERS + '/' + id,
    }).then((res) => res.data);
};

const addWorker = (data: { email: string }) => {
    const selectedAccId = selectedUser();

    const url = selectedAccId
        ? `${CREATE_SINGLE_WORKER}?uid=${selectedAccId}`
        : CREATE_SINGLE_WORKER;

    return axiosInstance({
        method: 'POST',
        url: url,
        data: {
            email: data.email,
        },
    }).then((res) => res.data);
};

export const useWorkers = () => {
    return useQuery('allWorkers', get_all_workers, {
        onError: () => {
            toast.error('Error fetching workers');
        },
    });
};

export const useAddWorker = () => {
    const queryClient = useQueryClient();
    return useMutation(addWorker, {
        onSuccess: () => {
            queryClient.invalidateQueries('allWorkers').then(() => {
                toast.success('Worker added successfully');
            });
        },
    });
};

export const useUpdateWorker = () => {
    const queryClient = useQueryClient();
    return useMutation(update_worker, {
        onSuccess: () => {
            queryClient.invalidateQueries('allWorkers').then(() => {
                toast.success('Worker updated successfully');
            });
        },
    });
};

export const useDeleteWorker = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteWorker, {
        onSuccess: () => {
            queryClient.invalidateQueries('allWorkers');
        },
    });
};
