import {
    ALL_EVENTS,
    HAY_VISION_FIELDS,
    HAY_VISION_FIELDS_BY_ID,
    UPDATE_EVENT,
} from '../../config';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../services/axiosInstance';
import { selectedUser, userObject } from '../../states/selectors';

export function getAllEvents() {
    const uid = selectedUser();
    const url = uid ? `${ALL_EVENTS}?uid=${uid}` : ALL_EVENTS;

    return axiosInstance({
        method: 'GET',
        url: url,
    }).then((response) => response.data);
}

function updateEvent(data: any) {
    const uid = selectedUser();
    const url = uid ? `${UPDATE_EVENT}?uid=${uid}` : UPDATE_EVENT;
    return axiosInstance({
        method: 'POST',
        url: url,
        data: data,
    }).then((response) => response.data);
}

export function useUpdateEvent() {
    const queryClient = useQueryClient();
    return useMutation(updateEvent, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('allEvents');
            queryClient.invalidateQueries('hayVisionEvents');
        },
    });
}

const getHayVisionFieldsById = (id: string) => {
    const uid = selectedUser();
    const url = uid
        ? `${HAY_VISION_FIELDS_BY_ID}/${id}?uid=${uid}`
        : `${HAY_VISION_FIELDS_BY_ID}/${id}`;

    return axiosInstance({
        method: 'GET',
        url: url,
    }).then((response) => response.data);
};

export function useHayVisionFieldsById(id: string) {
    return useQuery(['hayVisionFields', id], () => getHayVisionFieldsById(id), {
        enabled: !!id,
    });
}
