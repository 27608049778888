import React from 'react';
import Map from '../../map/Map';
import EventsProgressBar from '../../EventsProgressBar';
import WorkersNotifiedModal from '../../modal_components/WorkersNotifiedModal';
import EventsSection from './EventsSection';
import styled from 'styled-components';
import { NoEventImg } from '../../screens/home_page/styled';
import ModalComponent from '../../modal/ModalComponent';
import { AnimatePresence } from 'framer-motion';
import { ModalCloseButton, ModalFooter } from '../../../utils/GlobalStyles';
import NO_EVENTS from '../../../static/images/no-events.png';
import { dateComparison, ResetBtn } from '../../screens/home_page/HomePage';
import { useCreateModal } from '../../../utils/useCreateModal';

interface EventsProps {
    fieldId: any;
    hayEvents: any;
    location: any;
    completeCount: number;
    setCompleteCount: any;
    dateComparison: any;
    setLocation: any;
    setZoomLevel: any;
    setMapCenter: any;
    mapCenter: any;
    zoomLevel: number;
    showModal: boolean;
    setShowModal: any;
    hayFields: any;
}

function HayVisionEvents(props: EventsProps) {
    const {
        fieldId,
        showModal,
        setShowModal,
        location,
        completeCount,
        setCompleteCount,
        setLocation,
        setZoomLevel,
        setMapCenter,
        mapCenter,
        zoomLevel,
        hayEvents,
        hayFields,
    } = props;

    const modalNotifyWorker = useCreateModal();

    const [initialBounds, setInitialBounds] = React.useState(null);
    const [mapObjects, setMapObjects] = React.useState(null);
    const [selectedPin, setSelectedPin] = React.useState(null);

    return (
        <>
            <MapSection>
                {!!hayFields && (
                    <Map
                        location={location}
                        setMapCenter={setMapCenter}
                        mapCenter={mapCenter}
                        zoomLevel={zoomLevel}
                        setZoomLevel={setZoomLevel}
                        mapLocations={hayFields}
                        // @ts-ignore
                        mapObjects={mapObjects}
                        setMapObjects={setMapObjects}
                        initialBounds={initialBounds}
                        setInitialBounds={setInitialBounds}
                        selectedPin={selectedPin}
                        setSelectedPin={setSelectedPin}
                    />
                )}
            </MapSection>

            <EventsProgressBar
                allEventsCount={
                    fieldId
                        ? hayEvents?.filter(
                              (event: any) => event.field_id.toString() === fieldId
                          ).length
                        : hayEvents.length
                }
                completeCount={completeCount}
            />

            <EventHeader>
                <EventHeading>HAY VISION EVENTS</EventHeading>
                <EventSubHeading>
                    <ResetBtn
                        mapObjects={mapObjects}
                        initialBounds={initialBounds}
                        setZoomLevel={setZoomLevel}
                        setSelectedPin={setSelectedPin}
                        setMapCenter={setMapCenter}
                    />

                    <span>
                        {completeCount}/{hayEvents.length}
                    </span>
                </EventSubHeading>
            </EventHeader>

            <AllEventSection>
                {hayEvents.length !== 0 ? (
                    hayEvents.sort(dateComparison).map((value: any, index: any) => {
                        return (
                            <EventsSection
                                key={index}
                                value={value}
                                completeCount={completeCount}
                                modalNotifyWorker={modalNotifyWorker}
                                open={modalNotifyWorker.openModal}
                                handleClose={modalNotifyWorker.closeModal}
                                setCompleteCount={setCompleteCount}
                                setLocation={setLocation}
                                location={location}
                                setMapCenter={setMapCenter}
                                mapCenter={mapCenter}
                                setZoomLevel={setZoomLevel}
                            />
                        );
                    })
                ) : (
                    <NoEventImg>
                        <img src={NO_EVENTS} alt={''} />{' '}
                    </NoEventImg>
                )}
            </AllEventSection>

            <AnimatePresence initial={false} mode={'wait'}>
                {modalNotifyWorker.modal && (
                    <ModalComponent
                        key={'modal-worker'}
                        open={modalNotifyWorker.openModal}
                        handleClose={modalNotifyWorker.closeModal}
                        width={'40'}
                        minheight={'400px'}
                        footer={
                            <ModalFooter>
                                <ModalCloseButton onClick={modalNotifyWorker.closeModal}>
                                    Close
                                </ModalCloseButton>
                            </ModalFooter>
                        }
                    >
                        <WorkersNotifiedModal
                            title="Workers Notified"
                            subText={
                                'This is the list of workers who were notified about this event.'
                            }
                            onClose={() => setShowModal(false)}
                            show={showModal}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </>
    );
}

const MapSection = styled.div``;

const AllEventSection = styled.section`
    height: 48vh;
    background: white;
    overflow-y: scroll;
`;

const EventHeader = styled.div`
    display: inline-flex;
    width: 100%;
    height: 6vh;
    background: white;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid #e5e5e5;
`;

const EventHeading = styled.h1`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: 'Oswald', sans-serif;
    color: #9ca499;
    margin: 0;
    padding: 1em;
`;

const SubHeading = styled.p`
    font-size: 14px;
    font-weight: 500;
    margin-top: 0;
    color: #999;
`;

const EventSubHeading = styled(SubHeading)`
    font-size: 12px;
    margin: 0;
    padding: 1em;
    color: #9ca499;
`;

export default HayVisionEvents;
