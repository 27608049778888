import { HAY_VISION_CHARTS_DATA } from '../../config';
import axiosInstance from '../../services/axiosInstance';
import { selectedUser } from '../../states/selectors';
import { useQuery } from 'react-query';

const get_chat_data = (field_id: string, uid: string) => {
    const url = uid
        ? `${HAY_VISION_CHARTS_DATA}/${field_id}?uid=${uid}`
        : `${HAY_VISION_CHARTS_DATA}/${field_id}`;

    return axiosInstance({
        method: 'GET',
        url: url,
    }).then((response) => response.data);
};

export const useGetChatData = (field_id: string) => {
    const selectedAccId = selectedUser();
    return useQuery(
        ['chartData', selectedAccId],
        () => get_chat_data(field_id, selectedAccId),
        {
            refetchOnWindowFocus: false,
            // onError: (data) => {
            // console.log(data);
            // },
        }
    );
};
