import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import SideNav from '../../sidenav/SideNav';
import HayVisionEvents from '../events/HayVisionEvents';
import { WorkersModal } from '../workers/WorkersModal';
import { get_hay_vision_events } from '../../hooks/useHayVision';
import {
    BackArrowIcon,
    BackArrowWrapper,
    ChartLegend,
    Content,
    ContentHeader,
    EventWrapper,
    HayFieldCardWrapper,
    HayFieldContent,
    HayFieldTitle,
    HayTypeContent,
    HeadingSection,
    HRLine,
    MainContent,
    UserGroupIcon,
    UserGroupWrapper,
} from '../styles/hayField';
import { useWorkers } from '../../hooks/useWorkers';
import { HiInformationCircle } from 'react-icons/hi';
import ModalComponent from '../../modal/ModalComponent';
import { ModalCloseButton, ModalFooter } from '../../../utils/GlobalStyles';
import { AnimatePresence } from 'framer-motion';
import { useQuery } from 'react-query';
import { EventsLineChart } from '../charts/EventsLineChart';
import styled from 'styled-components';
import { useHayVisionFieldsById } from '../../hooks/useEvents';
import { options } from './AddField';
import { selectedUser } from '../../../states/selectors';
import { dateComparison } from '../../screens/home_page/HomePage';

function HayField() {
    let navigate = useNavigate();
    const { fieldId } = useParams();
    const selectedAccId = selectedUser();

    const { data: fieldInfo } = useHayVisionFieldsById(fieldId);

    const [showModal, setShowModal] = useState(false);

    const [completeCount, setCompleteCount] = useState(0);

    const [location, setLocation] = useState({
        address: '',
        lat: 38.887653,
        lng: -102.950467,
    });

    const { isSuccess: isALlWorkersSuccess, data: hayVisionWorkers } = useWorkers();

    const [zoomLevel, setZoomLevel] = useState(3);
    const [mapCenter, setMapCenter] = useState(location);

    const [modalOpenWorker, setModalOpenWorker] = useState(false);
    const closeModalWorker = () => setModalOpenWorker(false);
    const openModalWorker = () => setModalOpenWorker(true);

    const {
        isLoading: isHayEventsLoading,
        isError: isHayEventsError,
        data: hayEvents,
    } = useQuery('hayVisionEvents', () => get_hay_vision_events(selectedAccId), {
        onSuccess: (events) => {
            const completeCount = events.filter(
                (eachObject) =>
                    eachObject['status'] === 2 &&
                    eachObject.field_id.toString() === fieldId
            );
            setCompleteCount(completeCount.length);
        },
    });

    return (
        <HayFieldContent>
            <SideNav />
            <MainContent>
                <HeadingSection>
                    <div
                        style={{ display: 'inline-flex', gridArea: '3/1' }}
                        onClick={() => navigate(-1)}
                    >
                        <BackArrowWrapper>
                            <BackArrowIcon /> HAY VISION {'/'} {fieldInfo?.name}
                        </BackArrowWrapper>
                    </div>
                    <div style={{ gridArea: '3/2' }}>
                        <UserGroupWrapper>
                            <UserGroupIcon
                                onClick={() =>
                                    modalOpenWorker
                                        ? closeModalWorker()
                                        : openModalWorker()
                                }
                            />
                        </UserGroupWrapper>
                    </div>
                    {isALlWorkersSuccess && !!hayVisionWorkers ? (
                        <AnimatePresence
                            // Disable any initial animation on children for first render
                            initial={false}
                            mode={'wait'}
                        >
                            {modalOpenWorker && (
                                <ModalComponent
                                    open={openModalWorker}
                                    handleClose={closeModalWorker}
                                    width={'40'}
                                    minheight={'400px'}
                                    footer={
                                        <ModalFooter>
                                            <ModalCloseButton onClick={closeModalWorker}>
                                                Close
                                            </ModalCloseButton>
                                        </ModalFooter>
                                    }
                                >
                                    <WorkersModal
                                        title="WORKERS IN HAY VISION"
                                        subText={''}
                                        workers={hayVisionWorkers}
                                    />
                                </ModalComponent>
                            )}
                        </AnimatePresence>
                    ) : null}
                </HeadingSection>

                <ContentHeader>
                    <HayTypeContent>
                        <p>Hay Type</p>
                        <h4>{fieldInfo && options[fieldInfo?.hay_type - 1].label}</h4>
                    </HayTypeContent>
                    <HayTypeContent>
                        <p>Field Size</p>
                        <h4>{fieldInfo?.acres} Acres</h4>
                    </HayTypeContent>
                </ContentHeader>
                <HRLine />

                <Content>
                    <HayFieldTitle>
                        <h3>
                            ENERGY CURVE ANALYSIS{' '}
                            <span>
                                <HiInformationCircle />
                            </span>
                        </h3>
                    </HayFieldTitle>
                    <HayFieldCardWrapper>
                        <EventsLineChart fieldId={fieldId} />
                    </HayFieldCardWrapper>
                </Content>
            </MainContent>
            <EventWrapper>
                {!isHayEventsLoading && !!hayEvents ? (
                    <HayVisionEvents
                        fieldId={fieldId}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        location={location}
                        completeCount={completeCount}
                        setCompleteCount={setCompleteCount}
                        dateComparison={dateComparison}
                        setLocation={setLocation}
                        setMapCenter={setMapCenter}
                        mapCenter={mapCenter}
                        zoomLevel={zoomLevel}
                        setZoomLevel={setZoomLevel}
                        hayEvents={hayEvents?.filter(
                            (event) => event.field_id.toString() === fieldId
                        )}
                        hayFields={[fieldInfo]}
                    />
                ) : !isHayEventsError ? (
                    <p>Loading... something</p>
                ) : (
                    <>Error</>
                )}
            </EventWrapper>
        </HayFieldContent>
    );
}

export default HayField;
