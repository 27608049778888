import { useState } from 'react';

export const useCreateModal = () => {
    const [modal, setModal] = useState(false);
    const openModal = () => setModal(true);
    const closeModal = () => setModal(false);

    return {
        modal,
        openModal,
        closeModal,
    };
};
