import styled from 'styled-components';
import HAY_VISION from '../../../static/images/hay_vision_new.png';
import BACK_ARROW_OUTLINED from '../../../static/icons/back-arrow-outlined.png';
import USER_GROUP_OUTLINED from '../../../static/icons/users-icon-outlined.png';
import USER_GROUP_FILLED from '../../../static/icons/users-icon-filled.png';
import BACK_ARROW_FILLED from '../../../static/icons/back-arrow-filled.svg';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Menu, MenuItem } from '@mui/material';
import { MdEdit } from 'react-icons/md';
import { FaRegTrashAlt } from 'react-icons/fa';

export const HayFieldContent = styled.div`
    display: grid;
    overflow-y: hidden;
    padding: 0;
    grid-template-columns: 240px 2fr 400px;
    grid-template-rows: auto;
    grid-template-areas:
        'side-nav home-content map-content'
        'side-nav home-content map-content'
        'side-nav home-content map-content';
`;

export const MainContent = styled.div`
    grid-area: home-content;
    background: #ffffff;
    display: flex;
    width: 99%;
    height: 99vh;
    flex-direction: column;
    overflow: hidden;
`;

export const ContentHeader = styled.div`
    width: auto;
    margin: 0 4em;
    display: flex;
    justify-content: left;
    gap: 4em;
    font-family: 'Poppins', sans-serif;
`;

export const Content = styled.div`
    width: auto;
    margin: 0 3em;
    grid-column-gap: 4em;
    grid-row-gap: 2em;
    overflow-y: scroll;
    font-family: 'Poppins', sans-serif;
`;

export const HayFieldTitle = styled.div`
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1em;
    justify-content: space-between;

    > h3,
    > p {
        padding: 0;
        margin: 0;
    }

    > h3 {
        font-family: Oswald, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        margin: 0 1em;
        color: #374630;
        letter-spacing: 0.08em;
        opacity: 0.5;
        display: inline-flex;
        align-items: center;
        gap: 0.5em;
    }

    span {
        font-size: 1.2rem;
    }
`;

export const HayTypeContent = styled.div`
    //width: 100%;
    //display: grid;
    //margin-bottom: 1.5em;
    font-family: 'Poppins', sans-serif;

    p,
    h4 {
        margin: 0;
        color: rgba(55, 70, 48, 0.5);
        font-weight: 600;
        padding: 0;
        font-size: 12px;
    }

    h4 {
        margin-top: 0.4em;
        color: #202029;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }
`;

export const ARROW = styled.span`
    color: transparent;
    margin-right: 14px;
    transition: ease-in-out 0.2s;
`;

export const HeadingSection = styled.div`
    width: auto;
    height: 140px;
    margin: 2em 3em 1.5em;
    box-shadow: rgba(100, 100, 111, 0.3) 0 10px 30px 0;
    padding: 2em;
    border-radius: 10px;
    background-position: center;
    background-image: url(${HAY_VISION});
    background-size: cover;
    background-repeat: no-repeat;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;
`;

export const HayFieldImages = styled.img`
    width: auto;
    height: 100%;
    transition: 0.4s;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.3) 0 10px 30px 0;
`;

HayFieldImages.defaultProps = {
    src: HAY_VISION,
};

export const ComingSoon = styled.img`
    position: absolute;
    top: 50px;
    width: 100%;
    height: auto;
    //z-index: 1000;
    cursor: not-allowed;
`;

export const ItemWrapper = styled.div`
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    color: #9ba298;
    display: grid;
    justify-content: center;
    transition: 0.2s;
    cursor: pointer;
    border-radius: 14px;
    position: relative;

    &:hover ${HeadingSection} > ${ARROW} {
        color: green;
        transform: translateX(12px);
    }

    &:hover > ${HayFieldImages} {
        -ms-transform: scale(1.03); /* IE 9 */
        -webkit-transform: scale(1.03); /* Safari 3-8 */
        transform: scale(1.03);
    }

    &:hover > ${ComingSoon} {
    }

    &:hover {
        color: #282c34;
        box-shadow:
            rgba(0, 0, 0, 0.02) 0 1px 3px 0,
            rgba(27, 31, 35, 0.15) 0 0 0 1px;
    }
`;

export const EventWrapper = styled.div`
    grid-area: map-content;
    background: #efefef;
    border-left: 1px solid #e5e5e5;
`;

export const BackArrowIcon = styled.div`
    background: url(${BACK_ARROW_OUTLINED}) no-repeat;
    background-size: contain;
    width: 52px;
    height: 52px;
    cursor: pointer;
`;

export const UserGroupIcon = styled.div`
    background: url(${USER_GROUP_OUTLINED}) no-repeat;
    background-size: contain;
    width: 52px;
    height: 52px;
    transition: background ease 600ms;
    cursor: pointer;
    &:hover {
        background: url(${USER_GROUP_FILLED}) no-repeat;
        background-size: contain;
    }
`;

export const BackArrowWrapper = styled.span`
    width: max-content;
    padding-right: 10px;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    color: #fff;
    -webkit-transition: background-image 0.2s ease-in-out;
    transition:
        background-image 0.2s ease-in-out,
        background 0.2s ease-in-out;
    cursor: pointer;
    font-weight: 500;

    &:hover {
        background: rgba(255, 255, 255, 0.32);
    }

    &:hover > ${BackArrowIcon} {
        background-image: url(${BACK_ARROW_FILLED});
        background-repeat: no-repeat;
        background-size: contain;
    }
`;
export const UserGroupWrapper = styled.span`
    width: 100%;
    display: inline-flex;
    justify-content: right;
    align-items: center;
    align-content: center;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    font-weight: 500;
`;

export const SearchInput = styled.input`
    background-color: #f4f4f4;
    border: 1px solid #e5e5e5;
    color: #374630;
    border-radius: 5px;
    padding: 5px 20px;
    width: auto;
    transition: 0.2s ease all;
    font-family: 'Poppins', sans-serif;
`;

export const FilterItemOne = styled.button`
    background: #5ea73c;
    color: white;
    letter-spacing: 0.1em;
    border: 1px solid #efefef;
    width: 55px;
    height: 25px;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    transition: 0.2s ease all;
    font-family: 'Poppins', sans-serif;

    &:hover {
        background: #59a137;
    }
`;

export const FilterItemTwo = styled(FilterItemOne)`
    background: white;
    color: #333;
    border: 1px solid #efefef;
    border-radius: 0;

    &:hover {
        background: #5ea73c;
        color: white;
    }
`;

export const FilterItemThree = styled(FilterItemOne)`
    background: white;
    color: #333;
    border: 1px solid #efefef;
    border-radius: 0 4px 4px 0;

    &:hover {
        background: #5ea73c;
        color: white;
    }
`;

export const FilterButtons = styled.div`
    display: inline-flex;
`;

export const HRLine = styled.hr`
    background-color: #e7eaeb;
    color: #e7eaeb;
    opacity: 0.2;
    margin: 1em 0;
`;

export const HayFieldCardWrapper = styled.div`
    //display: grid;
    //grid-template-columns: 1fr 1fr 1fr;
    //grid-template-rows: auto;
    //grid-gap: 2em;
    //overscroll-behavior: contain;
    //overflow-y: scroll;
    //overflow-x: hidden;
    //width: 95%;
    //padding-bottom: 10px;
    //scrollbar-width: none;
    //width: min-content;
`;

export const EditDots = styled(BsThreeDotsVertical)`
    font-size: 20px;
    border-radius: 8px;
    padding: 0.2em;
    transition: all ease 200ms;
`;

export const EditMenuItem = styled.div`
    display: inline-flex;
    justify-content: left;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    width: 100%;
    gap: 0.8em;
`;

export const DeleteMenuItem = styled(MenuItem)`
    color: red !important;

    &:hover {
        background: #ed434314 !important;
    }
`;

export const EditIcon = styled(MdEdit)`
    font-size: 1.1rem !important;
`;

export const DeleteIcon = styled(FaRegTrashAlt)`
    font-size: 1rem !important;
`;

export const EditMenu = styled(Menu)``;

export const TimeSelection = styled.div`
    display: inline-flex;
`;

export const ChartLegend = styled.div`
    padding: 1em;
    margin: 1em 0;
    width: 90%;
    align-items: end;
    justify-content: center;
    background-color: #fafafa;
    border: 1px solid #e5e5e5;
    display: flex;
    flex-direction: row;
    gap: 2em;
`;
