import { createStore } from 'redux';
import allReducer from './reducers';

function saveToSessionStorage(state) {
    try {
        const passStateToLocal = JSON.stringify(state);
        sessionStorage.setItem('state', passStateToLocal);
    } catch (e) {
        console.error(e);
    }
}

function loadFromSessionStorage() {
    try {
        const passLocalToState = sessionStorage.getItem('state');
        if (passLocalToState === null) {
            return undefined;
        }
        return JSON.parse(passLocalToState);
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

const persistedState = loadFromSessionStorage();
export const store = createStore(
    allReducer,
    persistedState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => saveToSessionStorage(store.getState()));
export default store;
