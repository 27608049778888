import { ReactComponent as VISION_MODULES_ICON } from '../../static/icons/vision-modules.svg';
import { ReactComponent as WORKERS_ICON } from '../../static/icons/workers.svg';
import { ReactComponent as SETTINGS_ICON } from '../../static/icons/settings.svg';
import { ReactComponent as PHONE_ICON } from '../../static/icons/phone.svg';

export const SideNavData = [
    {
        title: 'Vision Modules',
        icon: <VISION_MODULES_ICON width="24px" className={'active_color'} />,
        link: ['/home', 'vision'],
    },
    {
        title: 'Workers',
        icon: <WORKERS_ICON width="24px" className={'active_color'} />,
        link: ['/workers'],
    },
    {
        title: 'Account Settings',
        icon: <SETTINGS_ICON width="24px" className={'active_color'} />,
        link: ['/settings'],
    },
    {
        title: 'Contact Us',
        icon: <PHONE_ICON width="24px" className={'active_color'} />,
        link: ['/contact'],
    },
];
