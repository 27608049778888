import { TextField } from '@mui/material';
import styled from 'styled-components';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const TextInput = styled(TextField)`
    margin: 8px 0 !important;
`;

export const CustomTextInput = styled(TextInput)({
    '& label': {
        color: '#98A3AA',
        fontSize: 14,
        lineHeight: '1.5em',
        fontFamily: "'Poppins', sans-serif !important",
    },
    '& label.Mui-focused': {
        color: '#98A3AA',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
        backgroundColor: '#fff',
        color: '#111',
    },

    '& .MuiOutlinedInput-root': {
        overflow: 'hidden',
        borderRadius: 8,
        height: 52,
        fontSize: 14,
        fontFamily: "'Poppins', sans-serif !important",
        backgroundColor: '#f4f4f4',
        '&.Mui-focused': {
            backgroundColor: '#fff',
        },
        '&.Mui-filled': {
            backgroundColor: '#fff',
            border: '10px solid #333',
        },
        '& fieldset': {
            borderColor: '#f4f4f4',
        },
        '&:hover fieldset': {
            borderColor: '#E7EAEB',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#E7EAEB',
        },
    },
});

export const CustomSearchInput = styled(TextInput)({
    '& label': {
        color: '#98A3AA',
        fontSize: 14,
        lineHeight: '1.5em',
        fontFamily: "'Poppins', sans-serif !important",
    },
    '& label.Mui-focused': {
        color: '#98A3AA',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
        backgroundColor: '#fff',
        color: '#111',
    },

    '& .MuiOutlinedInput-root': {
        overflow: 'hidden',
        borderRadius: 8,
        height: 40,
        fontSize: 12,
        width: 200,
        color: '#374630',
        fontWeight: 500,
        fontFamily: "'Poppins', sans-serif !important",
        backgroundColor: '#f4f4f4',

        '&.Mui-focused': {
            backgroundColor: '#f4f4f4',
        },
        '& fieldset': {
            borderColor: '#f4f4f4',
        },
        '&:hover fieldset': {
            borderColor: '#E7EAEB',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#E7EAEB',
        },
    },
});

export const CustomNotifyInput = styled(TextInput)({
    '& label': {
        color: '#98A3AA',
        backgroundColor: '#fff',
        zIndex: 0,
        fontFamily: "'Poppins', sans-serif !important",
    },
    '& label.Mui-focused': {
        color: '#98A3AA',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
        backgroundColor: '#fff',
        color: '#111',
    },

    '& .MuiOutlinedInput-root': {
        overflow: 'hidden',
        borderRadius: 8,
        height: 52,
        fontSize: 12,
        width: '335px',
        fontFamily: "'Poppins', sans-serif !important",
        backgroundColor: 'transparent',

        '&.Mui-focused': {
            backgroundColor: '#fff',
        },
        '& fieldset': {
            borderColor: '#E7EAEB',
        },
        '&:hover fieldset': {
            borderColor: '#E7EAEB',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#E7EAEB',
        },
    },
});

export const CustomDateInput = styled(DesktopDatePicker)({
    '& label': {
        color: '#98A3AA',
        backgroundColor: '#fff',
        marginTop: 10,
        zIndex: 0,
        fontFamily: "'Poppins', sans-serif !important",
    },
    '& label.Mui-focused': {
        color: '#98A3AA',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
        backgroundColor: '#fff',
        color: '#111',
    },

    '& .MuiOutlinedInput-root': {
        overflow: 'hidden',
        borderRadius: 8,
        height: 52,
        fontSize: 14,
        marginTop: 10,
        width: '335px',
        fontFamily: "'Poppins', sans-serif !important",
        backgroundColor: 'transparent',

        '&.Mui-focused': {
            backgroundColor: '#fff',
        },
        '& fieldset': {
            borderColor: '#E7EAEB',
            borderWidth: 2,
        },
        '&:hover fieldset': {
            borderColor: '#E7EAEB',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#E7EAEB',
        },
    },
});

// export const CustomNotifyInput = styles(OutlinedInput)({
//     '& label': {
//         color: '#98A3AA',
//         fontSize: '12px',
//         fontFamily: "'Poppins', sans-serif !important",
//     },
//     '& label.Mui-focused': {
//         color: '#98A3AA',
//     },
//     '& .MuiInput-underline:after': {
//         borderBottomColor: 'black',
//         backgroundColor: '#fff',
//         color: '#111',
//     },
//
//     '& .MuiOutlinedInput-root': {
//         overflow: 'hidden',
//         borderRadius: 8,
//         height: 52,
//         fontFamily: "'Poppins', sans-serif !important",
//         backgroundColor: '#fff',
//
//         '&.Mui-focused': {
//             backgroundColor: '#fff',
//         },
//         '& fieldset': {
//             borderColor: '#fff',
//         },
//         '&:hover fieldset': {
//             borderColor: '#fff',
//         },
//         '&.Mui-focused fieldset': {
//             borderColor: '#fff',
//         },
//     },
// })

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 6% 0;
    text-align: center;
`;

export const ButtonFooter = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    width: 100%;
    padding: 12px 8px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border-radius: 4px;

    &:hover {
        background-color: #f3f3f3;
    }
`;

export const CustomCard = styled.div`
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    transition: 0.3s;
    display: inline-block;
    background-color: white;
    width: 400px;

    &:hover {
        box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.105);
    }
`;

export const CustomCardContainer = styled.div`
    padding: 4% 10%;
`;

export const ModalFooter = styled.div`
    padding: 10px;
    display: grid;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
    background: #fff;
    width: 90%;
`;

export const ModalCloseButton = styled.button`
    border: none;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    background: none;
    width: 120px;
    height: max-content;
    display: grid;
    justify-content: center;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        background-color: rgba(227, 227, 227, 0.33);
        border-radius: 6px;
    }
`;

export const PhotoIcn = styled.span`
    display: grid;
`;
