import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import _ from 'lodash';

import {
    ButtonContainer,
    ButtonFooter,
    CustomTextInput,
} from '../../../utils/AdminGlobalStyles';
import { AccountListProps, AdminListProps } from '../../../@types/user';
import { toast } from 'react-toastify';
import ErrorDisplay from '../../../utils/ErrorDisplay';
import { useUpdateAccount } from '../../../hooks/useAccounts';
import { HR } from './EditAdminAccount';
import { LoadingButton } from '@mui/lab';

const EditAccountWrapper = styled.div`
    padding: 1em;
    color: #414149;
`;

const Heading = styled.h1`
    margin: 0 0 0.5em 0;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #9ba298;
`;

const EditAccountBtn = styled(LoadingButton)`
    background: #000000 !important;
    border: none !important;
    color: white !important;
    font-family: 'Poppins', sans-serif !important;
    cursor: pointer !important;
    padding: 10px 8px !important;
    font-weight: 600 !important;
    width: 100% !important;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px !important;

    &:hover {
        transition: 0.3s !important;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16) !important;
    }

    &:disabled {
        background: #e1e1e1 !important;
        cursor: not-allowed !important;
        box-shadow: none !important;
    }
`;

const ButtonCont = styled(ButtonContainer)`
    padding: 1rem 0 0 0;
`;

interface Props {
    handleClose: () => void;
    itemData: AccountListProps | AdminListProps | any;
    setItemData:
        | React.Dispatch<React.SetStateAction<AccountListProps | null | undefined>>
        | React.Dispatch<React.SetStateAction<AdminListProps | null | undefined>>;
}

const EditUserAccount = (props: Props) => {
    const { handleClose, itemData, setItemData } = props;

    const [newPassword, setNewPassword] = useState('');
    const [reNewPassword, setReNewPassword] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(false);

    const [userDataCopy, setUserDataCopy] = useState<
        AccountListProps | AdminListProps | null
    >();

    const updateAcc = useUpdateAccount();

    useEffect(() => {
        if (itemData) {
            setUserDataCopy(itemData);
        }
    }, []);

    const itemsAreEqual = () => {
        return _.isEqual(itemData, userDataCopy);
    };

    return (
        <EditAccountWrapper>
            <Heading>Edit Account</Heading>
            <CustomTextInput
                fullWidth
                label="Enter full name"
                type="text"
                value={itemData.display_name}
                onChange={(e) =>
                    setItemData({
                        ...itemData,
                        display_name: e.target.value,
                    })
                }
            />

            <CustomTextInput
                fullWidth
                label="Enter farm name"
                type="text"
                value={itemData.farm_name}
                onChange={(e) =>
                    setItemData({
                        ...itemData,
                        farm_name: e.target.value,
                    })
                }
            />

            <CustomTextInput
                fullWidth
                label="Enter phone number"
                type="text"
                value={itemData.phone_number}
                onChange={(e) =>
                    setItemData({
                        ...itemData,
                        phone_number: e.target.value,
                    })
                }
            />
            <HR />
            <CustomTextInput
                fullWidth
                label="Set new password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
            />

            <CustomTextInput
                fullWidth
                label="Verify new password"
                type="password"
                value={reNewPassword}
                onChange={(e) => {
                    setReNewPassword(e.target.value);
                    if (newPassword === e.target.value) {
                        setItemData({
                            ...itemData,
                            password: newPassword,
                        });
                    }

                    if (_.isEmpty(e.target.value)) {
                        delete itemData.password;
                    }
                }}
            />
            {_.isEmpty(reNewPassword) ? (
                <></>
            ) : _.isEqual(newPassword, reNewPassword) ? (
                <ErrorDisplay message="Password match" color={'#00c47b'} />
            ) : (
                <ErrorDisplay message="Password do not match" color={'#ff0000'} />
            )}

            <ButtonCont>
                <ButtonFooter
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Cancel
                </ButtonFooter>
                <EditAccountBtn
                    loading={loadingStatus}
                    disabled={
                        itemsAreEqual() ? true : !_.isEqual(newPassword, reNewPassword)
                    }
                    onClick={() => {
                        if (itemsAreEqual()) {
                            handleClose();
                        } else {
                            setLoadingStatus(true);
                            updateAcc
                                .mutateAsync({ itemData: itemData })
                                .then(() => {
                                    toast.success('Account edited successfully');
                                    setLoadingStatus(false);
                                    handleClose();
                                })
                                .catch((err) => {
                                    setLoadingStatus(false);
                                });
                        }
                    }}
                >
                    Edit
                </EditAccountBtn>
            </ButtonCont>
        </EditAccountWrapper>
    );
};

export default EditUserAccount;
