import React from 'react';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    ChartDataset,
    ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js/dist/types';
import { format } from 'date-fns';
import { useGetChatData } from '../../hooks/useCharts';
import NO_CHART_DATA from '../../../static/images/chart-no-data.png';
import { NoEventImg } from '../../screens/home_page/styled';
import styled from 'styled-components';
import { ChartLegend } from '../styles/hayField';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface ShadingRange {
    min: number;
    max: number;
}

type Dataset = ChartDataset & {
    shadingRange: ShadingRange[];
};

interface CustomDataPoint {
    date: string;
    value: number;
    text?: string;
}

const ChartContainer = styled.div`
    position: relative;
    width: 100%;
    height: 50vh; /* You can adjust this to suit your needs */
`;

const LegendItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
`;

export function EventsLineChart({ fieldId }: { fieldId: string }) {
    const { data: chartData, isSuccess, isLoading } = useGetChatData(fieldId);

    if (isLoading || !isSuccess) {
        return (
            <div>
                <NoEventImg>
                    <img src={NO_CHART_DATA} alt={''} />{' '}
                </NoEventImg>
            </div>
        );
    }

    const { labels, values, forecast_values, valid_index } = chartData;

    const lastDataIndex = valid_index;

    // actual data
    const actualLabels = labels.slice(0, lastDataIndex + 1);
    const actualValues = values.slice(0, lastDataIndex + 1);
    const actualForecastValues = forecast_values.slice(0, lastDataIndex + 1);

    // placeholder data
    const placeholderLabels = labels.slice(lastDataIndex + 1);
    const placeholderValues = values.slice(lastDataIndex + 1);
    const placeholderForecastValues = forecast_values.slice(lastDataIndex + 1);

    const shadingArea = {
        id: 'shadingArea',
        beforeDatasetsDraw(chart: Chart, args: { cancelable: true }, pluginOptions: any) {
            const {
                ctx,
                chartArea: { top, bottom, left, right, width, height },
                scales: { x, y },
            } = chart;
            const tickHeight = y.height / (y.max - y.min);
            const dataPointsLength = actualValues.length; // Changed this line

            ctx.save();
            ctx.beginPath();

            // ctx.strokeStyle = 'rgba(255, 26, 104, 1)';
            ctx.fillStyle = 'rgba(94,167,60,0.2)';
            ctx.moveTo(
                chart.getDatasetMeta(0).data[0].x,
                chart.getDatasetMeta(0).data[0].y +
                    tickHeight * (chart.data.datasets[0] as Dataset).shadingRange[0].min
            );

            for (let i = 1; i < dataPointsLength; i++) {
                ctx.lineTo(
                    chart.getDatasetMeta(0).data[i].x,
                    chart.getDatasetMeta(0).data[i].y +
                        tickHeight *
                            (chart.data.datasets[0] as Dataset).shadingRange[i].min
                );
            }

            for (let z = dataPointsLength - 1; 0 < z; z--) {
                ctx.lineTo(
                    chart.getDatasetMeta(0).data[z].x,
                    chart.getDatasetMeta(0).data[z].y -
                        tickHeight *
                            (chart.data.datasets[0] as Dataset).shadingRange[z].max
                );
            }

            ctx.lineTo(
                chart.getDatasetMeta(0).data[0].x,
                chart.getDatasetMeta(0).data[0].y -
                    tickHeight * (chart.data.datasets[0] as Dataset).shadingRange[0].max
            );

            ctx.closePath();
            // ctx.stroke();
            ctx.fill();
        },
    };

    const customDataPoints: CustomDataPoint[] = [
        { date: '2023/5/6', value: 300, text: 'spray application #1' },
        { date: '2023/5/21', value: 502, text: 'spray application #2' },
        { date: '2023/6/15', value: 808, text: 'harvest' },
    ];

    // Event data points
    const customEventDataset = {
        label: 'Custom Data Points',
        data: new Array(labels.length).fill(null),
        borderColor: '#5EA73C', // Set custom color for the stroke
        backgroundColor: '#FFFFFF', // Set custom color for the dot
        pointStyle: 'circle', // Set the point style to circle
        pointRadius: 6, // Set custom radius for the dot
        pointHoverRadius: 10, // Set custom hover radius for the dot
        pointHoverBackgroundColor: '#FFFFFF', // Set custom color for the dot on hover
        pointHitRadius: 10, // Set custom hit radius for the dot
        pointBorderWidth: 2, // Set custom width for the stroke
        pointBorderColor: '#5EA73C', // Set custom color for the stroke
        order: 1, // Set custom order for the custom dataset
        hoverBorderWidth: 2, // Set custom width for the stroke on hover
        hoverBorderColor: '#5EA73C', // Set custom color for the stroke on hover
        hoverRadius: 10, // Set custom hover radius for the dot on hover
        hoverBorderRadius: 0, // Set custom hover border radius for the dot on hover
    };

    // Add custom data points to the custom dataset
    customDataPoints.forEach((point) => {
        const index = labels.findIndex((label: string) => label === point.date);
        if (index !== -1) {
            customEventDataset.data[index] = point.value;
        }
    });

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'GDU',
                data: actualValues,
                borderColor: '#5EA73C',
                order: 2,
                backgroundColor: '#5EA73C',
                shadingRange: actualForecastValues,
            },
            {
                label: 'Placeholder',
                data: placeholderValues,
                borderColor: 'rgba(0, 0, 0, 0)', // transparent line
                order: 3,
                backgroundColor: 'rgba(0, 0, 0, 0)', // transparent fill
                shadingRange: placeholderForecastValues,
            },
            customEventDataset,
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        interaction: {
            intersect: false,
            mode: 'nearest',
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                border: {
                    display: false,
                },
                yPadding: 10,
                xPadding: 10,
                grace: '40%', // Adds 40% padding to y-axis
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    callback: function (value: any, tickIndex: number, tickValues: any) {
                        return format(new Date(labels[tickIndex]), 'MMM');
                    },
                },
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
                display: false,
            },
            [shadingArea.id]: shadingArea,
            tooltip: {
                intersect: false,
                yAlign: 'bottom' as const,
                marginX: 10,
                marginY: 10,
                padding(): { top: number; left: number; bottom: number; right: number } {
                    return { top: 10, left: 15, bottom: 10, right: 15 };
                },
                titleConfig: {
                    display: false,
                },
                displayColors: false,
                callbacks: {
                    label: (tooltipItem: any, data: ChartData) => {
                        const datasetIndex = tooltipItem.datasetIndex;
                        const index = tooltipItem.dataIndex;
                        const value = tooltipItem.formattedValue;

                        if (index < actualValues.length) {
                            if (datasetIndex === 0) {
                                return `GDU: ${value}`;
                            } else if (datasetIndex === 1) {
                                const customDataPoint = customDataPoints.find(
                                    (point) => actualLabels[index] === point.date
                                );
                                return customDataPoint ? customDataPoint.text : null;
                            }
                        } else {
                            return null; // No tooltip for placeholder values
                        }
                    },
                    title: (tooltipItem: any, data: ChartData) => {
                        const date = new Date(tooltipItem[0].label);
                        const day = date.getDate();
                        const month = date.toLocaleString('default', {
                            month: 'short',
                        });
                        const year = date.getFullYear();
                        return `Date: ${day} ${month} ${year}`;
                    },
                },

                backgroundColor: (context: any) => {
                    const datasetIndex = context.tooltip.dataPoints[0].datasetIndex;
                    return datasetIndex === 1 ? '#5EA73C' : 'rgba(0,0,0,0.7)';
                },
            },
        },
    };

    return (
        <ChartContainer>
            <Line options={options as any} data={data} plugins={[shadingArea]} />
            <ChartLegend>
                <LegendItem>
                    <div
                        style={{
                            background: '#d9e8d0',
                            height: '10px',
                            width: '40px',
                        }}
                    ></div>
                    <p>Forecasted GDU</p>
                </LegendItem>
                <LegendItem>
                    <div
                        style={{
                            background: '#5fa73c',
                            height: '4px',
                            width: '40px',
                            borderRadius: '20%',
                        }}
                    ></div>
                    <p>Past & Expected GDU</p>
                </LegendItem>
            </ChartLegend>
        </ChartContainer>
    );
}
