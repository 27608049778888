import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/screens/home_page/HomePage';
import ForgotPassword from './components/ForgotPassword';
import LandingPage from './components/screens/LandingPage';
import { createBrowserHistory } from 'history';
import React from 'react';
import FirstLogin from './components/screens/FirstLogin';
import { Slide, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import AccountSettings from './components/screens/AccountSettings';
import Contacts from './components/screens/Contacts';
import Profile from './components/screens/Profile';
import Workers from './components/hay_vision/workers/Workers';
import HayVision from './components/hay_vision/HayVision';

import AddFieldHay from './components/hay_vision/fields/AddField';
import HayField from './components/hay_vision/fields/HayField';
import AddWorker from './components/hay_vision/workers/AddWorker';
import EditField from './components/hay_vision/fields/EditField';
import ProtectedRoute from './utils/ProtectedRoute';
import GardenVision from './components/garden_vision/GardenVision';
import AdminDashboard from './admin/screens/AdminDashboard';

function App() {
    const searchParams = window.location.search;
    const params = new URLSearchParams(searchParams);

    const check_admin = params.get('user');

    const data = params.get('data');

    return (
        <Router history={createBrowserHistory}>
            <ToastContainer
                position="top-right"
                autoClose={2500}
                transition={Slide}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
            />

            <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route exact path="/admin-dashboard" element={<AdminDashboard />} />
                <Route exact path="/forgot-password" element={<ForgotPassword />} />

                <Route element={<ProtectedRoute check_admin={check_admin} data={data} />}>
                    <Route exact path="/first-login" element={<FirstLogin />} />
                    <Route exact path="/home" element={<HomePage />} />
                    <Route exact path="/home/:uid" element={<HomePage />} />
                    <Route exact path="/workers" element={<Workers />} />
                    <Route exact path="/workers/add-worker" element={<AddWorker />} />
                    <Route exact path="/settings" element={<AccountSettings />} />
                    <Route exact path="/contact" element={<Contacts />} />
                    <Route exact path="/profile" element={<Profile />} />
                </Route>

                {/*<Route element={<AdminProtectedRoute />}>*/}
                {/*    <Route path="/dashboard" element={<AdminDashboard />} />*/}
                {/*</Route>*/}

                {/* Garden Vision Routes */}
                <Route path="/garden-vision" element={<GardenVision />} />

                {/* Hay Vision Routes */}
                <Route path="/hay-vision" element={<HayVision />} />
                <Route path="/hay-vision/field/:fieldId" element={<HayField />} />
                <Route path="/hay-vision/add-field" element={<AddFieldHay />} />
                <Route path="/hay-vision/edit-field/:fieldId" element={<EditField />} />

                <Route path="*" element={<>No Match</>} />
            </Routes>
        </Router>
    );
}

export default App;
