import store from './store';

export const selectFirebaseToken = () => {
    return store.getState().FirebaseTokenReduxState;
};

export const userObject = () => {
    return store.getState().UserObjectReduxState;
};

export const selectedUser = () => {
    return store.getState().SelectedUserReduxState;
};
