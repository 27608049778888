import React, { useState } from 'react';
import styled from 'styled-components';
import {
    ButtonContainer,
    ButtonFooter,
    CustomTextInput,
} from '../../../utils/AdminGlobalStyles';
import { toast } from 'react-toastify';
import { useCreateAccounts } from '../../../hooks/useAccounts';

interface Props {
    handleClose: () => void;
}

const CreateAccountWrapper = styled.div`
    padding: 1em;
    color: #414149;
`;

const Heading = styled.h1`
    margin: 0 0 0.5em 0;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #9ba298;
`;

const CreateAccountBtn = styled.button`
    background: #000000;
    padding: 10px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 500;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

const ButtonCont = styled(ButtonContainer)`
    padding: 1rem 0 0 0;
`;

const CreateUserAccount = (props: Props) => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [accountName, setAccountName] = useState('');
    const [password, setPassword] = useState('');

    const createAcc = useCreateAccounts();

    const resetFields = () => {
        setEmail('');
        setPassword('');
        setFullName('');
        setAccountName('');
    };

    const handleCreateAcc = () => {
        const data = {
            admin: false,
            disabled: false,
            display_name: fullName,
            email: email,
            password: password,
            phone_number: null,
            photo_url: null,
            farm_name: accountName,
        };

        createAcc.mutateAsync({ addDataAcc: data }).then(() => {
            toast.success('Created New Account');
            resetFields();
            props.handleClose();
        });
    };

    return (
        <CreateAccountWrapper>
            <Heading>Create Account</Heading>
            <CustomTextInput
                fullWidth
                label="Enter owner full name"
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
            />
            <CustomTextInput
                fullWidth
                label="Enter owner email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
            />
            <CustomTextInput
                fullWidth
                label="Enter account name"
                type="text"
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
            />
            <CustomTextInput
                fullWidth
                label="Enter account password"
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <ButtonCont>
                <ButtonFooter onClick={() => props.handleClose()}>Cancel</ButtonFooter>
                <CreateAccountBtn onClick={() => handleCreateAcc()}>
                    Create
                </CreateAccountBtn>
            </ButtonCont>
        </CreateAccountWrapper>
    );
};

export default CreateUserAccount;
