import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import SideNav from '../sidenav/SideNav';

import { ReactComponent as FORWARD_ARROW } from '../../static/icons/forward-arrow.svg';
import HayVisionEvents from './events/HayVisionEvents';
import { ModalCloseButton, ModalFooter } from '../../utils/GlobalStyles';
import { WorkersModal } from './workers/WorkersModal';
import {
    get_hay_vision_events,
    useDeleteHayField,
    useHayVisionFields,
    useUpdateHayField,
} from '../hooks/useHayVision';
import {
    AcresButton,
    AddButton,
    BackArrowIcon,
    BackArrowWrapper,
    CardItem,
    ClearIcon,
    Content,
    DeleteIcon,
    DeleteMenuItem,
    EditDots,
    EditIcon,
    EditMenu,
    EditMenuItem,
    EnableIcon,
    EventWrapper,
    FieldDetails,
    FilterButtons,
    HayFieldCardWrapper,
    HayFieldTitle,
    HayVisionContent,
    HeadingSection,
    HRLine,
    InfoCountWrapper,
    MainContent,
    NameButton,
    PausedState,
    PendingButton,
    SearchContent,
    SearchInput,
    UserGroupIcon,
    UserGroupWrapper,
} from './styles/hayVision';
import { useWorkers } from '../hooks/useWorkers';
import { MenuItem } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import ModalComponent from '../modal/ModalComponent';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { selectedUser } from '../../states/selectors';
import { useCreateModal } from '../../utils/useCreateModal';
import ClearFieldModal from '../modal_components/ClearFieldModal';
import NO_FIELDS from '../../static/images/no-fields.png';
import { NoFieldImg } from '../screens/home_page/styled';

export default function HayVision() {
    let navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const [completeCount, setCompleteCount] = useState(0);
    const [sortTrigger, setSortTrigger] = useState([true, false, false]);

    const delete_hay_field = useDeleteHayField();
    const selectedAccId = selectedUser();

    const { isLoading: isHayFieldsLoading, data: hayFields } =
        useHayVisionFields(selectedAccId);

    const [modalOpenWorker, setModalOpenWorker] = useState(false);
    const closeModalWorker = () => setModalOpenWorker(false);
    const openModalWorker = () => setModalOpenWorker(true);

    const modalClearField = useCreateModal();

    const [location, setLocation] = useState({
        address: '',
        lat: 38.887653,
        lng: -102.950467,
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [fieldId, setFieldId] = useState('');
    const [selectedField, setSelectedField] = useState({});
    const open = Boolean(anchorEl);

    const handleClick = (event, id) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setFieldId(id);
        setSelectedField(hayFields.find((field) => field.id === id));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [searchInput, setSearchInput] = useState('');

    const filteredHayData = (fields, filterOption) => {
        if (filterOption === 'names' || filterOption === 'all') {
            return fields
                .sort((a, b) => {
                    if (sortTrigger[0]) {
                        return a.name > b.name ? 1 : -1;
                    } else {
                        return b.name > a.name ? 1 : -1;
                    }
                })
                .filter((eachObject) => {
                    return eachObject.name
                        .toLowerCase()
                        .includes(searchInput.toLowerCase());
                });
        } else if (filterOption === 'pending') {
            return fields.filter((eachObject) => {
                return eachObject.name.toLowerCase().includes(searchInput.toLowerCase());
            });
        } else if (filterOption === 'acres') {
            return fields
                .sort((a, b) => {
                    if (sortTrigger[2]) {
                        return parseInt(a.acres) - parseInt(b.acres);
                    } else {
                        return parseInt(b.acres) - parseInt(a.acres);
                    }
                })
                .filter((eachObject) => {
                    return eachObject.name
                        .toLowerCase()
                        .includes(searchInput?.toLowerCase());
                });
        }

        return fields.filter((eachObject) => {
            return eachObject.name.toLowerCase().includes(searchInput?.toLowerCase());
        });
    };

    const FieldInfo = (props) => {
        return props.paused ? (
            <PausedState>Paused</PausedState>
        ) : (
            props.badges.count > 0 && (
                <InfoCountWrapper category={props.badges.category}>
                    <p>{props.badges.count}</p>
                    <p>
                        {'•'} {props.badges.category.charAt(0).toUpperCase()}
                        {props.badges.category.slice(1)}
                    </p>
                </InfoCountWrapper>
            )
        );
    };

    const { isSuccess: isALlWorkersSuccess, data: hayVisionWorkers } = useWorkers();

    const [zoomLevel, setZoomLevel] = useState(3);
    const [mapCenter, setMapCenter] = useState(location);

    const [selectedFilter, setSelectedFilter] = useState('all');

    function dateComparison(value1, value2) {
        return new Date(value1.due_date).getTime() - new Date(value2.due_date).getTime();
    }

    const {
        isLoading: isHayEventsLoading,
        isError: isHayEventsError,
        data: hayEvents,
    } = useQuery('hayVisionEvents', () => get_hay_vision_events(selectedAccId), {
        onSuccess: (events) => {
            const completeCount = events.filter((eachObject) => eachObject.status === 2);
            setCompleteCount(completeCount.length);
        },
    });

    const allEventsFiltered = hayEvents?.filter((eachEvent) => {
        return hayFields?.some((eachField) => {
            return eachField?.id === eachEvent?.field_id && eachField?.active === true;
        });
    });

    const updateHayFields = useUpdateHayField();

    const handleClearField = () => {
        const data = {
            ...selectedField,
            active: false,
            uid: selectedAccId,
        };

        if (selectedField.active === false) {
            navigate(`/hay-vision/edit-field/${data.id}`);
        } else {
            toast
                .promise(updateHayFields.mutateAsync(data), {
                    pending: 'Updating...',
                    success: 'Field updated successfully',
                    error: 'Error updating!',
                })
                .then(() => {
                    modalClearField.closeModal();
                });
        }
    };

    const countOverduePendingEvents = (category, field, hayEvents) => {
        const events = hayEvents.filter((event) => event.field_id === field.id);

        let count = 0;

        if (category === 'overdue') {
            count = events.filter((event) => {
                return new Date(event.due).getTime() <= new Date().getTime();
            });
        } else if (category === 'pending') {
            count = events.filter((event) => {
                return new Date(event.due).getTime() > new Date().getTime();
            });
        }

        return { field_id: field.id, count: count.length, category: category };
    };

    return (
        <HayVisionContent>
            <SideNav />
            <MainContent>
                <HeadingSection>
                    <div
                        style={{ display: 'inline-flex', gridArea: '3/1' }}
                        onClick={() => navigate(-1)}
                    >
                        <BackArrowWrapper>
                            <BackArrowIcon /> HAY VISION{' '}
                        </BackArrowWrapper>
                    </div>
                    <div style={{ gridArea: '3/2' }} className={'hay_workers'}>
                        <UserGroupWrapper className={'hay_workers'}>
                            <UserGroupIcon
                                className={'hay_workers'}
                                onClick={() =>
                                    modalOpenWorker
                                        ? closeModalWorker()
                                        : openModalWorker()
                                }
                            />
                        </UserGroupWrapper>
                    </div>
                    {isALlWorkersSuccess && !!hayVisionWorkers ? (
                        <AnimatePresence initial={false} mode={'wait'}>
                            {modalOpenWorker && (
                                <ModalComponent
                                    open={openModalWorker}
                                    handleClose={closeModalWorker}
                                    width={'40'}
                                    minheight={'400px'}
                                    footer={
                                        <ModalFooter>
                                            <ModalCloseButton onClick={closeModalWorker}>
                                                Close
                                            </ModalCloseButton>
                                        </ModalFooter>
                                    }
                                >
                                    <WorkersModal
                                        title="WORKERS IN HAY VISION"
                                        subText={''}
                                        workers={hayVisionWorkers}
                                    />
                                </ModalComponent>
                            )}
                        </AnimatePresence>
                    ) : null}
                </HeadingSection>

                <Content>
                    <SearchContent>
                        <SearchInput
                            placeholder={'Search using keywords...'}
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                        <FilterButtons>
                            <NameButton
                                style={{
                                    backgroundColor:
                                        sortTrigger[0] || selectedFilter === 'names'
                                            ? '#5ea73c'
                                            : null,
                                    color:
                                        sortTrigger[0] || selectedFilter === 'names'
                                            ? '#fff'
                                            : null,
                                }}
                                onClick={() => {
                                    setSortTrigger([!sortTrigger[0], false, false]);
                                    setSelectedFilter('names');
                                }}
                            >
                                Name
                            </NameButton>
                            <PendingButton>Pending</PendingButton>
                            <AcresButton
                                style={{
                                    backgroundColor:
                                        sortTrigger[2] || selectedFilter === 'acres'
                                            ? '#5ea73c'
                                            : null,
                                    color:
                                        sortTrigger[2] || selectedFilter === 'acres'
                                            ? '#fff'
                                            : null,
                                }}
                                onClick={() => {
                                    setSortTrigger([false, false, !sortTrigger[2]]);
                                    setSelectedFilter('acres');
                                }}
                            >
                                Acres
                            </AcresButton>
                        </FilterButtons>
                    </SearchContent>
                </Content>
                <HRLine />

                <Content>
                    <HayFieldTitle>
                        <h3>HAY FIELDS</h3>
                        <AddButton onClick={() => navigate('/hay-vision/add-field')}>
                            Add Field
                            <span>
                                <FORWARD_ARROW />
                            </span>
                        </AddButton>
                    </HayFieldTitle>

                    {!isHayFieldsLoading && !!hayFields && !!allEventsFiltered ? (
                        filteredHayData(hayFields).length !== 0 ? (
                            <HayFieldCardWrapper>
                                {filteredHayData(hayFields, selectedFilter).map(
                                    (value, key) => (
                                        <div style={{ position: 'relative' }} key={key}>
                                            <CardItem
                                                key={key}
                                                value={value}
                                                onClick={() =>
                                                    navigate(
                                                        `/hay-vision/field/${value.id}`
                                                    )
                                                }
                                            >
                                                {value.active === false ? (
                                                    <FieldInfo paused={true} />
                                                ) : (
                                                    <div className={'field-badges'}>
                                                        <FieldInfo
                                                            paused={false}
                                                            badges={countOverduePendingEvents(
                                                                'pending',
                                                                value,
                                                                allEventsFiltered
                                                            )}
                                                        />

                                                        <FieldInfo
                                                            paused={false}
                                                            badges={countOverduePendingEvents(
                                                                'overdue',
                                                                value,
                                                                allEventsFiltered
                                                            )}
                                                        />
                                                    </div>
                                                )}

                                                <div
                                                    className={'image_wrapper'}
                                                    style={{
                                                        width: '200px',
                                                        height: '90px',
                                                    }}
                                                >
                                                    <img
                                                        src={value.location_image}
                                                        alt={''}
                                                    />
                                                </div>
                                                <FieldDetails>
                                                    <p>
                                                        {value.name} <br />{' '}
                                                        <span>{value.acres} acres</span>
                                                    </p>

                                                    <EditDots
                                                        value={value}
                                                        onMouseDown={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        onClick={(e) =>
                                                            handleClick(e, value.id)
                                                        }
                                                    />
                                                </FieldDetails>
                                            </CardItem>
                                        </div>
                                    )
                                )}

                                <EditMenu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    {selectedField.active === true ? (
                                        <>
                                            <MenuItem
                                                style={{ width: '10em' }}
                                                onClick={() =>
                                                    navigate(
                                                        `/hay-vision/edit-field/${fieldId}`
                                                    )
                                                }
                                            >
                                                <EditMenuItem>
                                                    <EditIcon />
                                                    Edit Field
                                                </EditMenuItem>
                                            </MenuItem>
                                            <MenuItem
                                                style={{ width: '10em' }}
                                                onClick={() => {
                                                    modalClearField.openModal();
                                                    handleClose();
                                                }}
                                            >
                                                <EditMenuItem>
                                                    <ClearIcon />
                                                    Pause Field
                                                </EditMenuItem>
                                            </MenuItem>
                                        </>
                                    ) : (
                                        <MenuItem
                                            style={{ width: '10em' }}
                                            onClick={() => {
                                                modalClearField.openModal();
                                                handleClose();
                                            }}
                                        >
                                            <EditMenuItem>
                                                <EnableIcon />
                                                Enable Field
                                            </EditMenuItem>
                                        </MenuItem>
                                    )}

                                    <DeleteMenuItem
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    'Are you sure you want to delete this field?'
                                                )
                                            ) {
                                                delete_hay_field
                                                    .mutateAsync(fieldId)
                                                    .then(() => {
                                                        handleClose();
                                                    })
                                                    .catch(() => {
                                                        toast.error(
                                                            'Error deleting field'
                                                        );
                                                    });
                                            }
                                        }}
                                    >
                                        <EditMenuItem>
                                            <DeleteIcon />
                                            Delete Field
                                        </EditMenuItem>
                                    </DeleteMenuItem>
                                </EditMenu>
                            </HayFieldCardWrapper>
                        ) : (
                            <NoFieldImg>
                                <img src={NO_FIELDS} alt={''} />
                            </NoFieldImg>
                        )
                    ) : !isHayEventsError ? (
                        <p>Loading...</p>
                    ) : (
                        <>Error</>
                    )}
                </Content>
            </MainContent>
            <EventWrapper>
                {!isHayEventsLoading && !!allEventsFiltered && !!hayFields ? (
                    <HayVisionEvents
                        showModal={showModal}
                        setShowModal={setShowModal}
                        location={location}
                        completeCount={completeCount}
                        setCompleteCount={setCompleteCount}
                        dateComparison={dateComparison}
                        setLocation={setLocation}
                        setMapCenter={setMapCenter}
                        mapCenter={mapCenter}
                        zoomLevel={zoomLevel}
                        setZoomLevel={setZoomLevel}
                        hayFields={hayFields}
                        hayEvents={allEventsFiltered}
                        fieldId={fieldId}
                    />
                ) : !isHayEventsError ? (
                    <p>Loading...</p>
                ) : (
                    <>Error</>
                )}
            </EventWrapper>

            <AnimatePresence initial={false} mode={'wait'}>
                {modalClearField.modal && selectedField && (
                    <ModalComponent
                        key={'clear-field'}
                        open={modalClearField.openModal}
                        handleClose={modalClearField.closeModal}
                        width={'35'}
                        min_height={'200px'}
                    >
                        {selectedField.active === true ? (
                            <ClearFieldModal
                                heading={'Pause Field?'}
                                text={`Pausing the field will deactivate nightly processing. We will not remove this field from your account, however, it will be set to an inactive state and no events or notices will be generated for this field. You may enable this field again when desired.`}
                                subText={'Are you sure you wish to pause this field?'}
                                btnText={'Pause'}
                                btnColor={'#ED4343'}
                                selectedField={selectedField}
                                handleClose={modalClearField.closeModal}
                                func={handleClearField}
                            />
                        ) : (
                            <ClearFieldModal
                                heading={'Enable Field?'}
                                text={
                                    'To enable this field, update the field settings on the following page.'
                                }
                                btnText={'Next'}
                                btnColor={'#5EA73C'}
                                selectedField={selectedField}
                                handleClose={modalClearField.closeModal}
                                func={handleClearField}
                            />
                        )}
                    </ModalComponent>
                )}
            </AnimatePresence>
        </HayVisionContent>
    );
}
