import React, { useState } from 'react';
import Footer from '../Footer';
import LoginPage from './LoginPage';
import NavBar from '../NavBar';

import { useAuth } from '../../context/UserContextProvider';
import ForgotPassword from '../ForgotPassword';
import ENERGY_CURVE from '../../static/images/energy-curve.svg';
import LOGIN_BACKGROUND from '../../static/images/img.png';
import styled from 'styled-components';
import { CustomCard, CustomCardContainer } from '../../utils/GlobalStyles';

export default function LandingPage() {
    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');
    const [reEnterPassword, setReEnterPassword] = useState('');

    const [hasAccount, setHasAccount] = useState(false);

    const { emailError, passwordError, setEmailError, setPasswordError } = useAuth();

    const clearInputs = () => {
        setEmail('');
        setPassword('');
    };

    const clearErrors = () => {
        setEmailError('');
        setPasswordError('');
    };

    return (
        <div>
            <LandingPageContent>
                <NavBar />

                <ContentWrapper>
                    <LeftLoginContainer>
                        <img src={ENERGY_CURVE} alt="" />

                        <LearnMoreBtn>Learn More</LearnMoreBtn>
                    </LeftLoginContainer>
                    <RightLoginContainer>
                        <CustomCard>
                            <CustomCardContainer>
                                {hasAccount ? (
                                    <>
                                        <ForgotPassword
                                            user_email={email}
                                            setEmail={setEmail}
                                            hasAccount={hasAccount}
                                            setHasAccount={setHasAccount}
                                            clearInputs={clearInputs}
                                            clearErrors={clearErrors}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <LoginPage
                                            user_email={email}
                                            setEmail={setEmail}
                                            user_password={password}
                                            setPassword={setPassword}
                                            reEnterPassword={reEnterPassword}
                                            setReEnterPassword={setReEnterPassword}
                                            hasAccount={hasAccount}
                                            setHasAccount={setHasAccount}
                                            emailError={emailError}
                                            setEmailError={setEmailError}
                                            passwordError={passwordError}
                                            setPasswordError={setPasswordError}
                                            clearInputs={clearInputs}
                                            clearErrors={clearErrors}
                                        />
                                    </>
                                )}
                            </CustomCardContainer>
                        </CustomCard>
                    </RightLoginContainer>
                </ContentWrapper>
                <Footer />
            </LandingPageContent>
        </div>
    );
}

const ContentWrapper = styled.div`
    background-color: transparent;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
        '. . . . .'
        'left_login left_login . right_login right_login'
        'left_login left_login . right_login right_login'
        'left_login left_login . right_login right_login';
`;

const LandingPageContent = styled.div`
    background-image: url(${LOGIN_BACKGROUND});
    /* Full height */
    height: 100vh;
    padding: 0 5%;
    display: grid;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
`;

const LeftLoginContainer = styled.div`
    grid-area: left_login;
    display: grid;
    align-items: center;

    img {
        width: 500px;
    }
`;

const RightLoginContainer = styled.div`
    grid-area: right_login;
    display: grid;
    justify-content: center;
`;

const LearnMoreBtn = styled.button`
    background-color: black;
    color: #fff;
    font-size: 14px;
    width: 30%;
    padding: 2% 4%;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.1s;
    font-family: 'Poppins', sans-serif;

    &:hover {
        background-color: transparent;
        color: #171b2c;
        border: 1px solid #171b2c;
    }
`;
