import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    User,
} from 'firebase/auth';
import { auth } from '../firebase/config';
import { UserContextType } from '../admin/@types/user';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setFirebaseToken, setUserEmail, setUserObject } from '../states/actions';

export const UserContext = createContext<UserContextType | null>(null);

export const useAuth = () => {
    return useContext(UserContext) as UserContextType;
};

// React 18 needs this manually defined
interface Props {
    children: React.ReactNode;
}

const UserContextProvider: React.FC<Props> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    // const [setFirebaseToken, setFirebaseToken] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        return onAuthStateChanged(auth, async (user) => {
            if (user) {
                const idToken = await user.getIdToken(true);
                dispatch(setFirebaseToken(idToken));
            }
        });
    }, []);

    const clearAllState = () => {
        setError('');
        setEmailError('');
        setPasswordError('');
        dispatch(setUserObject(null));
        dispatch(setFirebaseToken(null));
    };

    const createUser = (email: string, password: string) => {
        setLoading(true);
        return createUserWithEmailAndPassword(auth, email, password);
    };

    const loginUser = (email: string, password: string) => {
        return signInWithEmailAndPassword(auth, email, password)
            .then(async (res) => {
                const fToken = await res.user.getIdToken();
                setUser(res.user);
                dispatch(setFirebaseToken(fToken));
                dispatch(setUserEmail(email));
                return fToken;
            })
            .catch((res) => {
                switch (res.code) {
                    case 'auth/invalid-email':
                        toast.error('Invalid Email');
                        break;
                    case 'auth/user-disabled':
                        toast.error('User has been disabled');
                        break;
                    case 'auth/user-not-found':
                        toast.error("User doesn't exist");
                        break;
                    case 'auth/wrong-password':
                        toast.error('Wrong password');
                        break;
                    case 'ERR_BAD_REQUEST':
                        toast.error('Bad Request');
                        break;
                    default:
                        break;
                }
                dispatch(setFirebaseToken(''));
                dispatch(setUserEmail(''));
            });
    };

    const logoutUser = () => {
        return signOut(auth);
    };

    const forgotPassword = (email: string) => {
        return sendPasswordResetEmail(auth, email, {
            url: 'http://localhost:3000',
        });
    };

    const resetPassword = (email: string) => {
        return sendPasswordResetEmail(auth, email);
    };

    const contextValue: UserContextType | null = {
        user,
        loading,
        error,
        loginUser,
        createUser,
        logoutUser,
        forgotPassword,
        emailError,
        passwordError,
        setEmailError,
        setPasswordError,
        resetPassword,
        setUser,
        clearAllState,
    };
    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
