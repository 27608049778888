import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import LOCATION_PIC from '../../static/icons/location-pin-green.svg';
import MY_LOCATION from '../../static/images/MyLocation.png';
import { GOOGLE_MAP_API } from '../../config';

const AddMarkerOnMap = ({
    location,
    setLocation,
    zoomLevel,
    fieldData,
    setFieldData,
}) => {
    const [getLocation, setGetLocation] = useState(false);

    const url = 'https://maps.googleapis.com/maps/api/staticmap';

    let marker;
    const loadMap = (map, maps, loc) => {
        marker = new maps.Marker({
            position: loc,
            map,
            draggable: true,
            icon: LOCATION_PIC,
        });

        const myLocationButton = document.createElement('img');
        myLocationButton.src = MY_LOCATION;
        myLocationButton.classList.add('my-location-button');

        map.controls[maps.ControlPosition.TOP_LEFT].push(myLocationButton);

        marker.addListener('dragend', (e) => {
            let lat = e.latLng.lat();
            let lng = e.latLng.lng();

            setFieldData({
                ...fieldData,
                // location: { ...location, lat: lat, lng: lng },
                gps_long: lng,
                gps_lat: lat,
                location_image:
                    url +
                    `?maptype=hybrid&markers=size:mid%7Ccolor:0xCBD1D4%7Clabel:%7C${lat},${lng}&zoom=12&size=200x115&style=feature:all%7Celement:labels%7Cvisibility:on&key=${GOOGLE_MAP_API}`,
            });

            setLocation(
                url +
                    `?maptype=hybrid&markers=size:mid%7Ccolor:0xCBD1D4%7Clabel:%7C${lat},${lng}&zoom=12&size=200x115&style=feature:all%7Celement:labels%7Cvisibility:on&key=${GOOGLE_MAP_API}`
            );
        });

        myLocationButton.addEventListener('click', () => {
            setGetLocation(false);

            // Using geolocation
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };

                        let lat = position.coords.latitude;
                        let lng = position.coords.longitude;

                        setFieldData({
                            ...fieldData,
                            location: { ...location, lat: lat, lng: lng },
                            location_image:
                                url +
                                `?maptype=hybrid&markers=size:mid%7Ccolor:0xCBD1D4%7Clabel:%7C${lat},${lng}&zoom=12&size=200x115&style=feature:all%7Celement:labels%7Cvisibility:on&key=${GOOGLE_MAP_API}`,
                        });

                        // infoWindow.setPosition(pos);
                        // infoWindow.setContent("Location found.");
                        // infoWindow.open(map);
                        map.setCenter(pos);
                        marker.setPosition(pos);
                        map.setZoom(17);
                    },
                    () => {
                        // handleLocationError(true, infoWindow, map.getCenter()!);
                        // alert(map.getCenter())
                        setGetLocation(true);
                    }
                );
            } else {
                // Browser doesn't support Geolocation
                setGetLocation(true);
                // handleLocationError(false, infoWindow, map.getCenter()!);
            }
        });
    };

    // Important! Always set the container height explicitly
    return (
        <div id={'map_marker'} style={{ height: '380px', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAP_API }}
                defaultCenter={{
                    lat: fieldData.gps_lat,
                    lng: fieldData.gps_long,
                }}
                defaultZoom={zoomLevel}
                options={(maps) => {
                    return {
                        streetViewControl: false,
                        scaleControl: true,
                        fullscreenControl: false,
                        gestureHandling: 'greedy',
                        disableDoubleClickZoom: true,
                        mapTypeControl: true,
                        mapTypeId: maps.MapTypeId.HYBRID,
                        mapTypeControlOptions: {
                            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                            position: maps.ControlPosition.TOP_RIGHT,
                            mapTypeIds: [
                                maps.MapTypeId.ROADMAP,
                                maps.MapTypeId.SATELLITE,
                                maps.MapTypeId.HYBRID,
                            ],
                        },
                        styles: [
                            {
                                featureType: 'all',
                                elementType: 'labels',
                                stylers: [
                                    {
                                        visibility: 'on',
                                    },
                                ],
                            },
                        ],
                        zoomControl: true,
                        clickableIcons: false,
                    };
                }}
                onGoogleApiLoaded={({ map, maps }) =>
                    loadMap(map, maps, {
                        lat: fieldData.gps_lat,
                        lng: fieldData.gps_long,
                    })
                }
            />
            {getLocation ? (
                <p style={{ color: 'red' }}>
                    Failed to get your location! Please unblock location for this website.
                </p>
            ) : (
                <></>
            )}
            <p>
                Lat: {fieldData.gps_lat}, Lng: {fieldData.gps_long}
            </p>
        </div>
    );
};

export default AddMarkerOnMap;
