import React from 'react';
import styled from 'styled-components';
import FOOTER_IMG from '../static/images/footer-img.png';

const FooterWrapper = styled.div`
    background-color: transparent;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'left_footer left_footer . . . right_footer';
`;

const FooterMenu = styled.div`
    grid-area: left_footer;
    text-align: left;
    display: grid;
    align-content: end;
`;

const FooterList = styled.li`
    list-style: none;
    color: black;
    padding: 20px 20px 20px 0;
    display: inline-block;
    font-weight: 500;
    margin: 0 0;
    font-size: 12px;
    cursor: pointer;
    position: relative;
`;

const FooterImg = styled.div`
    grid-area: right_footer;
    //background: url(${FOOTER_IMG}) no-repeat;
    background-size: contain;
    width: 100px;
`;

export default function Footer() {
    return (
        <FooterWrapper>
            <FooterMenu>
                <ul style={{ padding: 0 }}>
                    <FooterList>Terms &amp; Conditions</FooterList>
                    <FooterList>|</FooterList>
                    <FooterList>Privacy Policy</FooterList>
                </ul>
            </FooterMenu>

            <FooterImg>{/*<img width={140} src={FOOTER_IMG} alt="img" />*/}</FooterImg>
        </FooterWrapper>
    );
}
