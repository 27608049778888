// export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BASE_URL = 'https://api-devel.ectvision.com';
export const GOOGLE_MAP_API = process.env.REACT_APP_GOOGLE_MAP_API;

// Hay Vision endpoints
export const ALL_EVENTS = BASE_URL + '/v1/hay_vision/events';
export const UPDATE_EVENT = BASE_URL + '/v1/hay_vision/events/update';
export const HAY_VISION_EVENTS = BASE_URL + '/hay_events';

// Fields
export const HAY_VISION_FIELDS = BASE_URL + '/v1/hay_vision/fields';
export const HAY_VISION_FIELDS_BY_ID = BASE_URL + '/v1/hay_vision/field';
export const CREATE_HAY_VISION_FIELDS = BASE_URL + '/v1/hay_vision/field/create';
export const EDIT_HAY_VISION_FIELDS = BASE_URL + '/v1/hay_vision/field';
export const UPDATE_HAY_VISION_FIELDS = BASE_URL + '/v1/hay_vision/field/update';
export const DELETE_HAY_VISION_FIELDS = BASE_URL + '/v1/hay_vision/field/delete';

// Charts
export const HAY_VISION_CHARTS_DATA = BASE_URL + '/v1/hay_vision/chart';

// Accounts
export const GET_LOGGED_IN_USER = BASE_URL + '/v1/ect_vision/account';
export const UPDATE_LOGGED_IN_USER = BASE_URL + '/v1/ect_vision/account';

// All workers endpoint
export const GET_ALL_WORKERS = BASE_URL + '/v1/ect_vision/account/workers';
export const CREATE_SINGLE_WORKER = BASE_URL + '/v1/ect_vision/account/worker/create';
export const UPDATE_SINGLE_WORKER = BASE_URL + '/v1/ect_vision/account/worker/update';

export const ADMIN_BASE_URL = process.env.REACT_APP_BASE_URL;

// Accounts API
export const GET_ALL_ACCOUNTS = BASE_URL + '/v1/admin/accounts';
export const GET_ALL_ADMINS = BASE_URL + '/v1/admin/admins';
export const GET_ALL_SALESMEN = BASE_URL + '/v1/admin/salesmen';
export const UPDATE_ACCOUNT_DETAILS = BASE_URL + '/v1/admin/update';
export const DELETE_ADMIN_ACCOUNT = BASE_URL + '/v1/admin/delete';

export const GET_ALL_SALESMAN = BASE_URL + '/v1/admin/salesmen';

// Create APIs
export const CREATE_NEW_ADMIN = BASE_URL + '/v1/admin/create';
export const CREATE_NEW_ACCOUNT = BASE_URL + '/v1/admin/create';
export const CREATE_NEW_SALESMAN = BASE_URL + '/v1/admin/create';
