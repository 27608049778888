import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import axiosInstance from '../../services/axiosInstance';
import { CREATE_NEW_SALESMAN, GET_ALL_SALESMAN } from '../../config';

const addSalesMan = ({ data }: { data: any }) => {
    return axiosInstance.post(CREATE_NEW_SALESMAN, data).then((res) => res.data);
};

export function useCreateSalesman() {
    const queryClient = useQueryClient();
    return useMutation(addSalesMan, {
        onSuccess: () => {
            queryClient.invalidateQueries().then(() => {});
        },
        onError: (err) => {
            console.error(err);
            toast.error("Couldn't create Salesman");
        },
    });
}

const getSalesman = () => {
    return axiosInstance.get(GET_ALL_SALESMAN).then((res) => res.data);
};

export const useGetAllSalesman = () => {
    return useQuery(['allSalesman'], getSalesman, {
        onError: (err) => {
            console.log(err);
        },
    });
};
