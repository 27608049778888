import React, { useCallback, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { ReactComponent as LOCATION_PIN_GREEN } from '../../static/icons/location-pin-green.svg';
import { ReactComponent as LOCATION_PIN_RED } from '../../static/icons/location-pin-green.svg';
import { GOOGLE_MAP_API } from '../../config';
import styled from 'styled-components';

const LocationText = styled.p`
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #f8f8f8;
    padding: 4px;
    margin: 2em 0 0 -2em;
    border-radius: 4px;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.5);
`;

const LocationMarker = styled.div`
    display: flex;
    flex-direction: row;
    > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        user-select: none;
        transform: translate(-50%, -60%);
        &:hover {
            z-index: 1;
        }
    }
`;

function useThrottledCallback(callback, delay) {
    const callbackRef = useRef(callback);
    const timeoutRef = useRef();

    // Update callback
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    // Set up throttled function
    return useCallback(
        function () {
            // If there's a timeout in progress, skip this call
            if (timeoutRef.current) return;

            // Call our callback
            callbackRef.current.apply(this, arguments);

            // Set a timeout
            timeoutRef.current = setTimeout(() => {
                timeoutRef.current = undefined;
            }, delay);
        },
        [delay]
    );
}

const LocationPin = React.memo(
    ({
        text,
        location,
        setMapCenter,
        setZoomLevel,
        zoomLevel,
        selectedPin,
        setSelectedPin,
        pinID,
    }) => {
        const handleClick = useThrottledCallback(() => {
            setZoomLevel(15);
            setMapCenter(location);
            setSelectedPin(pinID);
        }, 50);

        return (
            <LocationMarker
                onClick={() => {
                    handleClick();
                }}
            >
                <LOCATION_PIN_GREEN />
                {selectedPin === pinID ? <LocationText>{text}</LocationText> : null}
            </LocationMarker>
        );
    }
);

const Map = ({
    location,
    mapCenter,
    setMapCenter,
    zoomLevel,
    setZoomLevel,
    mapLocations,
    setMapObjects,
    initialBounds,
    setInitialBounds,
    selectedPin,
    setSelectedPin,
}) => {
    function getBounds(map, maps) {
        const bounds = new maps.LatLngBounds();

        if (mapLocations[0]) {
            mapLocations.forEach((place) => {
                bounds.extend(new maps.LatLng(place.gps_lat, place.gps_long));
            });
            map.fitBounds(bounds);

            // Only set initial bounds when they're not set yet.
            if (!initialBounds) {
                setInitialBounds(bounds);
            }
        }

        return bounds;
    }

    // Important! Always set the container height explicitly
    return (
        <div style={{ height: '45vh', width: 'auto' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAP_API }}
                defaultCenter={location}
                defaultZoom={3}
                zoom={zoomLevel}
                center={mapCenter}
                yesIWantToUseGoogleMapApiInternals={true}
                options={(maps) => {
                    return {
                        streetViewControl: false,
                        scaleControl: true,
                        fullscreenControl: false,
                        gestureHandling: 'greedy',
                        disableDoubleClickZoom: true,
                        mapTypeControl: true,
                        mapTypeId: maps.MapTypeId.HYBRID,
                        mapTypeControlOptions: {
                            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                            position: maps.ControlPosition.TOP_RIGHT,
                            mapTypeIds: [
                                maps.MapTypeId.ROADMAP,
                                maps.MapTypeId.SATELLITE,
                                maps.MapTypeId.HYBRID,
                            ],
                        },
                        styles: [
                            {
                                featureType: 'all',
                                elementType: 'labels',
                                stylers: [
                                    {
                                        visibility: 'on',
                                    },
                                ],
                            },
                        ],
                        zoomControl: true,
                        clickableIcons: false,
                    };
                }}
                onGoogleApiLoaded={({ map, maps }) => {
                    getBounds(map, maps);
                    setMapObjects({ map, maps });
                }}
            >
                {mapLocations[0] &&
                    (mapLocations || [])?.map((value, index) => (
                        <LocationPin
                            key={index}
                            text={value?.name}
                            lat={value?.gps_lat}
                            lng={value?.gps_long}
                            setZoomLevel={setZoomLevel}
                            zoomLevel={zoomLevel}
                            setMapCenter={setMapCenter}
                            location={{ lat: value?.gps_lat, lng: value?.gps_long }}
                            selectedPin={selectedPin}
                            setSelectedPin={setSelectedPin}
                            pinID={index}
                        />
                    ))}
            </GoogleMapReact>
        </div>
    );
};

export default Map;
