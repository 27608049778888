import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { ButtonContainer, ButtonFooter } from '../../../utils/AdminGlobalStyles';
import { AccountListProps, AdminListProps } from '../../../@types/user';
import { toast } from 'react-toastify';
import ErrorDisplay from '../../../utils/ErrorDisplay';
import { useUpdateAccount } from '../../../hooks/useAccounts';
import {
    deleteObject,
    getDownloadURL,
    ref,
    uploadBytesResumable,
} from 'firebase/storage';
import { LoadingButton } from '@mui/lab';
import { ReactComponent as EDIT_ROUND } from '../../../static/icons/edit-round.svg';
import { ReactComponent as DELETE_ROUND } from '../../../static/icons/delete-round.svg';
import { storage } from '../../../../firebase/config';
import { InputBox } from './EditAdminAccount';

const EditAccountWrapper = styled.div`
    padding: 1em;
    color: #414149;
    text-align: center;
`;

const Heading = styled.h1`
    margin: 0 0 0.5em 0;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #9ba298;
`;

const EditAccountBtn = styled(LoadingButton)`
    background: #000000 !important;
    border: none !important;
    color: white !important;
    font-family: 'Poppins', sans-serif !important;
    cursor: pointer !important;
    padding: 10px 8px !important;
    font-weight: 600 !important;
    width: 100% !important;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px !important;

    &:hover {
        transition: 0.3s !important;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16) !important;
    }

    &:disabled {
        background: #e1e1e1 !important;
        cursor: not-allowed !important;
        box-shadow: none !important;
    }
`;

const ButtonCont = styled(ButtonContainer)`
    padding: 1rem 0 0 0;
`;

export const HR = styled.hr`
    border-top: none;
    background-color: #e7eaeb;
    margin: 0.5rem 1rem;
`;

const ImageWrapper = styled.div`
    width: 100%;
    position: relative;
    display: grid;
    justify-content: center;
`;

const ImgContent = styled.div`
    width: 120px;
    height: 120px;
    padding: 4px;
    margin-bottom: 1.5em;
    border-radius: 8px;

    .edit_del {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & > img {
        width: 120px;
        height: 120px;
        border-radius: 8px;
        object-fit: cover;
    }
`;

const EditRound = styled(EDIT_ROUND)`
    cursor: pointer;

    &:hover {
        transition: 0.3s;
        transform: scale(1.1);
    }
`;
const DeleteRound = styled(DELETE_ROUND)`
    cursor: pointer;

    &:hover {
        transition: 0.3s;
        transform: scale(1.1);
    }
`;

interface InputItemProps {
    val: string;
    setVal?: (val: string) => void;
    label: string;
    type: string;
    itemProps?: any;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface Props {
    handleClose: () => void;
    itemData: AccountListProps | AdminListProps | any;
    setItemData:
        | React.Dispatch<React.SetStateAction<AccountListProps | null | undefined>>
        | React.Dispatch<React.SetStateAction<AdminListProps | null | undefined>>;
}

const EditSalesmanAccount = (props: Props) => {
    const { handleClose, itemData, setItemData } = props;

    const dummy_img =
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfpIaGerw5l637QyCc2ZwUJH1KMMzipcYsMxAbDDg2_3zZE6kHxcHyG3caDBMHCFAl_c0&usqp=CAU';

    const [profile_image, setProfileImage] = useState(
        itemData.photo_url ? itemData.photo_url : dummy_img
    );

    const [selectedImg, setSelectedImg] = useState<string | null>(null);

    const [newPassword, setNewPassword] = useState('');
    const [reNewPassword, setReNewPassword] = useState('');

    const [loadingStatus, setLoadingStatus] = useState(false);

    const [salesmanDataCopy, setSalesmanDataCopy] = useState<
        AccountListProps | AdminListProps | null
    >();

    const updateAcc = useUpdateAccount();

    useEffect(() => {
        if (itemData) {
            setSalesmanDataCopy(itemData);
        }
    }, []);

    const itemsAreEqual = () => {
        return _.isEqual(itemData, salesmanDataCopy);
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfileImage('');
        setSelectedImg('');
        const reader = new FileReader();
        reader.onloadend = () => {
            // @ts-ignore
            setProfileImage(reader.result);
        };

        // @ts-ignore
        reader.readAsDataURL(e.target.files[0]);
        // @ts-ignore
        setSelectedImg(e.target.files[0]);

        // @ts-ignore
        uploadToFirebaseStorage(e.target.files[0]);
    };

    const [percent, setPercent] = useState(0);

    const uploadToFirebaseStorage = (image: File) => {
        if (!image) {
            alert('Please choose a file first!');
        }

        const storageRef = ref(storage, `/${itemData.uid}/profile_image.png`);
        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                // update progress
                // setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setItemData({
                        ...itemData,
                        photo_url: url,
                    });

                    updateAcc
                        .mutateAsync({
                            itemData: {
                                ...itemData,
                                photo_url: url,
                            },
                        })
                        .then(() => {
                            setLoadingStatus(false);
                            toast.success('Account edited successfully');
                            handleClose();
                        })
                        .catch(() => {
                            setLoadingStatus(false);
                            toast.error('Error');
                        });
                });
            }
        );
    };

    const deleteProfileImage = (uid: string) => {
        const deleteRef = ref(storage, `/${itemData.uid}/profile_image.png`);

        // Delete the file
        deleteObject(deleteRef)
            .then(() => {
                // Successfully deleted
                updateAcc
                    .mutateAsync({
                        itemData: {
                            ...itemData,
                            photo_url: dummy_img,
                        },
                    })
                    .then(() => {
                        toast.success('File deleted successfully');
                        handleClose();
                        setProfileImage(dummy_img);
                        setSelectedImg(null);
                    });
            })
            .catch((error) => {
                toast.error('Error deleting profile image');
            });
    };

    return (
        <EditAccountWrapper>
            <Heading>Edit Salesman</Heading>
            <div>
                <ImageWrapper>
                    <ImgContent>
                        <img width={180} src={profile_image} alt="profile_image" />

                        <div className="edit_del">
                            <DeleteRound
                                onClick={() => {
                                    if (
                                        window.confirm('Are you sure you want to delete?')
                                    ) {
                                        deleteProfileImage(itemData.uid);
                                    }
                                }}
                            />

                            <input
                                id="upload-id"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => handleImageChange(e)}
                                style={{
                                    display: 'none',
                                    margin: 0,
                                    padding: 0,
                                }}
                            />
                            <label
                                htmlFor="upload-id"
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    height: '51px',
                                }}
                            >
                                <EditRound />
                            </label>
                        </div>
                    </ImgContent>
                </ImageWrapper>
            </div>

            <InputBox
                val={itemData.display_name}
                label={'Enter salesman name'}
                type={'text'}
                itemProps={null}
                onChange={(e) => {
                    setItemData({
                        ...itemData,
                        display_name: e.target.value,
                    });
                }}
            />

            <InputBox
                val={itemData.email}
                label={'Enter salesman email'}
                type={'email'}
                itemProps={null}
                onChange={(e) => {
                    setItemData({
                        ...itemData,
                        email: e.target.value,
                    });
                }}
            />

            <HR />

            <InputBox
                val={newPassword}
                label={'Set new password'}
                type={'password'}
                itemProps={null}
                onChange={(e) => setNewPassword(e.target.value)}
            />

            {/*<CustomTextInput*/}
            {/*    fullWidth*/}
            {/*    label="Set new password"*/}
            {/*    type="password"*/}
            {/*    value={newPassword}*/}
            {/*    onChange={(e) => setNewPassword(e.target.value)}*/}
            {/*/>*/}

            <InputBox
                val={reNewPassword}
                label={'Verify new password'}
                type={'password'}
                itemProps={null}
                onChange={(e) => {
                    setReNewPassword(e.target.value);
                    if (newPassword === e.target.value) {
                        setItemData({
                            ...itemData,
                            password: newPassword,
                        });
                    }

                    if (_.isEmpty(e.target.value)) {
                        delete itemData.password;
                    }
                }}
            />

            {/*<CustomTextInput*/}
            {/*    fullWidth*/}
            {/*    label="Verify new password"*/}
            {/*    type="password"*/}
            {/*    value={reNewPassword}*/}
            {/*    onChange={(e) => {*/}
            {/*        setReNewPassword(e.target.value);*/}
            {/*        if (newPassword === e.target.value) {*/}
            {/*            setItemData({*/}
            {/*                ...itemData,*/}
            {/*                password: newPassword,*/}
            {/*            });*/}
            {/*        }*/}

            {/*        if (_.isEmpty(e.target.value)) {*/}
            {/*            delete itemData.password;*/}
            {/*        }*/}
            {/*    }}*/}
            {/*/>*/}

            {_.isEmpty(reNewPassword) ? (
                <></>
            ) : _.isEqual(newPassword, reNewPassword) ? (
                <ErrorDisplay message="Password match" color={'#00c47b'} />
            ) : (
                <ErrorDisplay message="Password do not match" color={'#ff0000'} />
            )}

            <ButtonCont>
                <ButtonFooter
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Cancel
                </ButtonFooter>
                <EditAccountBtn
                    loading={loadingStatus}
                    disabled={
                        itemsAreEqual() ? true : !_.isEqual(newPassword, reNewPassword)
                    }
                    onClick={() => {
                        if (itemsAreEqual()) {
                            handleClose();
                        } else {
                            setLoadingStatus(true);
                            updateAcc
                                .mutateAsync({ itemData: itemData })
                                .then(() => {
                                    setLoadingStatus(false);
                                    toast.success('Account edited successfully');
                                    handleClose();
                                })
                                .catch(() => {
                                    setLoadingStatus(false);
                                    toast.error('Error');
                                });
                        }
                    }}
                >
                    Change
                </EditAccountBtn>
            </ButtonCont>
        </EditAccountWrapper>
    );
};

export default EditSalesmanAccount;
