import React from 'react';
import { SwitchFreeze } from './switchstyle';
import { SwitchProps } from './interfaces';

export default function FreezeSwitch(props: SwitchProps) {
    return (
        <SwitchFreeze
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            {...props}
        />
    );
}
