import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import HeaderLeft from '../../HeaderLeft';
import SideNav from '../../sidenav/SideNav';
import {
    ButtonContainer,
    ButtonFooter,
    CustomDateInput,
    CustomNotifyInput,
    CustomTextInput,
} from '../../../utils/GlobalStyles';
import { SelectMapModal } from './SelectMapModal';
import { useNavigate } from 'react-router-dom';
import { useAddHayFields } from '../../hooks/useHayVision';
import { toast } from 'react-toastify';
import ModalComponent from '../../modal/ModalComponent';
import { AnimatePresence } from 'framer-motion';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { AddButton, NotifyCount, SubtractButton } from '../../screens/AccountSettings';

import { format, parseISO, isAfter } from 'date-fns';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { selectedUser } from '../../../states/selectors';

import Select, { SingleValue } from 'react-select';
import { useCreateModal } from '../../../utils/useCreateModal';
import NotifyUserQuickGerm from '../../modal_components/NotifyUserQuickGerm';
import { InputBox } from '../../../utils/InputBox';

import { utcToZonedTime } from 'date-fns-tz';

const FieldContentWrapper = styled.div`
    background-color: transparent;
    width: 100%;
    overflow-x: hidden;
    display: grid;
    height: 100vh;
    padding: 0;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'side-nav acc-content';
`;

const HeaderWrapper = styled.div`
    grid-area: acc-header;
    background-color: white;
    padding: 0 4em;
    height: 18vh;
    overflow-x: hidden;
    border-bottom: 1px solid #e9eced;
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 1fr;
    grid-template-areas: 'header-left-content . header-right-content';
`;

const FieldContent = styled.div`
    grid-area: acc-content;
`;

const AddFieldWrapper = styled.div`
    width: 100%;
    height: 80vh;
    margin: 2em 4em 0 2em;
    padding: 0 2em;
    display: grid;
    justify-content: start;
    overflow-y: scroll;
    overflow-x: hidden;

    .form {
        width: 350px;
    }

    ${CustomTextInput} {
        width: 340px;
    }
`;

const ChangeDetailsBtn = styled.button`
    background: #5ea73c;
    padding: 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

const LocationSelector = styled.div`
    font-size: 14px;
    width: 330px;
    height: 185px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #f4f4f4;
    border-radius: 8px;
    color: #98a3aa;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        text-decoration: underline;
    }
`;

export interface FieldProps {
    name: string;
    acres: number;
    active?: boolean;
    hay_type?: number;
    annual_cuts?: number;
    completed_cuts?: number;
    begin_date?: string;
    gps_lat: number;
    gps_long: number;
    location_image?: string;
}

export const formatDate = (value: string) => {
    return value.split('/')[2] + '-' + value.split('/')[0] + '-' + value.split('/')[1];
};

export const renderInput = (props: TextFieldProps): any => {
    return (
        <TextField
            inputRef={props.inputRef}
            className={props.className}
            label={props.label}
            inputProps={{
                ...props.inputProps,
                value: formatDate(props.inputProps?.value),
            }}
            onClick={props.onClick}
            onChange={props.onChange}
            InputProps={{
                endAdornment: props.InputProps?.endAdornment,
            }}
        />
    );
};

interface Option {
    value: number;
    label: string;
}

export const options: Option[] = [
    { value: 1, label: 'Multi-Species (Cool season)' },
    { value: 2, label: 'Multi-Species (Warm season)' },
    { value: 3, label: 'Alfalfa' },
    { value: 4, label: 'Annual Rye' },
    { value: 5, label: 'Barley' },
    { value: 6, label: 'Bermudagrass' },
    { value: 7, label: 'Birdsfoot trefoil' },
    { value: 8, label: 'Bluestem, Big' },
    { value: 9, label: 'Bluestem, Caucasian' },
    { value: 10, label: 'Bluestim, Smooth' },
    { value: 11, label: 'Clover, alsike' },
    { value: 12, label: 'Clover, crimson' },
    { value: 13, label: 'Clover, ladino' },
    { value: 14, label: 'Clover, Red' },
    { value: 15, label: 'Crownvetch' },
    { value: 16, label: 'Eastern Gamagrass' },
    { value: 17, label: 'Fescue, Tall' },
    { value: 18, label: 'Hairy Vetch' },
    { value: 19, label: 'Hemp' },
    { value: 20, label: 'Indiangrass' },
    { value: 21, label: 'Johnsongrass' },
    { value: 22, label: 'Kentucky Bluegrass' },
    { value: 23, label: 'Lespedeza, Common' },
    { value: 24, label: 'Lespedeza, Korean' },
    { value: 25, label: 'Lespedeza, Sericea' },
    { value: 26, label: 'Millet, Pearl' },
    { value: 27, label: 'Oats' },
    { value: 28, label: 'Orchardgrass' },
    { value: 29, label: 'Reed Canarygrass' },
    { value: 30, label: 'Ryegrass, perennial' },
    { value: 31, label: 'Sudangrass' },
    { value: 32, label: 'Switchgrass' },
    { value: 33, label: 'Timothy' },
    { value: 34, label: 'Triticale' },
    { value: 35, label: 'Wheat' },
];

function AddField() {
    let navigate = useNavigate();

    const d = new Date();

    const selectedAccId = selectedUser();

    const [annualCost, setAnnualCost] = useState(0);
    const [completedAnnualCost, setCompletedAnnualCost] = useState(0);

    const [modalAddField, setModalAddField] = useState(false);
    const closeAddField = () => setModalAddField(false);
    const openAddField = () => setModalAddField(true);

    const [selectedOption, setSelectedOption] = useState<any>();

    const notifyModal = useCreateModal();

    const [location, setLocation] = useState('');

    const [dateVal, setDateVal] = React.useState<any>(format(new Date(), 'yyyy/MM/dd'));

    function handleAdd(setVal: {
        (value: React.SetStateAction<number>): void;
        (value: React.SetStateAction<number>): void;
        (arg0: (prevState: any) => any): void;
    }) {
        setVal((prevState) => prevState + 1);
    }

    function handleSubtract(
        val: number,
        setVal: {
            (value: React.SetStateAction<number>): void;
            (arg0: (prevState: any) => number): void;
        }
    ) {
        if (val > 0) {
            setVal((prevState) => prevState - 1);
        }
    }

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
    };

    const [fieldData, setFieldData] = useState<FieldProps>({
        name: '',
        acres: 0,
        active: true,
        hay_type: 0,
        annual_cuts: 0,
        completed_cuts: 0,
        begin_date: `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`,
        gps_long: -101.233888,
        gps_lat: 37.761201,
        location_image: '',
    });

    const handleChangeFieldInfo = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        if (event.target.name === 'name') {
            setFieldData({ ...fieldData, [event.target.name]: event.target.value });
        } else {
            setFieldData({
                ...fieldData,
                [event.target.name]: Number(event.target.value),
            });
        }
    };

    const addHayFields = useAddHayFields();

    function addNewField(fieldData: any) {
        const combinedData = {
            ...fieldData,
            annual_cuts: annualCost,
            completed_cuts: completedAnnualCost,
            begin_date: dateVal,
            uid: selectedAccId,
        };

        addHayFields.mutateAsync(combinedData).then(() => {
            toast.success('Added Successfully');
            navigate(-1);
        });
    }

    return (
        <FieldContentWrapper>
            <SideNav />
            <FieldContent>
                <HeaderWrapper>
                    <HeaderLeft
                        title={'Hay Vision'}
                        subTitle={'Add Field to Hay Vision'}
                    />
                </HeaderWrapper>

                <AddFieldWrapper>
                    <form name={'create_new_field'} className={'form'}>
                        <CustomTextInput
                            label="Enter field name..."
                            type="text"
                            name={'name'}
                            value={fieldData.name}
                            onChange={(e) => handleChangeFieldInfo(e)}
                            required
                        />

                        <CustomTextInput
                            label="Enter field area (in acres)"
                            type="text"
                            name={'acres'}
                            value={fieldData.acres}
                            onChange={(e) => handleChangeFieldInfo(e)}
                            required
                        />

                        <SelectHayType
                            fieldData={fieldData}
                            setFieldData={setFieldData}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                        />

                        <CustomNotifyInput
                            label="Annual Cuts"
                            id="outlined-adornment"
                            disabled
                            value={''}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <NotifyCount>
                                            {annualCost.toLocaleString('en-US', {
                                                minimumIntegerDigits: 2,
                                                useGrouping: false,
                                            })}
                                        </NotifyCount>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            disableTouchRipple={true}
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                handleSubtract(annualCost, setAnnualCost)
                                            }
                                            onMouseDown={(e) =>
                                                handleMouseDownPassword(e)
                                            }
                                            edge="end"
                                        >
                                            <SubtractButton />
                                        </IconButton>
                                        <IconButton
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            disableTouchRipple={true}
                                            aria-label="toggle password visibility"
                                            onClick={() => handleAdd(setAnnualCost)}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            <AddButton />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />

                        <CustomNotifyInput
                            label="Completed Annual Cuts This Year"
                            id="outlined-adornment"
                            disabled
                            value={''}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <NotifyCount>
                                            {completedAnnualCost.toLocaleString('en-US', {
                                                minimumIntegerDigits: 2,
                                                useGrouping: false,
                                            })}
                                        </NotifyCount>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            disableTouchRipple={true}
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                handleSubtract(
                                                    completedAnnualCost,
                                                    setCompletedAnnualCost
                                                )
                                            }
                                            onMouseDown={(e) =>
                                                handleMouseDownPassword(e)
                                            }
                                            edge="end"
                                        >
                                            <SubtractButton />
                                        </IconButton>
                                        <IconButton
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            disableTouchRipple={true}
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                handleAdd(setCompletedAnnualCost)
                                            }
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            <AddButton />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <CustomDateInput
                                views={['year', 'month', 'day']}
                                label="Last Plant/Harvest Date"
                                value={dateVal}
                                onChange={(selectedDate: any) => {
                                    if (selectedDate) {
                                        const dateNoTimezoneShift = new Date(
                                            Date.UTC(
                                                selectedDate.getFullYear(),
                                                selectedDate.getMonth(),
                                                selectedDate.getDate()
                                            )
                                        );

                                        if (isAfter(dateNoTimezoneShift, new Date())) {
                                            notifyModal.openModal();
                                        }

                                        const formattedDate = format(
                                            dateNoTimezoneShift,
                                            'yyyy-MM-dd'
                                        );

                                        setDateVal(formattedDate);
                                    } else {
                                        setDateVal(null);
                                    }
                                }}
                                renderInput={renderInput}
                            />
                        </LocalizationProvider>

                        <div>
                            <p
                                style={{
                                    fontSize: 14,
                                    color: '#98A3AA',
                                    marginBottom: '5px',
                                }}
                            >
                                Field Location
                            </p>
                            {!!fieldData.location_image ? (
                                <p
                                    style={{
                                        fontSize: 12,
                                        color: '#98A3AA',
                                        margin: '0 0 5px 0',
                                    }}
                                >
                                    (Lat: {fieldData.gps_lat.toString().substring(0, 8)}{' '}
                                    Lng: {fieldData.gps_long.toString().substring(0, 8)})
                                </p>
                            ) : null}

                            <LocationSelector
                                onClick={() => {
                                    modalAddField ? closeAddField() : openAddField();
                                }}
                            >
                                {!!fieldData.location_image ? (
                                    <img
                                        src={fieldData.location_image
                                            .replace('size=200x115', 'size=328x182')
                                            .replace('zoom=12', 'zoom=12')
                                            .replace('color:0xCBD1D4', 'color:0xde1d3a')}
                                        alt={''}
                                    />
                                ) : (
                                    'Click to select from map'
                                )}
                            </LocationSelector>
                        </div>

                        <ButtonContainer>
                            <ButtonFooter
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(-1);
                                }}
                            >
                                Cancel
                            </ButtonFooter>
                            <ChangeDetailsBtn
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                        document.forms[
                                            // @ts-ignore
                                            'create_new_field'
                                        ].reportValidity() &&
                                        !!fieldData.location_image
                                    ) {
                                        addNewField(fieldData);
                                    } else {
                                        toast.error('Please fill the details.');
                                    }
                                }}
                            >
                                Add Field
                            </ChangeDetailsBtn>
                        </ButtonContainer>
                    </form>
                </AddFieldWrapper>
            </FieldContent>

            <AnimatePresence
                // Disable any initial animation on children for first render
                initial={false}
                mode={'wait'}
            >
                {notifyModal.modal && (
                    <ModalComponent
                        open={openAddField}
                        handleClose={closeAddField}
                        title={''}
                        width={'25'}
                    >
                        <NotifyUserQuickGerm
                            dateValue={dateVal}
                            handleClose={notifyModal.closeModal}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>

            <AnimatePresence
                // Disable any initial animation on children for first render
                initial={false}
                mode={'wait'}
            >
                {modalAddField && (
                    <ModalComponent
                        open={openAddField}
                        handleClose={closeAddField}
                        title={''}
                        width={'45'}
                    >
                        <SelectMapModal
                            title={'CHOOSE FIELD LOCATION'}
                            onClose={closeAddField}
                            // location={fieldData.location}
                            setLocation={setLocation}
                            fieldData={fieldData}
                            setFieldData={setFieldData}
                            zoomLevel={5}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </FieldContentWrapper>
    );
}

interface ComponentProps {
    fieldData: FieldProps;
    setFieldData: any;
    setSelectedOption: any;
    selectedOption: any;
}

export const SelectHayType: React.FC<ComponentProps> = ({
    fieldData,
    setFieldData,
    setSelectedOption,
    selectedOption,
}) => {
    const handleSelectChange = (selected: SingleValue<Option>) => {
        if (selected) {
            setSelectedOption(selected);
            setFieldData({ ...fieldData, hay_type: selected.value });
        }
    };

    return (
        <Select
            options={options}
            styles={{
                control: (base, state) => ({
                    ...base,
                    height: '52px',
                    minHeight: '52px',
                    margin: '1rem 0',
                    zIndex: 999,
                    width: '340px', // increase width here
                    borderRadius: '8px', // set border radius here
                    borderColor: state.isFocused ? '#484848' : base.borderColor,
                }),
            }}
            classNamePrefix="custom-react-select"
            value={selectedOption}
            onChange={handleSelectChange}
            placeholder="Hay Type"
        />
    );
};

export default AddField;
