import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../context/UserContextProvider';
import { ButtonContainer, CustomTextInput } from '../utils/AdminGlobalStyles';
import { IconButton, InputAdornment } from '@mui/material';

import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const AdminLoginPage = (props: any) => {
    const { loginUser, user } = useAuth();

    let navigate = useNavigate();

    const [user_email, setEmail] = useState('');
    const [user_password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [hasAccount, setHasAccount] = useState(false);

    const clearInputs = () => {
        setEmail('');
        setPassword('');
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = (email: string, password: string) => {
        loginUser(email, password).then((token) => {
            if (!!token) {
                navigate('/dashboard', { replace: true });
            } else {
                navigate('/', { replace: true });
            }
        });
    };

    return (
        <>
            <Heading>Login as Admin</Heading>

            {/*<CustomTextInput*/}
            {/*    fullWidth*/}
            {/*    label="Enter email"*/}
            {/*    type="email"*/}
            {/*    required*/}
            {/*    value={user_email}*/}
            {/*    onChange={(e) => setEmail(e.target.value)}*/}
            {/*/>*/}

            <InputItem
                val={user_email}
                setVal={setEmail}
                label={'Enter email'}
                type={'text'}
                itemProps={null}
            />

            <InputItem
                val={user_password}
                setVal={setPassword}
                label={'Enter password'}
                type={showPassword ? 'text' : 'password'}
                itemProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end"
                            >
                                {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            {/*<CustomTextInput*/}
            {/*    fullWidth*/}
            {/*    type="password"*/}
            {/*    label="Enter password"*/}
            {/*    required*/}
            {/*    value={user_password}*/}
            {/*    onChange={(e) => setPassword(e.target.value)}*/}
            {/*/>*/}

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <ForgotPassword
                    onClick={() => {
                        setHasAccount(!hasAccount);
                        clearInputs();
                    }}
                >
                    Forget Password?
                </ForgotPassword>
            </div>

            <ButtonContainer>
                <LoginButton
                    onClick={() => {
                        handleLogin(user_email.trim(), user_password.trim());
                    }}
                >
                    Login
                </LoginButton>
            </ButtonContainer>
        </>
    );
};

interface InputItemProps {
    val: string;
    setVal: (val: string) => void;
    label: string;
    type: string;
    itemProps?: any;
}

export const InputItem = ({ val, setVal, label, type, itemProps }: InputItemProps) => {
    const [focused, setFocused] = useState(false);

    return (
        <CustomTextInput
            fullWidth
            onFocus={(e) => {
                if (e.target.value.length >= 0) {
                    setFocused(true);
                } else {
                    setFocused(false);
                }
            }}
            onBlur={(e) => {
                if (e.target.value.length > 0) {
                    setFocused(true);
                } else {
                    setFocused(false);
                }
            }}
            focused={focused}
            label={label}
            type={type}
            required
            InputProps={itemProps}
            value={val}
            onChange={(e) => {
                setVal(e.target.value);
                if (e.target.value.length > 0) {
                    setFocused(true);
                } else {
                    setFocused(false);
                }
            }}
        />
    );
};

const Heading = styled.h3`
    padding: 2% 0;
`;

const ForgotPassword = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;

    &:hover {
        color: #646464;
    }
`;

const LoginButton = styled.button`
    background: #000000;
    width: 100%;
    padding: 12px 8px;
    color: white;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    &:hover {
        //background: #3f3f3f;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
`;

export default AdminLoginPage;
