import React, { useEffect, useState } from 'react';
import HeaderLeft from '../../HeaderLeft';
import SideNav from '../../sidenav/SideNav';
import HeaderRight from '../../HeaderRight';
import FreezeSwitch from './FreezeSwitch';
import HayVisionSwitch from './HayVisionSwitch';
import CropVisionSwitch from './CropVisionSwitch';
import {
    update_worker,
    useDeleteWorker,
    useUpdateWorker,
    useWorkers,
} from '../../hooks/useWorkers';

import {
    EditIcon,
    HeaderWrapper,
    ImageWrapper,
    ImgContent,
    ProfileSubtitle,
    ProfileTitle,
    ProfileTitleWrapper,
    WorkerProfileWrapper,
    WorkersContent,
    WorkersWrapper,
} from './styled/styled.ts';
import { dummy } from '../../screens/Profile';

import { DeleteIcon, DeleteMenuItem, EditMenu, EditMenuItem } from '../styles/hayVision';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { userObject } from '../../../states/selectors';
import { useGetAccountDetails } from '../../hooks/useAccounts';

export default function Workers() {
    const [allWorkersState, setAllWorkersState] = useState([]);
    const { data: user } = useGetAccountDetails();

    const {
        isSuccess: isAllWorkersSuccess,
        isError: isAllWorkersError,
        error: allWorkersError,
        data: allWorkerData,
    } = useWorkers();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [workerId, setWorkerId] = useState('');
    const open = Boolean(anchorEl);

    const handleClick = (event, id) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setWorkerId(id);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateWorker = useUpdateWorker();
    const delWorker = useDeleteWorker();

    useEffect(() => {
        setAllWorkersState(allWorkerData);
    }, [allWorkerData]);

    const handleUpdate = async (worker, category) => {
        await setAllWorkersState((prevState) =>
            prevState.map((w) => {
                if (w.uid === worker.uid) {
                    return {
                        ...w,
                        [category]: !worker[category],
                    };
                } else {
                    return w;
                }
            })
        );

        let data = { worker: worker, category: category, id: worker.uid };
        await updateWorker.mutateAsync(data).catch((err) => {
            toast.error(err.message);
            setTimeout(() => {
                setAllWorkersState(allWorkerData);
            }, 2000);
        });
    };

    return (
        <WorkersWrapper>
            <SideNav />

            <WorkersContent>
                <HeaderWrapper>
                    <HeaderLeft
                        title={user?.account_name}
                        subTitle={`${user?.workers_count} Workers & ${user?.hay_vision_fields_count} Fields`}
                    />
                    <HeaderRight text={'add_worker'} />
                </HeaderWrapper>
                <div style={{ overflowY: 'auto', height: '82vh' }}>
                    <table>
                        <colgroup>
                            <col span="1" style={{ width: '50%' }} />
                            <col span="1" style={{ width: '10%' }} />
                            {/*<col span="1" style={{ width: '10%' }} />*/}
                            {/*<col span="1" style={{ width: '10%' }} />*/}
                        </colgroup>
                        <thead>
                            <tr className={'table-header'}>
                                <th>WORKERS</th>
                                <th className={'mid-title'}>FREEZE</th>
                                {/*<th className={'mid-title'}>HAY VISION</th>*/}
                                {/*<th className={'mid-title'}>CROP VISION</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {isAllWorkersSuccess &&
                            !!allWorkerData &&
                            !!allWorkersState ? (
                                allWorkersState.map((value, index) => (
                                    <tr key={index}>
                                        <td>
                                            <WorkerProfileWrapper
                                                style={{
                                                    transition: 'all 0.3s ease',
                                                    opacity: value['disabled'] ? 0.5 : 1,
                                                }}
                                            >
                                                <ImageWrapper>
                                                    <ImgContent>
                                                        <img
                                                            width={45}
                                                            src={dummy}
                                                            alt="logo"
                                                        />
                                                    </ImgContent>
                                                </ImageWrapper>

                                                <ProfileTitleWrapper>
                                                    <div>
                                                        <ProfileTitle>
                                                            {value.display_name}
                                                        </ProfileTitle>
                                                        <ProfileSubtitle>
                                                            {value.email}
                                                        </ProfileSubtitle>
                                                    </div>
                                                    <EditIcon
                                                        onMouseDown={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        onClick={(e) =>
                                                            handleClick(e, value.uid)
                                                        }
                                                    />
                                                </ProfileTitleWrapper>
                                            </WorkerProfileWrapper>
                                        </td>
                                        <td className={'mid-title'}>
                                            <FreezeSwitch
                                                checked={value['disabled']}
                                                onChange={() =>
                                                    handleUpdate(value, 'disabled')
                                                }
                                            />
                                        </td>
                                        {/*<td className={'mid-title'}>*/}
                                        {/*    <HayVisionSwitch*/}
                                        {/*        hidebutton={value['disabled'] ? 0 : 1}*/}
                                        {/*        disabled={value['disabled']}*/}
                                        {/*        checked={value['hay_vision']}*/}
                                        {/*        onChange={() =>*/}
                                        {/*            handleUpdate(value, 'hay_vision')*/}
                                        {/*        }*/}
                                        {/*    />*/}
                                        {/*</td>*/}
                                        {/*<td className={'mid-title'}>*/}
                                        {/*    <CropVisionSwitch*/}
                                        {/*        hidebutton={value['disabled'] ? 0 : 1}*/}
                                        {/*        disabled={value['disabled']}*/}
                                        {/*        checked={value['crop_vision']}*/}
                                        {/*        onChange={() =>*/}
                                        {/*            handleUpdate(value, 'crop_vision')*/}
                                        {/*        }*/}
                                        {/*    />*/}
                                        {/*</td>*/}
                                    </tr>
                                ))
                            ) : isAllWorkersError ? (
                                <p>{allWorkersError.message}</p>
                            ) : null}
                        </tbody>

                        <EditMenu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <DeleteMenuItem
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            'Are you sure you want to remove this worker?'
                                        )
                                    ) {
                                        handleClose();
                                        delWorker.mutate(workerId);
                                    }
                                }}
                            >
                                <EditMenuItem>
                                    <DeleteIcon />
                                    Remove
                                </EditMenuItem>
                            </DeleteMenuItem>
                        </EditMenu>
                    </table>
                </div>
            </WorkersContent>
        </WorkersWrapper>
    );
}
