import styled from 'styled-components';
import { Menu } from '@mui/material';
import { ReactComponent as FREEZE } from '../../static/icons/freeze.svg';
import { FaRegTrashAlt } from 'react-icons/fa';

export const EditDots = styled.img`
    cursor: pointer;
    border-radius: 50px;
    transition: all 0.1s ease-in-out;

    &:hover {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    }
`;

export const EditMenu = styled(Menu)``;

export const EditMenuItem = styled.div`
    display: inline-flex;
    justify-content: left;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    width: 100%;
    gap: 0.8em;
`;

interface FreezeProps {
    is_freeze?: boolean;
    change_color?: any;
}

export const FreezeIcon = styled(FREEZE)<FreezeProps>`
    width: 1.1rem;

    & > path {
        fill: ${(props) => props.change_color};
    }
`;

export const DeleteIcon = styled(FaRegTrashAlt)`
    font-size: 1rem !important;
`;
