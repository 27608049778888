import React from 'react';
import styled from 'styled-components';
import ECT_VISION from '../static/images/logo.svg';

const NavBarWrapper = styled.div`
    background-color: transparent;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'left_header . right_header right_header right_header';
`;

const NavMenu = styled.nav`
    grid-area: right_header;
`;

const NavList = styled.li`
    list-style: none;
    color: black;
    padding: 20px 20px 20px 0;
    display: inline-block;
    font-weight: 500;
    margin: 0 0;
    font-size: 12px;
    cursor: pointer;
    position: relative;
`;

const NavLogo = styled.div`
    grid-area: left_header;
    display: grid;
    /*align-content: center;*/
    /*align-items: center;*/
    padding: 2em 0;
    width: auto;
`;

function NavBar() {
    return (
        <NavBarWrapper>
            <NavLogo>
                <img src={ECT_VISION} alt="logo" />
            </NavLogo>

            <NavMenu>
                <ul>
                    <NavList>Hay Vision</NavList>
                    <NavList>Garden Vision</NavList>
                    <NavList>Crop Vision</NavList>
                </ul>
            </NavMenu>
        </NavBarWrapper>
    );
}

export default NavBar;
