import { useEffect, useState } from 'react';
import { CustomTextInput } from './GlobalStyles';

type InputBoxProps = {
    val: string;
    setVal?: (val: string) => void;
    label: string;
    type: string;
    itemProps?: any;
    onKeyDown?: (e: any) => void;
};

export const InputBox = (props: InputBoxProps) => {
    const { val, setVal, label, type, itemProps, onKeyDown } = props;

    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (val) {
            setFocused(true);
        } else {
            setFocused(false);
        }
    }, [val]);

    return (
        <CustomTextInput
            fullWidth
            onKeyDown={onKeyDown}
            onFocus={(e) => {
                if (e.target.value.length >= 0) {
                    setFocused(true);
                } else {
                    setFocused(false);
                }
            }}
            onBlur={(e) => {
                if (e.target.value.length > 0) {
                    setFocused(true);
                } else {
                    setFocused(false);
                }
            }}
            focused={focused}
            label={label}
            type={type}
            required
            InputProps={itemProps}
            value={val}
            onChange={(e) => {
                if (setVal) {
                    setVal(e.target.value);
                    if (e.target.value.length > 0) {
                        setFocused(true);
                    } else {
                        setFocused(false);
                    }
                }
            }}
        />
    );
};
