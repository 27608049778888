import React, { memo, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import FIRST_LOGIN_BG from '../../static/images/first-login-bg.png';
import FLOWER_POTS from '../../static/images/flower-pots.svg';
import ECT_VISION from '../../static/images/logo.svg';
import { toast } from 'react-toastify';
import { ButtonContainer, ButtonFooter, CustomTextInput } from '../../utils/GlobalStyles';
import { useGetAccountDetails, useUpdateAccountDetails } from '../hooks/useAccounts';
import { setUserObject } from '../../states/actions';
import { userObject } from '../../states/selectors';
import { useDispatch } from 'react-redux';
import { useUpdateAccount } from '../../admin/hooks/useAccounts';
import { useAuth } from '../../context/UserContextProvider';

type WelcomeProps = {
    user_data: any;
    setIsClicked: (value: boolean) => void;
};

const Welcome = memo(function Welcome(props: WelcomeProps) {
    const [password, setPassword] = useState('');
    const [re_password, setRePassword] = useState('');

    const updateAcc = useUpdateAccount();

    const { loginUser } = useAuth();

    function changePassword() {
        if (password === re_password && password !== '' && re_password !== '') {
            const data = {
                uid: props.user_data.uid,
                password: password,
            };

            updateAcc
                .mutateAsync({ itemData: data })
                .then((res) => {
                    loginUser(props.user_data.email, password).then((res) => {
                        props.setIsClicked(true);
                        toast.success('Password changed successfully');
                    });
                })
                .catch((err) => {
                    console.log(err);
                    toast.error('Something went wrong');
                });
        } else {
            toast.error("Password didn't match!", {
                hideProgressBar: true,
            });
        }
    }

    return (
        <WelcomeWrapper>
            {props.user_data !== null ? (
                <>
                    <WelcomeHeading>
                        Hi {props.user_data['display_name'].split(' ')[0]},
                    </WelcomeHeading>
                    <WelcomePara>
                        We would recommend you to change your default password to a custom
                        one :)
                    </WelcomePara>

                    <CustomTextInput
                        fullWidth
                        label="Enter password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <CustomTextInput
                        fullWidth
                        label="Re-Enter password"
                        type="password"
                        value={re_password}
                        onChange={(e) => setRePassword(e.target.value)}
                    />

                    <ButtonContainer>
                        <ButtonFooter
                            onClick={() => {
                                toast.info('No change in password', {
                                    hideProgressBar: true,
                                });
                                props.setIsClicked(true);
                            }}
                        >
                            Skip for now
                        </ButtonFooter>
                        <ChangePasswordBtn onClick={() => changePassword()}>
                            Change
                        </ChangePasswordBtn>
                    </ButtonContainer>
                </>
            ) : (
                <>Loading...</>
            )}
        </WelcomeWrapper>
    );
});

const ProfileDetails = memo(function ProfileDetails(props: WelcomeProps) {
    const { user_data } = props;
    let history = useNavigate();

    const user = userObject();
    const dispatch = useDispatch();

    const updateUserDetails = useUpdateAccountDetails();

    const [displayName, setDisplayName] = useState('');
    const [accountName, setAccountName] = useState('');

    useEffect(() => {
        setDisplayName(user_data['display_name']);
        setAccountName(user_data['account_name']);
    }, []);

    function handleChangeDetails(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        const data = {
            account_name: accountName,
            display_name: displayName,
            setup_complete: true,
        };

        updateUserDetails.mutateAsync(data).then(() => {
            toast.success('Update Successful');

            dispatch(
                setUserObject({
                    ...user,
                    account_name: accountName,
                    display_name: displayName,
                    setup_complete: true,
                })
            );

            history('/home', { replace: true });
        });
    }

    return (
        <WelcomeWrapper>
            <WelcomeHeading>Your Details,</WelcomeHeading>
            <WelcomePara>You can edit these details in the future as well.</WelcomePara>

            <CustomTextInput
                fullWidth
                label="Your Name"
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
            />

            <CustomTextInput
                fullWidth
                label="Account Name"
                type="text"
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
            />

            <CustomTextInput
                fullWidth
                label="Email"
                type="email"
                disabled
                value={user_data['email']}
            />

            <ButtonContainer>
                <ButtonFooter
                    onClick={() => {
                        props.setIsClicked(false);
                    }}
                >
                    Cancel
                </ButtonFooter>
                <ChangeDetailsBtn onClick={(e) => handleChangeDetails(e)}>
                    Done
                </ChangeDetailsBtn>
            </ButtonContainer>
        </WelcomeWrapper>
    );
});

const FirstLogin = memo(function FirstLogin() {
    const [userData, setUserData] = useState(null);

    const [isClicked, setIsClicked] = useState(false);

    const userDetails = useGetAccountDetails();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!userDetails.isLoading) {
            setUserData(userDetails.data);
            dispatch(setUserObject(userDetails.data));
        }
    }, [userDetails.data]);

    return (
        <AfterLoginWrapper>
            <NavLogoContent>
                <img src={ECT_VISION} alt="logo" />
            </NavLogoContent>

            <MidContent>
                {!isClicked ? (
                    <Welcome user_data={userData} setIsClicked={setIsClicked} />
                ) : (
                    <ProfileDetails user_data={userData} setIsClicked={setIsClicked} />
                )}
            </MidContent>

            <FlowerPotImg>
                <img width={200} src={FLOWER_POTS} alt={''} />
            </FlowerPotImg>
        </AfterLoginWrapper>
    );
});

const AfterLoginWrapper = styled.div`
    background-image: url(${FIRST_LOGIN_BG});
    overflow-x: hidden;
    height: 100vh;
    display: grid;
    padding: 0 5%;
    background-repeat: no-repeat;
    background-size: cover;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
        'top-left-first top-left-first . . .'
        '. . mid-content-first . bottom-content-first'
        '. . . . bottom-content-first';
`;

const MidContent = styled.div`
    grid-area: mid-content-first;
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    transition: 0.3s;
    display: inline-block;
    background-color: white;
    width: 400px;

    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.103);
    }
`;

const FlowerPotImg = styled.div`
    grid-area: bottom-content-first;
    display: grid;
    justify-content: end;
    align-content: end;
`;

const NavLogoContent = styled.div`
    grid-area: top-left-first;
    display: grid;
    width: auto;
    padding: 2em 0;
`;

const WelcomeWrapper = styled.div`
    padding: 5% 8%;
`;

const WelcomeHeading = styled.h1`
    font-weight: 600;
    font-size: 30px;
`;

const WelcomePara = styled.p`
    font-size: 12px;
`;

const ChangePasswordBtn = styled.button`
    background: #5ea73c;
    padding: 12px 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

const ChangeDetailsBtn = styled.button`
    background: #5ea73c;
    padding: 12px 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

export default FirstLogin;
