import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HeaderLeft from '../../HeaderLeft';
import SideNav from '../../sidenav/SideNav';
import {
    ButtonContainer,
    ButtonFooter,
    CustomDateInput,
    CustomNotifyInput,
    CustomTextInput,
} from '../../../utils/GlobalStyles';
import { SelectMapModal } from './SelectMapModal';
import { useNavigate, useParams } from 'react-router-dom';
import { get_hay_vision_fields_by_id, useUpdateHayField } from '../../hooks/useHayVision';
import { toast } from 'react-toastify';
import ModalComponent from '../../modal/ModalComponent';
import { AnimatePresence } from 'framer-motion';
import { useQuery } from 'react-query';
import { FieldProps, options, renderInput, SelectHayType } from './AddField';
import { format, isAfter, parseISO } from 'date-fns';
import { IconButton, InputAdornment } from '@mui/material';
import { AddButton, NotifyCount, SubtractButton } from '../../screens/AccountSettings';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { selectedUser } from '../../../states/selectors';
import NotifyUserQuickGerm from '../../modal_components/NotifyUserQuickGerm';
import { useCreateModal } from '../../../utils/useCreateModal';
import { utcToZonedTime } from 'date-fns-tz';

const FieldContentWrapper = styled.div`
    background-color: transparent;
    width: 100%;
    overflow-x: hidden;
    display: grid;
    height: 100vh;
    padding: 0;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'side-nav acc-content';
`;

const HeaderWrapper = styled.div`
    grid-area: acc-header;
    background-color: white;
    padding: 0 4em;
    height: 18vh;
    overflow-x: hidden;
    border-bottom: 1px solid #e9eced;
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 1fr;
    grid-template-areas: 'header-left-content . header-right-content';
`;

const FieldContent = styled.div`
    grid-area: acc-content;
`;

const UpdateFieldWrapper = styled.div`
    width: 100%;
    height: 78vh;
    margin: 2em 4em 0 2em;
    padding: 0 2em;
    display: grid;
    justify-content: start;
    overflow-y: scroll;
    overflow-x: hidden;

    .form {
        width: 350px;
    }

    ${CustomTextInput} {
        width: 340px;
    }
`;

const ChangeDetailsBtn = styled.button`
    background: #5ea73c;
    padding: 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

const LocationSelector = styled.div`
    font-size: 14px;
    width: 330px;
    height: 185px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #f4f4f4;
    border-radius: 8px;
    color: #98a3aa;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        text-decoration: underline;
    }
`;

export default function EditField() {
    let navigate = useNavigate();

    const { fieldId } = useParams();

    const selectedAccId = selectedUser();

    const notifyModal = useCreateModal();

    const updateHayFields = useUpdateHayField();

    const field_by_id = useQuery(
        ['hayVisionFields', fieldId],
        () =>
            selectedAccId
                ? get_hay_vision_fields_by_id(fieldId, selectedAccId)
                : get_hay_vision_fields_by_id(fieldId),
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setFieldData(data);
                setLocation(data.location_image);
                setDateVal(data.begin_date);
                setAnnualCost(data.annual_cuts);
                setCompletedAnnualCost(data.completed_cuts);
            },
            onError: (data) => {
                toast.error('Error fetching field data');
            },
        }
    );

    const [annualCost, setAnnualCost] = useState(0);
    const [completedAnnualCost, setCompletedAnnualCost] = useState(0);

    const [dateVal, setDateVal] = React.useState<any>(format(new Date(), 'yyyy/MM/dd'));

    function handleAdd(setVal: {
        (value: React.SetStateAction<number>): void;
        (value: React.SetStateAction<number>): void;
        (arg0: (prevState: any) => any): void;
    }) {
        setVal((prevState) => prevState + 1);
    }

    function handleSubtract(
        val: number,
        setVal: {
            (value: React.SetStateAction<number>): void;
            (arg0: (prevState: any) => number): void;
        }
    ) {
        if (val > 0) {
            setVal((prevState) => prevState - 1);
        }
    }

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
    };

    const [location, setLocation] = useState('');

    const [fieldData, setFieldData] = useState<FieldProps>({
        name: '',
        acres: 0,
        active: true,
        hay_type: 0,
        annual_cuts: 0,
        completed_cuts: 0,
        begin_date: '',
        gps_long: 0,
        gps_lat: 0,
        location_image: '',
    });

    const handleChangeFieldInfo = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        if (event.target.name === 'name') {
            setFieldData({ ...fieldData, [event.target.name]: event.target.value });
        } else {
            setFieldData({
                ...fieldData,
                [event.target.name]: Number(event.target.value),
            });
        }
    };

    function updateField(fieldData: FieldProps) {
        const combinedData = {
            ...fieldData,
            active: true,
            annual_cuts: annualCost,
            completed_cuts: completedAnnualCost,
            begin_date: format(new Date(dateVal), 'yyyy-MM-dd'),
            uid: selectedAccId,
        };

        toast
            .promise(updateHayFields.mutateAsync(combinedData), {
                pending: 'Updating...',
                success: 'Field updated successfully!',
                error: 'Error updating field!',
            })
            .then(() => {
                navigate(-1);
            });
    }

    const [modalAddField, setModalAddField] = useState(false);
    const closeAddField = () => setModalAddField(false);
    const openAddField = () => setModalAddField(true);

    const [selectedOption, setSelectedOption] = useState<any>();

    useEffect(() => {
        if (fieldData.hay_type) {
            setSelectedOption({
                value: fieldData.hay_type,
                label: options[fieldData.hay_type - 1].label,
            });
        }
    }, [fieldData]);

    return (
        <FieldContentWrapper>
            <SideNav />
            <FieldContent>
                <HeaderWrapper>
                    <HeaderLeft
                        title={'Hay Vision'}
                        subTitle={'Update Field in Hay Vision'}
                    />
                </HeaderWrapper>

                <UpdateFieldWrapper>
                    <form name={'update_field'} className={'form'}>
                        <CustomTextInput
                            label="Enter field name..."
                            type="text"
                            name={'name'}
                            value={fieldData.name}
                            onChange={(e) => handleChangeFieldInfo(e)}
                            required
                        />
                        <CustomTextInput
                            label="Enter field area (in acres)"
                            type="text"
                            name={'acres'}
                            value={fieldData.acres}
                            onChange={(e) => handleChangeFieldInfo(e)}
                            required
                        />

                        {/*<CustomTextInput*/}
                        {/*    label="Enter hay type"*/}
                        {/*    type="text"*/}
                        {/*    name={'hay_type'}*/}
                        {/*    value={fieldData.hay_type}*/}
                        {/*    onChange={(e) => handleChangeFieldInfo(e)}*/}
                        {/*    required*/}
                        {/*/>*/}

                        <SelectHayType
                            fieldData={fieldData}
                            setFieldData={setFieldData}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                        />

                        <CustomNotifyInput
                            label="Annual Cuts"
                            id="outlined-adornment"
                            disabled
                            value={''}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <NotifyCount>
                                            {annualCost
                                                ? annualCost.toLocaleString('en-US', {
                                                      minimumIntegerDigits: 2,
                                                      useGrouping: false,
                                                  })
                                                : null}
                                        </NotifyCount>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            disableTouchRipple={true}
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                handleSubtract(annualCost, setAnnualCost)
                                            }
                                            onMouseDown={(e) =>
                                                handleMouseDownPassword(e)
                                            }
                                            edge="end"
                                        >
                                            <SubtractButton />
                                        </IconButton>
                                        <IconButton
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            disableTouchRipple={true}
                                            aria-label="toggle password visibility"
                                            onClick={() => handleAdd(setAnnualCost)}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            <AddButton />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />

                        <CustomNotifyInput
                            label="Completed Annual Cuts This Year"
                            id="outlined-adornment"
                            disabled
                            value={''}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <NotifyCount>
                                            {completedAnnualCost.toLocaleString('en-US', {
                                                minimumIntegerDigits: 2,
                                                useGrouping: false,
                                            })}
                                        </NotifyCount>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            disableTouchRipple={true}
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                handleSubtract(
                                                    completedAnnualCost,
                                                    setCompletedAnnualCost
                                                )
                                            }
                                            onMouseDown={(e) =>
                                                handleMouseDownPassword(e)
                                            }
                                            edge="end"
                                        >
                                            <SubtractButton />
                                        </IconButton>
                                        <IconButton
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            disableTouchRipple={true}
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                handleAdd(setCompletedAnnualCost)
                                            }
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            <AddButton />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <CustomDateInput
                                label="Last Plant/Harvest Date"
                                value={dateVal}
                                onChange={(selectedDate: any) => {
                                    if (selectedDate) {
                                        const dateWithoutTimezoneShift = new Date(
                                            Date.UTC(
                                                selectedDate.getFullYear(),
                                                selectedDate.getMonth(),
                                                selectedDate.getDate()
                                            )
                                        );

                                        if (
                                            isAfter(dateWithoutTimezoneShift, new Date())
                                        ) {
                                            notifyModal.openModal();
                                        }

                                        const formattedDate = format(
                                            dateWithoutTimezoneShift,
                                            'yyyy-MM-dd'
                                        );

                                        setDateVal(formattedDate);
                                    } else {
                                        // Set the date value to null or a default value when cleared
                                        setDateVal(null);
                                    }
                                }}
                                renderInput={renderInput}
                            />
                        </LocalizationProvider>

                        <div>
                            <p
                                style={{
                                    fontSize: 14,
                                    color: '#98A3AA',
                                    marginBottom: '5px',
                                }}
                            >
                                Field Location
                            </p>
                            {!!location ? (
                                <p
                                    style={{
                                        fontSize: 12,
                                        color: '#98A3AA',
                                        marginBottom: '0 0 5px 0',
                                    }}
                                >
                                    (Lat: {fieldData.gps_lat.toString().substring(0, 8)}{' '}
                                    Lng: {fieldData.gps_long.toString().substring(0, 8)})
                                </p>
                            ) : null}

                            <LocationSelector
                                onClick={() => {
                                    modalAddField ? closeAddField() : openAddField();
                                }}
                            >
                                {!!location ? (
                                    <img
                                        src={location
                                            .replace('size=200x115', 'size=328x182')
                                            .replace('zoom=10', 'zoom=10')
                                            .replace('color:0xCBD1D4', 'color:0xde1d3a')}
                                        alt={''}
                                    />
                                ) : (
                                    'Click to select location'
                                )}
                            </LocationSelector>
                        </div>

                        <ButtonContainer>
                            <ButtonFooter
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(-1);
                                }}
                            >
                                Cancel
                            </ButtonFooter>
                            <ChangeDetailsBtn
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                        // @ts-ignore
                                        document.forms['update_field'].reportValidity() &&
                                        !!location
                                    ) {
                                        updateField(fieldData);
                                    } else {
                                        toast.error('Please fill the details.');
                                    }
                                }}
                            >
                                Update Field
                            </ChangeDetailsBtn>
                        </ButtonContainer>
                    </form>
                </UpdateFieldWrapper>
            </FieldContent>

            <AnimatePresence initial={false} mode={'wait'}>
                {notifyModal.modal && (
                    <ModalComponent
                        open={openAddField}
                        handleClose={closeAddField}
                        title={''}
                        width={'25'}
                    >
                        <NotifyUserQuickGerm
                            dateValue={dateVal}
                            handleClose={notifyModal.closeModal}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>

            <AnimatePresence initial={false} mode={'wait'}>
                {modalAddField && (
                    <ModalComponent
                        open={openAddField}
                        handleClose={closeAddField}
                        title={''}
                        width={'45'}
                    >
                        <SelectMapModal
                            title={'CHOOSE FIELD LOCATION'}
                            onClose={closeAddField}
                            location={location}
                            setLocation={setLocation}
                            fieldData={fieldData}
                            setFieldData={setFieldData}
                            zoomLevel={12}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </FieldContentWrapper>
    );
}
