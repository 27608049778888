import React from 'react';

import { useNavigate } from 'react-router-dom';
import SideNav from '../sidenav/SideNav';
import { BackArrowIcon, BackArrowWrapper } from '../hay_vision/styles/hayField';

import styled from 'styled-components';
import HAY_VISION from '../../static/images/garden-vision-long.png';
import BARCODE from '../../static/images/barcode.png';
import GREEN_LOGO from '../../static/images/logo-green.svg';

import { FiArrowUpRight } from 'react-icons/fi';

export const GardenVisionContent = styled.div`
    height: 100vh;
    display: grid;
    overflow-y: hidden;
    padding: 0;
    grid-template-columns: 240px auto;
    grid-template-rows: auto;
    grid-template-areas:
        'side-nav home-content home-content'
        'side-nav home-content home-content'
        'side-nav home-content home-content';
`;

const HeadingSection = styled.div`
    width: auto;
    height: 200px;
    margin: 2em 3em 1.5em;
    box-shadow: rgba(100, 100, 111, 0.3) 0 10px 30px 0;
    padding: 2em;
    border-radius: 10px;
    background-position: top;
    background-image: url(${HAY_VISION});
    background-size: cover;
    background-repeat: no-repeat;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;
`;

const MainContent = styled.div`
    grid-area: home-content;
    background: #ffffff;
    overflow-y: scroll;
`;

export const Content = styled.div`
    width: auto;
    margin: 3em 5em;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    align-items: flex-start;
    justify-items: center;
    font-family: 'Poppins', sans-serif;
`;

export const Title = styled.h3`
    font-family: Oswald, sans-serif;
    font-style: normal;
    font-weight: 500;
    opacity: 0.8;
    margin: 0;
    font-size: 3.6em;
    text-transform: uppercase;
`;

const Description = styled.p`
    font-size: 1rem;
    color: #9ba298;
    width: 90%;
`;

const ArrowLink = styled(FiArrowUpRight)`
    font-size: 1.5em;
    color: #9ba298;
    cursor: pointer;

    &:hover {
        color: #767e76;
    }
`;

const BtnContainer = styled.div`
    border: 1px solid #e7eaeb;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    width: 380px;
    margin: 3em 5em;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
        border: 1px solid #d0d0d0;
    }

    > div {
        display: flex;
        width: 100%;
        align-items: center;

        * {
            padding: 0;
            margin: 0;
            font-family: 'Poppins', sans-serif;
        }

        h4 {
            font-family: Oswald, sans-serif;
            font-size: 1.6em;
            font-weight: 500;
            margin: 0 0.4em;
        }

        p {
            margin: 0 0.8em;
            color: #9ba298;
            font-family: 'Poppins', sans-serif;

            &:hover {
                text-decoration: underline;
            }
        }
    }
`;

const ImgContainer = styled.div`
    display: grid;
    gap: 0.2em;

    p {
        color: #9ba298;
        margin: 0;
        padding: 0;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export default function GardenVision() {
    let navigate = useNavigate();

    return (
        <GardenVisionContent>
            <SideNav />
            <MainContent>
                <HeadingSection>
                    <div
                        style={{ display: 'inline-flex', gridArea: '3/1' }}
                        onClick={() => navigate(-1)}
                    >
                        <BackArrowWrapper>
                            <BackArrowIcon /> GARDEN VISION
                        </BackArrowWrapper>
                    </div>
                </HeadingSection>

                <Content>
                    <div>
                        <Title>
                            USE TECHNOLOGY TO INCREASE PLANT HEALTH AND PRODUCTION.
                        </Title>
                        <Description>
                            Every plant has what we call an Energy Curve to track – its
                            lifetime cycle of the energy it intakes and outputs to grow
                            from seed. What if you could track it, and know exactly when
                            it needed which nutrients? Our Vision app lets us do just that
                            – and you can too!
                        </Description>
                    </div>

                    <ImgContainer>
                        <p>Available in Android & iOS</p>
                        <img width={'200px'} src={BARCODE} alt={'barcode'} />
                    </ImgContainer>
                </Content>

                <BtnContainer
                    onClick={() =>
                        window.open('https://www.energycurve.com/garden', '_blank')
                    }
                >
                    <div>
                        <img width={'50px'} src={GREEN_LOGO} alt={'logo'} />
                        <div>
                            <h4>Garden Vision</h4>
                            <p>Click to learn more</p>
                        </div>
                    </div>
                    <ArrowLink />
                </BtnContainer>
            </MainContent>
        </GardenVisionContent>
    );
}
