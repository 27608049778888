import React from 'react';
import styled from 'styled-components';
import HAY_VISION from '../../../static/images/hay_vision_new.png';
import { compareAsc, format, parse } from 'date-fns';
import { ReactComponent as ERROR } from '../../../static/icons/error.svg';
import { MdNotificationsActive } from 'react-icons/md';
import USER_ICON from '../../../static/icons/user-icon.png';
import { rgbToHex } from '@mui/material';
import { useUpdateEvent } from '../../hooks/useEvents';
import { toast } from 'react-toastify';

function EventsSection(props) {
    const data = props.value;

    const {
        setCompleteCount,
        completeCount,
        modalNotifyWorker,
        open,
        handleClose,
        setZoomLevel,
        setMapCenter,
        setSelectedPin,
        selectedPin,
    } = props;

    function dateFormatToDayOnly(date) {
        return format(parse(date, 'yyyy-MM-dd', new Date()), 'MMM d');
    }

    const updateEvent = useUpdateEvent();

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    return (
        <EventsWrapper
            data={data}
            onClick={() => {
                const location = {
                    address: '',
                    lat: data.gps_lat,
                    lng: data.gps_long,
                };

                setMapCenter(location);
                setZoomLevel(12);
            }}
        >
            <section style={{ padding: '1em 2em' }}>
                <EventTitle>
                    <div>
                        HAY VISION <span className={'field_name'}>{data.field_name}</span>
                    </div>
                    <NotificationIcon
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={() => (modalNotifyWorker ? handleClose() : open())}
                    >
                        <MdNotificationsActive
                            style={{ fontSize: 20, fill: '#9BA298' }}
                        />
                    </NotificationIcon>
                </EventTitle>
                <EventDescription>{data.text}</EventDescription>
                <EventActions>
                    {data.status === 1 ? (
                        <>
                            <ButtonComplete
                                data={data}
                                onClick={() => {
                                    setCompleteCount(completeCount + 1);
                                    updateEvent
                                        .mutateAsync({
                                            id: data.id,
                                            status: 2,
                                            completed_date: formattedDate,
                                        })
                                        .catch(() => {
                                            console.log('error');
                                            setCompleteCount(completeCount);
                                            toast.error(
                                                'Error marking event as complete'
                                            );
                                        });
                                }}
                            >
                                Mark Complete
                            </ButtonComplete>
                        </>
                    ) : (
                        <ButtonNotComplete
                            onClick={() => {
                                updateEvent
                                    .mutateAsync({
                                        id: data.id,
                                        status: 1,
                                    })
                                    .then(() => setCompleteCount(completeCount - 1))
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            }}
                        >
                            <CompleteText>
                                <p>
                                    <span>&#10004; </span>Completed on{' '}
                                    {dateFormatToDayOnly(
                                        format(new Date(), 'yyyy-MM-dd')
                                    )}
                                </p>
                                <p
                                    style={{
                                        color: 'gray',
                                        margin: '0',
                                    }}
                                >
                                    was due on {dateFormatToDayOnly(data.due)}
                                </p>
                            </CompleteText>

                            <ResetButton>UNMARK</ResetButton>
                        </ButtonNotComplete>
                    )}
                    {data.status === 2 ? (
                        <UserIconWrapper>
                            <UserIconDetails>{'full_name'}</UserIconDetails>
                            <UserIcon />
                        </UserIconWrapper>
                    ) : compareAsc(
                          parse(data.due, 'yyyy-MM-dd', new Date()),
                          parse(
                              format(new Date(), 'yyyy-MM-dd'),
                              'yyyy-MM-dd',
                              new Date()
                          )
                      ) === 1 ? (
                        <DueText>Due on {dateFormatToDayOnly(data.due)}</DueText>
                    ) : (
                        <DueTextRed>
                            <span>
                                <ERROR_ICON />
                            </span>
                            Due on {dateFormatToDayOnly(data.due)}
                        </DueTextRed>
                    )}
                </EventActions>
            </section>
        </EventsWrapper>
    );
}

const DueText = styled.p`
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
    display: block;
    color: gray;
`;

const DueTextRed = styled(DueText)`
    color: red;
    display: inline-flex;
    justify-content: center;
`;

const ERROR_ICON = styled(ERROR)`
    width: 15px;
    margin-right: 8px;

    > path {
        fill: #ee0000;
    }
`;

const EventDescription = styled.p`
    font-size: 14px;
`;

const EventActions = styled.div`
    width: 100%;
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
`;

const ButtonComplete = styled.button`
    border: none;
    margin: 0;
    padding: 0.8em 0;
    width: 130px;
    background: ${(props) => {
        if (
            compareAsc(
                parse(props['data'].due, 'yyyy-MM-dd', new Date()),
                parse(format(new Date(), 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date())
            ) !== 1
        ) {
            if (props['data'].status === 2) {
                return '#f4fff3';
            }
            return '#fff8f8';
        } else {
            return 'rgba(97,169,63,0.08)';
        }
    }};
    color: ${(props) => {
        if (
            compareAsc(
                parse(props['data'].due, 'yyyy-MM-dd', new Date()),
                parse(format(new Date(), 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date())
            ) !== 1
        ) {
            if (props['data'].status === 2) {
                return '#61a93f';
            }
            return '#ff3535';
        } else {
            return '#61a93f';
        }
    }};
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        background: ${(props) => {
            if (
                compareAsc(
                    parse(props['data'].due, 'yyyy-MM-dd', new Date()),
                    parse(format(new Date(), 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date())
                ) !== 1
            ) {
                if (props['data'].status === 2) {
                    return '#f9fff7';
                }
                return '#ffebeb';
            } else {
                return 'rgba(97,169,63,0.15)';
            }
        }};
    }
`;

const ButtonNotComplete = styled.button`
    background: transparent;
    width: max-content;
    padding: 1% 2%;
    color: #575757;

    border: none;
    margin: 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        background: #eeeeee;
        color: #575757;
    }
`;

const ResetButton = styled.p`
    margin: 0;
    width: 100px;
    padding: 0.6em 0;
    height: auto;
    display: none;
    transition: all ease-in-out 200ms;
`;

const CompleteText = styled.div`
    margin: 0;
    color: #61a93f;
    padding: 0 0;

    > p {
        margin: 0;
        padding: 0;
        font-size: 11px;
        color: #61a93f;
    }
`;

const EventsWrapper = styled.div`
    //background-color: transparent;
    border-bottom: ${(props) => {
        if (
            compareAsc(
                parse(props['data'].due, 'yyyy-MM-dd', new Date()),
                parse(format(new Date(), 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date())
            ) !== 1
        ) {
            if (props['data'].status === 2) {
                return '3px solid ' + rgbToHex('rgba(97,169,63,0.60)');
            }
            return '3px solid ' + rgbToHex('rgba(255,53,53,0.60)');
        } else {
            return '1px solid ' + rgbToHex('rgba(229,229,229,0.29)');
        }
    }};

    overflow-x: hidden;
    font-family: 'Poppins', sans-serif !important;

    &:hover {
        background-color: rgba(211, 211, 211, 0.05) !important;
        cursor: pointer;
    }

    &:hover > section > ${EventActions} > ${ButtonNotComplete} > ${ResetButton} {
        display: block;
    }

    &:hover > section > ${EventActions} > ${ButtonNotComplete} > ${CompleteText} {
        display: none;
    }

    &:hover > section > ${EventActions} > ${ButtonNotComplete} {
        background: #eeeeee;
        color: #575757;
    }
`;

const EventTitle = styled.div`
    font-weight: 600;
    transition: 0.3s;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;

    & > div > .field_name {
        color: #9ba298;
        font-size: 12px;
        margin-left: 5px;
    }
`;

const Icons = styled.img`
    width: 100%;
    height: 100%;
    transition: 0.4s;
    box-shadow: rgba(100, 100, 111, 0.3) 0 10px 30px 0;
`;

Icons.defaultProps = {
    src: HAY_VISION,
};

const UserIconDetails = styled.span`
    font-size: 10px;
    font-weight: 600;
    color: transparent;
    margin-right: 10px;
    transition: 0.2s;
    transform: translateX(30px);
`;

const NotificationIcon = styled.div`
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    padding: 6px;
    cursor: pointer;

    &:hover {
        box-shadow:
            rgba(0, 0, 0, 0.02) 0 1px 3px 0,
            rgba(27, 31, 35, 0.15) 0 0 0 1px;
    }
`;

const UserIconWrapper = styled.span`
    display: inline-flex;
    align-content: center;
    align-items: center;
    transition: 0.2s;

    &:hover > ${UserIconDetails} {
        transform: translateX(0px);
        color: teal;
    }
`;

const UserIcon = styled.img`
    width: 32px;
    height: 32px;
`;

UserIcon.defaultProps = {
    src: USER_ICON,
};

export default EventsSection;
