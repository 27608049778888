import React, { useState } from 'react';
import styled from 'styled-components';
import NavBar from '../components/navbar/NavBar';
import AdminTeam from '../components/dashboard/AdminTeam';
import { useGetAllAccounts } from '../hooks/useAccounts';
import AccountUsers from '../components/dashboard/AccountUsers';
import { useGetAllAdmins } from '../hooks/useAdmin';
import Salesman from '../components/dashboard/Salesman';
import { useGetAllSalesman } from '../hooks/useSalesman';

const DashboardWrapper = styled.div`
    background: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    display: grid;
    padding: 0 6em 0 4em;
    grid-row-gap: 4em;
`;

const ContentWrapper = styled.div`
    border-radius: 16px;
    transition: 0.3s;
    //display: inline-flex;
    background-color: white;
`;

const AdminContentWrapper = styled.div`
    //margin: 4rem 0;
    justify-content: end;
    align-content: end;
`;

const NavContent = styled.div`
    width: auto;
    padding-top: 3em;
`;

export default function AdminDashboard() {
    const [searchInput, setSearchInput] = useState('');

    const { data: account_data } = useGetAllAccounts();
    const { data: admin_accounts } = useGetAllAdmins();
    const { data: salesman_data } = useGetAllSalesman();

    return (
        <DashboardWrapper>
            <NavContent>
                <NavBar searchInput={searchInput} setSearchInput={setSearchInput} />
            </NavContent>
            <ContentWrapper>
                <AccountUsers searchInput={searchInput} accounts_list={account_data} />
            </ContentWrapper>
            <AdminContentWrapper>
                <AdminTeam admin_accounts={admin_accounts} />
            </AdminContentWrapper>
            <ContentWrapper style={{ marginBottom: '4em' }}>
                <Salesman salesman_data={salesman_data} />
            </ContentWrapper>
        </DashboardWrapper>
    );
}
