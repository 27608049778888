import {
    ALL_EVENTS,
    CREATE_HAY_VISION_FIELDS,
    DELETE_HAY_VISION_FIELDS,
    EDIT_HAY_VISION_FIELDS,
    HAY_VISION_FIELDS,
    UPDATE_HAY_VISION_FIELDS,
} from '../../config';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import axiosInstance from '../../services/axiosInstance';
import { selectedUser } from '../../states/selectors';

export async function get_hay_vision_events(id: any) {
    const url = id ? `${ALL_EVENTS}?uid=${id}` : ALL_EVENTS;

    return axiosInstance({
        method: 'GET',
        url: url,
    }).then((response) => response.data);
}

async function get_hay_vision_fields(id: any) {
    const url = id ? `${HAY_VISION_FIELDS}?uid=${id}` : HAY_VISION_FIELDS;

    return axiosInstance({
        method: 'GET',
        url: url,
    }).then((response) => response.data);
}

export async function get_hay_vision_fields_by_id(
    fieldId: string | undefined,
    uid?: string
) {
    const url = uid
        ? `${EDIT_HAY_VISION_FIELDS}/${fieldId}?uid=${uid}`
        : `${EDIT_HAY_VISION_FIELDS}/${fieldId}`;

    return axiosInstance({
        method: 'GET',
        url: url,
    }).then((response) => response.data);
}

const add_new_field = (field: { uid: any }) => {
    const url = field.uid
        ? `${CREATE_HAY_VISION_FIELDS}?uid=${field.uid}`
        : `${CREATE_HAY_VISION_FIELDS}`;

    return axiosInstance({
        method: 'POST',
        url: url,
        data: field,
    }).then((res) => res.data);
};

const update_new_field = (field: { uid: any }) => {
    const url = field.uid
        ? `${UPDATE_HAY_VISION_FIELDS}?uid=${field.uid}`
        : `${UPDATE_HAY_VISION_FIELDS}`;

    return axiosInstance({
        method: 'POST',
        url: url,
        data: field,
    }).then((res) => res.data);
};

const delete_field = (fieldId: any) => {
    const selectedAccId = selectedUser();

    const url = selectedAccId
        ? `${DELETE_HAY_VISION_FIELDS}?uid=${selectedAccId}`
        : `${DELETE_HAY_VISION_FIELDS}`;

    return axiosInstance({
        method: 'POST',
        url: url,
        data: {
            id: fieldId,
        },
    }).then((res) => res.data);
};

export function useHayVisionFields(selectedAccId: string) {
    return useQuery('hayVisionFields', () =>
        selectedAccId ? get_hay_vision_fields(selectedAccId) : get_hay_vision_fields(null)
    );
}

export const useAddHayFields = () => {
    const queryClient = useQueryClient();
    return useMutation(add_new_field, {
        onSuccess: () => {
            queryClient.invalidateQueries('hayVisionFields');
        },
        onError: (error: any) => {
            console.error(error.response.data.error);
            toast.error(error.response.data.error);
        },
    });
};
export const useUpdateHayField = () => {
    const queryClient = useQueryClient();
    return useMutation(update_new_field, {
        onSuccess: () => {
            queryClient.invalidateQueries('hayVisionFields');
        },
    });
};

export const useDeleteHayField = () => {
    const queryClient = useQueryClient();
    return useMutation(delete_field, {
        onSuccess: () => {
            queryClient
                .invalidateQueries('hayVisionFields')
                .then((r) => toast.success('Deleted Successfully'));
        },
    });
};
