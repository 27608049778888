import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GET_LOGGED_IN_USER, UPDATE_LOGGED_IN_USER } from '../../config';
import axiosInstance from '../../services/axiosInstance';
import { setUserObject } from '../../states/actions';
import { useDispatch } from 'react-redux';
import { selectedUser, userObject } from '../../states/selectors';

const get_account_details = (id: string) => {
    const url = id ? `${GET_LOGGED_IN_USER}?uid=${id}` : GET_LOGGED_IN_USER;

    return axiosInstance({
        method: 'GET',
        url: url,
    }).then((response) => response.data);
};

const update_account_details = (data: any) => {
    const url = data.uid
        ? `${UPDATE_LOGGED_IN_USER}?uid=${data.uid}`
        : UPDATE_LOGGED_IN_USER;

    return axiosInstance({
        method: 'POST',
        url: url,
        data: data,
    }).then((response) => response.data);
};

export const useGetAccountDetails = () => {
    const selectedAccId = selectedUser();
    return useQuery(
        ['accountDetails', selectedAccId],
        () => get_account_details(selectedAccId),
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useUpdateAccountDetails = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const user = userObject();
    return useMutation(update_account_details, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('accountDetails').then(() => {
                dispatch(
                    setUserObject({
                        ...user,
                        ...data,
                    })
                );
            });
        },
        onError: (data) => {
            console.error(data);
        },
    });
};
