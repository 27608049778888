import React, { useState } from 'react';
// @ts-ignore
import FOO_IMG from '../../static/images/profile-picture.webp';
import DOTS from '../../static/icons/three-dots.svg';
import styled from 'styled-components';
import { DeleteIcon, EditMenu, EditMenuItem, FreezeIcon } from './commonStyled';
import { MenuItem } from '@mui/material';
import { DeleteMenuItem } from './AdminTeam';

import { MdEdit } from 'react-icons/md';

import { AccountListProps } from '../../@types/user';
import { PhotoIcn } from '../../utils/AdminGlobalStyles';
import ModalComponent from '../modalComponents/config/ModalComponent';
import { AnimatePresence } from 'framer-motion';
import EditUserAccount from '../modalComponents/EditUser/EditUserAccount';
import { useDeleteAccount, useUpdateAccount } from '../../hooks/useAccounts';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setSelectedUser } from '../../../states/actions';
import { useNavigate } from 'react-router-dom';
import { useCreateModal } from '../../../utils/useCreateModal';

const AccountsWrapper = styled.div`
    //padding: 4% 8%;
    display: grid;
    gap: 2em;
    grid-template-columns: 1fr 1fr 1fr;
`;

const AccountTitle = styled.h3`
    font-weight: 500;
    font-size: 1em;
    color: #9ba298;
    font-family: 'Oswald', sans-serif;
`;

const EachAccountWrapper = styled.div`
    width: auto;
    height: 70px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 16px;
    padding: 0 0.5em 0 0;
    transition: all 0.1s ease-in-out;

    & > div:first-child {
        display: inline-flex;
        gap: 2em;
        align-items: center;
    }

    &:hover {
        background-color: #fcfcfc;
        border: 1px solid #eaeaea;
    }
`;

const EditDots = styled.img`
    cursor: pointer;
    border-radius: 50px;
    transition: all 0.1s ease-in-out;

    &:hover {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    }
`;

const FarmName = styled.h2`
    font-size: 1.2em;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    padding: 0;
    margin: 0;
`;

const FullName = styled.p`
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: #9ba298;
    padding: 0;
    margin: 0;
`;

interface AccProps {
    searchInput: string;
    accounts_list: any | null;
}

const AccountUsers = (props: AccProps) => {
    const { searchInput, accounts_list } = props;

    const dispatch = useDispatch();

    const navigateTo = useNavigate();
    const modalEditUser = useCreateModal();

    const deleteAcc = useDeleteAccount();
    const updateAcc = useUpdateAccount();

    const [freezeVal, setFreezeVal] = useState(false);
    const [itemData, setItemData] = useState<AccountListProps | null>();

    const [anchorEl, setAnchorEl] = React.useState<HTMLImageElement | null>(null);
    const open = Boolean(anchorEl);

    const filteredAccounts = (data: any, searchInp: string) => {
        return !!data
            ? data.filter(
                  (obj: { display_name: string; email: string }) =>
                      obj.display_name.toLowerCase().includes(searchInp.toLowerCase()) ||
                      obj.email.toLowerCase().includes(searchInp.toLowerCase())
              )
            : [];
    };

    const handleClick = (
        event: React.MouseEvent<HTMLImageElement>,
        item: AccountListProps
    ) => {
        event.stopPropagation();
        setItemData(item);
        // setFreezeVal(freeze_val);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AccountTitle>ACCOUNTS</AccountTitle>
            <AccountsWrapper>
                {filteredAccounts(accounts_list, searchInput)?.map(
                    (item: AccountListProps, index: React.Key | null | undefined) => {
                        return (
                            <EachAccountWrapper key={index}>
                                <div
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        dispatch(setSelectedUser(item.uid));
                                        navigateTo(`/home/${item.uid}`);
                                    }}
                                >
                                    <PhotoIcn>
                                        <img
                                            style={{
                                                borderRadius: '16px',
                                                objectFit: 'cover',
                                            }}
                                            width={'72px'}
                                            height={'72px'}
                                            src={
                                                item.photo_url
                                                    ? item.photo_url
                                                    : `${FOO_IMG}`
                                            }
                                            alt=""
                                        />
                                    </PhotoIcn>
                                    <div>
                                        <FarmName>{item.account_name}</FarmName>
                                        <FullName>{item.display_name}</FullName>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <EditDots
                                        src={DOTS}
                                        alt="edit"
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onClick={(e) => {
                                            setFreezeVal(item.disabled);
                                            handleClick(e, item);
                                        }}
                                    />
                                </div>
                            </EachAccountWrapper>
                        );
                    }
                )}
            </AccountsWrapper>
            <EditMenu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    style={{ width: '12em', padding: '0.8em' }}
                    onClick={() => {
                        setAnchorEl(null);
                        modalEditUser.openModal();
                    }}
                >
                    <EditMenuItem>
                        <MdEdit style={{ fontSize: '1.1rem' }} />
                        Edit Account
                    </EditMenuItem>
                </MenuItem>
                <MenuItem
                    style={{ width: '12em', padding: '0.8em' }}
                    onClick={() => {
                        setItemData((prevState) => {
                            if (prevState) {
                                let itemData: any = {
                                    ...prevState,
                                    disabled: !freezeVal,
                                };
                                updateAcc.mutateAsync({ itemData: itemData }).then(() => {
                                    toast.success('Account updated successfully');
                                    handleClose();
                                });
                                return itemData;
                            }
                        });

                        setFreezeVal((prevState) => !prevState);
                    }}
                >
                    <EditMenuItem>
                        {freezeVal ? (
                            <>
                                <FreezeIcon change_color={'#00f'} />
                                Unfreeze Account
                            </>
                        ) : (
                            <>
                                <FreezeIcon change_color={'#111'} />
                                Freeze Account
                            </>
                        )}
                    </EditMenuItem>
                </MenuItem>
                <DeleteMenuItem
                    onClick={() => {
                        if (
                            window.confirm('Are you sure you want to delete this field?')
                        ) {
                            const userInfo = {
                                uid: itemData?.uid,
                                delete_account: true,
                            };

                            deleteAcc.mutateAsync({ data: userInfo }).then(() => {
                                toast.success('Account deleted successfully');
                                handleClose();
                            });
                        }
                    }}
                >
                    <EditMenuItem>
                        <DeleteIcon />
                        Delete Account
                    </EditMenuItem>
                </DeleteMenuItem>
            </EditMenu>

            <AnimatePresence initial={false} mode={'wait'}>
                {modalEditUser.modal && itemData && (
                    <ModalComponent
                        key={'config-worker'}
                        open={modalEditUser.openModal}
                        handleClose={modalEditUser.closeModal}
                        width={'25'}
                        min_height={'200px'}
                    >
                        <EditUserAccount
                            itemData={itemData}
                            setItemData={setItemData}
                            handleClose={modalEditUser.closeModal}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </>
    );
};

export default AccountUsers;
