import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import SideNav from '../../sidenav/SideNav';
import GARDEN_VISION from '../../../static/images/garden-vision.png';
import CROP_VISION from '../../../static/images/crop-vision.png';
import COMING_SOON from '../../../static/images/coming-soon.png';
import EventsSection from '../../hay_vision/events/EventsSection';
import Map from '../../map/Map';
import EventsProgressBar from '../../EventsProgressBar';
import WorkersNotifiedModal from '../../modal_components/WorkersNotifiedModal';
import {
    AllEventSection,
    ARROW,
    ComingSoon,
    ComingSoonWrapper,
    Content,
    DashboardContent,
    EventHeader,
    EventHeading,
    EventSubHeading,
    EventWrapper,
    Heading,
    ItemWrapper,
    MainContent,
    MapSection,
    ResetButton,
    SubHeading,
    VisionHeading,
    VisionImg,
    VisionImgWrapper,
    NoEventImg,
} from './styled';
import { getAllEvents } from '../../hooks/useEvents';
import { AnimatePresence } from 'framer-motion';
import ModalComponent from '../../modal/ModalComponent';
import { ModalCloseButton, ModalFooter } from '../../../utils/GlobalStyles';
import { useQuery } from 'react-query';
import { selectedUser } from '../../../states/selectors';
import { useGetAccountDetails } from '../../hooks/useAccounts';

import NO_EVENTS from '../../../static/images/no-events.png';
import { useHayVisionFields } from '../../hooks/useHayVision';

export function dateComparison(value1, value2) {
    return new Date(value1.due).getTime() - new Date(value2.due).getTime();
}

const HomePage = () => {
    let navigate = useNavigate();

    const { data: user } = useGetAccountDetails();

    const [showModal, setShowModal] = useState(false);
    const [completeCount, setCompleteCount] = useState(0);

    const [initialBounds, setInitialBounds] = React.useState(null);
    const [mapObjects, setMapObjects] = React.useState(null);
    const [selectedPin, setSelectedPin] = React.useState(null);

    const [location, setLocation] = useState({
        address: '',
        lat: 38.887653,
        lng: -102.950467,
    });

    const [zoomLevel, setZoomLevel] = useState(3);
    const [mapCenter, setMapCenter] = useState(location);

    const selectedAccId = selectedUser();

    const { isLoading: isHayFieldsLoading, data: hayFields } =
        useHayVisionFields(selectedAccId);

    const [modalNotifyWorker, setModalNotifyWorker] = useState(false);
    const closeModalNotify = () => setModalNotifyWorker(false);
    const openModalNotify = () => setModalNotifyWorker(true);

    const {
        isLoading,
        isError,
        error,
        data: allEvents,
    } = useQuery('allEvents', getAllEvents, {
        onSuccess: (events) => {
            const completeCount = events.filter((eachObject) => eachObject.status === 2);
            setCompleteCount(completeCount.length);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const allEventsFiltered = allEvents?.filter((eachEvent) => {
        return hayFields?.some((eachField) => {
            return eachField?.id === eachEvent?.field_id && eachField?.active === true;
        });
    });

    return (
        <DashboardContent>
            <SideNav />
            <MainContent>
                <Heading>{user?.account_name}</Heading>
                <SubHeading>
                    {user?.workers_count} Workers & {user?.hay_vision_fields_count} Fields
                </SubHeading>

                <Content>
                    <ItemWrapper onClick={() => navigate('/hay-vision')}>
                        <VisionImg />
                        <VisionHeading>
                            HAY VISION<ARROW>&#10132;</ARROW>
                        </VisionHeading>
                    </ItemWrapper>
                    <ItemWrapper onClick={() => navigate('/garden-vision')}>
                        <VisionImg src={GARDEN_VISION} />
                        <VisionHeading>
                            GARDEN VISION<ARROW>&#10132;</ARROW>
                        </VisionHeading>
                    </ItemWrapper>
                    <ComingSoonWrapper>
                        <ComingSoon src={COMING_SOON} />
                        <VisionImgWrapper>
                            <VisionImg src={CROP_VISION} />
                        </VisionImgWrapper>

                        <VisionHeading>
                            CROP VISION<ARROW>&#10132;</ARROW>
                        </VisionHeading>
                    </ComingSoonWrapper>
                </Content>
            </MainContent>

            {!isLoading && !!hayFields && !!allEvents ? (
                <EventWrapper>
                    <MapSection>
                        <Map
                            location={location}
                            setMapCenter={setMapCenter}
                            mapCenter={mapCenter}
                            zoomLevel={zoomLevel}
                            setZoomLevel={setZoomLevel}
                            mapLocations={hayFields}
                            setMapObjects={setMapObjects}
                            mapObjects={mapObjects}
                            initialBounds={initialBounds}
                            setInitialBounds={setInitialBounds}
                            selectedPin={selectedPin}
                            setSelectedPin={setSelectedPin}
                        />
                    </MapSection>

                    <EventsProgressBar
                        allEventsCount={allEventsFiltered.length}
                        completeCount={completeCount}
                    />

                    <EventHeader>
                        <EventHeading>ALL EVENTS</EventHeading>
                        <EventSubHeading>
                            <ResetBtn
                                mapObjects={mapObjects}
                                initialBounds={initialBounds}
                                setZoomLevel={setZoomLevel}
                                setSelectedPin={setSelectedPin}
                                setMapCenter={setMapCenter}
                            />

                            <span>
                                {completeCount}/{allEventsFiltered.length}
                            </span>
                        </EventSubHeading>
                    </EventHeader>

                    <AllEventSection>
                        {allEventsFiltered.length !== 0 ? (
                            allEventsFiltered.sort(dateComparison).map((value, index) => {
                                return (
                                    <EventsSection
                                        key={index}
                                        value={value}
                                        completeCount={completeCount}
                                        modalNotifyWorker={modalNotifyWorker}
                                        open={openModalNotify}
                                        handleClose={closeModalNotify}
                                        setCompleteCount={setCompleteCount}
                                        setLocation={setLocation}
                                        location={location}
                                        setMapCenter={setMapCenter}
                                        mapCenter={mapCenter}
                                        setZoomLevel={setZoomLevel}
                                        setSelectedPin={setSelectedPin}
                                        selectedPin={selectedPin}
                                    />
                                );
                            })
                        ) : (
                            <NoEventImg>
                                <img src={NO_EVENTS} alt={''} />{' '}
                            </NoEventImg>
                        )}
                    </AllEventSection>

                    <AnimatePresence
                        // Disable any initial animation on children for first render
                        initial={false}
                        mode={'wait'}
                    >
                        {modalNotifyWorker && (
                            <ModalComponent
                                key={'modal-worker'}
                                open={openModalNotify}
                                handleClose={closeModalNotify}
                                width={'40'}
                                minheight={'400px'}
                                footer={
                                    <ModalFooter>
                                        <ModalCloseButton onClick={closeModalNotify}>
                                            Close
                                        </ModalCloseButton>
                                    </ModalFooter>
                                }
                            >
                                <WorkersNotifiedModal
                                    title="Workers Notified"
                                    subText={
                                        'This is the list of workers who were notified about this event.'
                                    }
                                    onClose={() => setShowModal(false)}
                                    show={showModal}
                                />
                            </ModalComponent>
                        )}
                    </AnimatePresence>
                </EventWrapper>
            ) : isError ? (
                <p>Error {error.message}</p>
            ) : (
                <>Loading...</>
            )}
        </DashboardContent>
    );
};

export function ResetBtn({
    mapObjects,
    initialBounds,
    setZoomLevel,
    setSelectedPin,
    setMapCenter,
}) {
    function resetView() {
        if (mapObjects && initialBounds) {
            const { map, maps } = mapObjects;
            map.fitBounds(initialBounds);

            // Get the current map state after fitBounds and update React state
            const currentZoom = map.getZoom();
            const currentCenter = map.getCenter();

            setZoomLevel(currentZoom);
            setMapCenter({ lat: currentCenter.lat(), lng: currentCenter.lng() });
        }
    }

    return (
        <ResetButton
            onClick={() => {
                resetView();
                setSelectedPin(null);
            }}
        >
            RESET
        </ResetButton>
    );
}

export default HomePage;
