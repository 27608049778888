import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonContainer, ButtonFooter } from '../../../utils/AdminGlobalStyles';

import { toast } from 'react-toastify';
import { InputItem } from '../../../screens/AdminLoginPage';
import { IconButton, InputAdornment } from '@mui/material';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { LoadingButton } from '@mui/lab';
import { useCreateSalesman } from '../../../hooks/useSalesman';

interface Props {
    handleClose: () => void;
}

const CreateAccountWrapper = styled.div`
    padding: 1em;
    color: #414149;
`;

const Heading = styled.h1`
    margin: 0 0 0.5em 0;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #9ba298;
`;

const CreateAccountBtn = styled(LoadingButton)`
    background: #000000 !important;
    border: none !important;
    color: white !important;
    font-family: 'Poppins', sans-serif !important;
    cursor: pointer !important;
    padding: 10px 8px !important;
    font-weight: 600 !important;
    width: 100% !important;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px !important;

    &:hover {
        transition: 0.3s !important;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16) !important;
    }

    &:disabled {
        background: #e1e1e1 !important;
        cursor: not-allowed !important;
        box-shadow: none !important;
    }
`;

const ButtonCont = styled(ButtonContainer)`
    padding: 1rem 0 0 0;
`;

export const handleClickShowPassword = (
    pwd: boolean,
    setPwd: {
        (value: React.SetStateAction<boolean>): void;
        (arg0: boolean): void;
    }
) => {
    setPwd(!pwd);
};

const CreateSalesmanAccount = (props: Props) => {
    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [loadingStatus, setLoadingStatus] = useState(false);

    const createSalesMan = useCreateSalesman();

    const resetFields = () => {
        setEmail('');
        setPassword('');
        setLoadingStatus(false);
    };

    const handleCreateSalesman = () => {
        setLoadingStatus(true);
        const new_data = {
            salesman: true,
            display_name: 'Salesman',
            email: email,
            password: password,
            phone_number: null,
            photo_url: null,
            farm_name: null,
        };

        createSalesMan.mutateAsync({ data: new_data }).then(() => {
            toast.success('Created new Salesman');
            resetFields();
            props.handleClose();
        });
    };

    return (
        <CreateAccountWrapper>
            <Heading>ADD SALESMAN</Heading>

            <InputItem
                val={email}
                setVal={setEmail}
                label={'Enter salesman email'}
                type={'email'}
                itemProps={null}
            />

            {/*<CustomTextInput*/}
            {/*    fullWidth*/}
            {/*    label="Enter salesman email"*/}
            {/*    type="email"*/}
            {/*    value={email}*/}
            {/*    onChange={(e) => setEmail(e.target.value.trim())}*/}
            {/*/>*/}

            <InputItem
                val={password}
                setVal={setPassword}
                label={'Enter salesman password'}
                type={showPassword ? 'text' : 'password'}
                itemProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() =>
                                    handleClickShowPassword(showPassword, setShowPassword)
                                }
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end"
                            >
                                {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            {/*<CustomTextInput*/}
            {/*    fullWidth*/}
            {/*    label="Enter salesman password"*/}
            {/*    type="password"*/}
            {/*    value={password}*/}
            {/*    onChange={(e) => setPassword(e.target.value.trim())}*/}
            {/*/>*/}

            <ButtonCont>
                <ButtonFooter onClick={() => props.handleClose()}>Cancel</ButtonFooter>
                <CreateAccountBtn
                    disabled={loadingStatus}
                    onClick={() => handleCreateSalesman()}
                >
                    Add Salesman
                </CreateAccountBtn>
            </ButtonCont>
        </CreateAccountWrapper>
    );
};

export default CreateSalesmanAccount;
