import axios, { AxiosError } from 'axios';
import { selectFirebaseToken } from '../states/selectors';

const base_URL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
    baseURL: base_URL,
});

// Adding some information before sending request.
axiosInstance.interceptors.request.use(
    (conf) => {
        if (conf.headers) {
            conf.headers['Authorization'] = selectFirebaseToken();
            conf.headers['Content-Type'] = 'application/json';
            conf.headers['Accept'] = '*/*';
        }
        return conf;
    },
    (error: AxiosError) => {
        console.error(error);
        return Promise.reject(error);
    }
);

export default axiosInstance;
