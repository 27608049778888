import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Backdrop } from './Backdrop';

interface ModalStyleProps {
    minwidth?: string;
    width?: string;
    variants?: any;
    initial?: string;
    animate?: string;
    exit?: string;
    height?: string;
    minheight?: string;
}

interface ModalProps {
    open: () => void;
    handleClose: () => void;
    children: React.ReactNode;
    minwidth?: string;
    width?: string;
    title?: string;
    footer?: React.ReactNode;
    minheight?: string;
    height?: string;
}

const ModalContainer = styled(motion.div)<ModalStyleProps>`
    width: clamp(
        ${(props) => props.minwidth || 20}%,
        ${(props) => props.width || 40}%,
        90%
    );
    //width: clamp(30%, 600px, 90%);
    height: min-content;
    min-height: ${(props) => props.minheight || 'auto'};
    max-height: 80%;
    margin: auto;
    padding: 1rem 0.1rem 1rem 2rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    //align-items: center;
    background-color: #fff;
    z-index: 10000;
    position: relative;
`;

const ModalTitle = styled.div`
    position: sticky;
    background-color: #fff;
    top: 0;
    padding-bottom: 10px;
    text-transform: uppercase;
    border-bottom: 1px solid #cccccc;
    letter-spacing: 1px;
    width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
    font-size: 16px;
    font-weight: 600;
`;

const ModalBody = styled.div`
    //overflow: auto;
    width: auto;
    padding: 0 2rem 0 0;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */

    &::-webkit-scrollbar {
        width: 4px;
    }
`;

const dropIn = {
    hidden: {
        y: '100vh',
        opacity: 0,
    },
    visible: {
        y: '0',
        opacity: 1,
        transition: {
            duration: 0.5,
        },
    },
    exit: {
        y: '100vh',
        opacity: 0,
        transition: {
            duration: 0.5,
        },
    },
};

const ModalComponent = (props: ModalProps) => {
    return (
        <Backdrop onClick={props.handleClose}>
            <ModalContainer
                onClick={(e) => e.stopPropagation()}
                variants={dropIn}
                initial={'hidden'}
                animate={'visible'}
                exit={'exit'}
                minwidth={props.minwidth}
                width={props.width}
                height={props.height}
                minheight={props.minheight}
            >
                {!!props.title ? <ModalTitle>{props.title}</ModalTitle> : null}

                <ModalBody>{props.children}</ModalBody>

                {props.footer}
            </ModalContainer>
        </Backdrop>
    );
};

export default ModalComponent;
