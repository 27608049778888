import React, { useState } from 'react';
import DOTS from '../../static/icons/three-dots.svg';
import styled from 'styled-components';

import { MenuItem } from '@mui/material';
import { DeleteIcon, EditDots, EditMenu, EditMenuItem, FreezeIcon } from './commonStyled';
import { AdminListProps } from '../../@types/user';
// @ts-ignore
import FOO_IMG from '../../static/images/profile-picture.webp';
import { PhotoIcn } from '../../utils/AdminGlobalStyles';
import ModalComponent from '../modalComponents/config/ModalComponent';
import { AnimatePresence } from 'framer-motion';
import { MdEdit } from 'react-icons/md';
import EditAdminAccount from '../modalComponents/EditUser/EditAdminAccount';
import { useDeleteAccount, useUpdateAccount } from '../../hooks/useAccounts';
import { toast } from 'react-toastify';
import { useCreateModal } from '../../../utils/useCreateModal';

const TeamsWrapper = styled.div`
    //padding: 4% 8%;
    display: grid;
    gap: 2em;
    grid-template-columns: 1fr 1fr 1fr;
`;

const TeamTitle = styled.h3`
    font-weight: 500;
    font-size: 1em;
    color: #9ba298;
    font-family: 'Oswald', sans-serif;
`;

const EachAdminWrapper = styled.div`
    width: auto;
    height: 70px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 16px;
    padding: 0 0.5em 0 0;
    transition: all 0.1s ease-in-out;

    & > div:first-child {
        display: inline-flex;
        gap: 2em;
        align-items: center;
    }

    &:hover {
        background-color: #fcfcfc;
        border: 1px solid #eaeaea;
    }
`;

export const DeleteMenuItem = styled(MenuItem)`
    color: red !important;
    padding: 0.8em !important;

    &:hover {
        background: #ed434314 !important;
    }
`;

const AdminName = styled.h2`
    font-size: 1.2em;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    padding: 0;
    margin: 0;
`;

const AdminEmail = styled.p`
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #9ba298;
    letter-spacing: 0.1px;
    padding: 0;
    margin: 0;
`;

interface AdminProps {
    admin_accounts: any | null;
}

const AdminTeam = (props: AdminProps) => {
    const { admin_accounts } = props;

    const modalAdminUser = useCreateModal();

    const updateAcc = useUpdateAccount();
    const deleteAcc = useDeleteAccount();

    const [freezeVal, setFreezeVal] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLImageElement | null>(null);

    const [itemData, setItemData] = useState<AdminListProps | null>();

    const open = Boolean(anchorEl);

    const handleClick = (
        event: React.MouseEvent<HTMLImageElement>,
        item: AdminListProps
    ) => {
        event.stopPropagation();
        setItemData(item);
        // setFreezeVal(freeze_val);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <TeamTitle>ECT TEAM ADMINS</TeamTitle>
            <TeamsWrapper>
                {admin_accounts?.map(
                    (item: AdminListProps, index: React.Key | null | undefined) => {
                        return (
                            <EachAdminWrapper key={index}>
                                <div>
                                    <PhotoIcn>
                                        <img
                                            style={{
                                                borderRadius: '16px',
                                                objectFit: 'cover',
                                            }}
                                            width={'72px'}
                                            height={'72px'}
                                            src={
                                                item.photo_url
                                                    ? item.photo_url
                                                    : `${FOO_IMG}`
                                            }
                                            alt="user"
                                        />
                                    </PhotoIcn>
                                    <div>
                                        <AdminName>{item.display_name}</AdminName>
                                        <AdminEmail>{item.email}</AdminEmail>
                                    </div>
                                </div>

                                <div>
                                    <EditDots
                                        src={DOTS}
                                        alt="edit"
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onClick={(e) => {
                                            setFreezeVal(item.disabled);
                                            handleClick(e, item);
                                        }}
                                    />
                                </div>
                            </EachAdminWrapper>
                        );
                    }
                )}
            </TeamsWrapper>
            <EditMenu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    style={{ width: '12em', padding: '0.8em' }}
                    onClick={() => {
                        modalAdminUser.openModal();
                        setAnchorEl(null);
                    }}
                >
                    <EditMenuItem>
                        <MdEdit style={{ fontSize: '1.1rem' }} />
                        Edit Admin
                    </EditMenuItem>
                </MenuItem>

                <MenuItem
                    style={{ width: '12em', padding: '0.8em' }}
                    onClick={() => {
                        setItemData((prevState) => {
                            if (prevState) {
                                let itemData: any = {
                                    ...prevState,
                                    disabled: !freezeVal,
                                };
                                updateAcc.mutateAsync({ itemData: itemData }).then(() => {
                                    toast.success('Account updated successfully');
                                    handleClose();
                                });
                                return itemData;
                            }
                        });

                        setFreezeVal((prevState) => !prevState);
                    }}
                >
                    <EditMenuItem>
                        {freezeVal ? (
                            <>
                                <FreezeIcon change_color={'#00f'} />
                                Unfreeze Account
                            </>
                        ) : (
                            <>
                                <FreezeIcon change_color={'#111'} />
                                Freeze Account
                            </>
                        )}
                    </EditMenuItem>
                </MenuItem>
                <DeleteMenuItem
                    onClick={() => {
                        if (
                            window.confirm(
                                'Are you sure you want to delete this account?'
                            )
                        ) {
                            const userInfo = {
                                uid: itemData?.uid,
                                delete_account: true,
                            };

                            deleteAcc.mutateAsync({ data: userInfo }).then(() => {
                                toast.success('Account deleted successfully');
                                handleClose();
                            });
                        }
                    }}
                >
                    <EditMenuItem>
                        <DeleteIcon />
                        Delete Account
                    </EditMenuItem>
                </DeleteMenuItem>
            </EditMenu>

            <AnimatePresence initial={false} mode={'wait'}>
                {modalAdminUser.modal && itemData && (
                    <ModalComponent
                        key={'config-worker'}
                        open={modalAdminUser.openModal}
                        handleClose={modalAdminUser.closeModal}
                        width={'25'}
                        min_height={'200px'}
                    >
                        <EditAdminAccount
                            itemData={itemData}
                            setItemData={setItemData}
                            handleClose={modalAdminUser.closeModal}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
            {/*<AnimatePresence initial={false} mode={'wait'}>*/}
            {/*    {modalAdminUser && itemData && (*/}
            {/*        <ModalComponent*/}
            {/*            key={'config-worker'}*/}
            {/*            open={openEditAdminModal}*/}
            {/*            handleClose={closeEditAdminModal}*/}
            {/*            min_width={'15%'}*/}
            {/*            width={'350px'}*/}
            {/*            min_height={'200px'}*/}
            {/*        >*/}
            {/*<DeleteAccount*/}
            {/*    itemData={itemData}*/}
            {/*    setItemData={setItemData}*/}
            {/*    handleClose={closeEditAdminModal}*/}
            {/*/>*/}
            {/*        </ModalComponent>*/}
            {/*    )}*/}
            {/*</AnimatePresence>*/}
        </>
    );
};

export default AdminTeam;
