import React, { useState } from 'react';
import DOTS from '../../static/icons/three-dots.svg';
import styled from 'styled-components';

import { MenuItem } from '@mui/material';
import { DeleteIcon, EditDots, EditMenu, EditMenuItem, FreezeIcon } from './commonStyled';
import { AdminListProps } from '../../@types/user';
// @ts-ignore
import FOO_IMG from '../../static/images/profile-picture.webp';
import { PhotoIcn } from '../../utils/AdminGlobalStyles';
import ModalComponent from '../modalComponents/config/ModalComponent';
import { AnimatePresence } from 'framer-motion';
import { MdEdit } from 'react-icons/md';
import EditAdminAccount from '../modalComponents/EditUser/EditAdminAccount';
import { useUpdateAccount } from '../../hooks/useAccounts';
import FreezeSalesman from '../modalComponents/FreezeSalesman';
import DeleteSalesmanAccount from '../modalComponents/DeleteUser/DeleteSalesmanAccount';
import { useCreateModal } from '../../../utils/useCreateModal';
import EditSalesmanAccount from '../modalComponents/EditUser/EditSalesmanAccount';

interface SalesmanProps {
    salesman_data: any | null;
}

const Salesman = (props: SalesmanProps) => {
    const { salesman_data } = props;

    const modalSalesman = useCreateModal();
    const modalSalesmanFreeze = useCreateModal();
    const modalSalesmanDelete = useCreateModal();

    const [freezeVal, setFreezeVal] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLImageElement | null>(null);

    const [itemData, setItemData] = useState<AdminListProps | null>();

    const open = Boolean(anchorEl);

    const handleClick = (
        event: React.MouseEvent<HTMLImageElement>,
        item: AdminListProps
    ) => {
        event.stopPropagation();
        setItemData(item);
        // setFreezeVal(freeze_val);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateAcc = useUpdateAccount();

    return (
        <>
            <TeamTitle>SALESMAN</TeamTitle>
            <TeamsWrapper>
                {salesman_data?.map(
                    (item: AdminListProps, index: React.Key | null | undefined) => {
                        return (
                            <EachSalesmanItem key={index}>
                                <div>
                                    <PhotoIcn>
                                        <img
                                            style={{
                                                borderRadius: '16px',
                                                objectFit: 'cover',
                                            }}
                                            width={'72px'}
                                            height={'72px'}
                                            src={
                                                item.photo_url
                                                    ? item.photo_url
                                                    : `${FOO_IMG}`
                                            }
                                            alt="user"
                                        />
                                    </PhotoIcn>
                                    <div>
                                        <SalesmanName>{item.display_name}</SalesmanName>
                                        <SalesmanEmail>{item.email}</SalesmanEmail>
                                    </div>
                                </div>

                                <div>
                                    <EditDots
                                        src={DOTS}
                                        alt="edit"
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onClick={(e) => {
                                            setFreezeVal(item.disabled);
                                            handleClick(e, item);
                                        }}
                                    />
                                </div>
                            </EachSalesmanItem>
                        );
                    }
                )}
            </TeamsWrapper>
            <EditMenu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    style={{ width: '12em', padding: '0.8em' }}
                    onClick={() => {
                        modalSalesman.openModal();
                        setAnchorEl(null);
                    }}
                >
                    <EditMenuItem>
                        <MdEdit style={{ fontSize: '1.1rem' }} />
                        Edit Salesman
                    </EditMenuItem>
                </MenuItem>

                <MenuItem
                    style={{ width: '12em', padding: '0.8em' }}
                    onClick={() => {
                        handleClose();
                        modalSalesmanFreeze.openModal();
                    }}
                >
                    <EditMenuItem>
                        {freezeVal ? (
                            <>
                                <FreezeIcon change_color={'#00f'} />
                                Unfreeze Account
                            </>
                        ) : (
                            <>
                                <FreezeIcon change_color={'#111'} />
                                Freeze Account
                            </>
                        )}
                    </EditMenuItem>
                </MenuItem>
                <DeleteMenuItem
                    onClick={() => {
                        handleClose();
                        modalSalesmanDelete.openModal();
                    }}
                >
                    <EditMenuItem>
                        <DeleteIcon />
                        Delete Account
                    </EditMenuItem>
                </DeleteMenuItem>
            </EditMenu>

            <AnimatePresence initial={false} mode={'wait'}>
                {modalSalesman.modal && itemData && (
                    <ModalComponent
                        key={'EditSalesmanAccount'}
                        open={modalSalesman.openModal}
                        handleClose={modalSalesman.closeModal}
                        width={'25'}
                        min_height={'200px'}
                    >
                        <EditSalesmanAccount
                            itemData={itemData}
                            setItemData={setItemData}
                            handleClose={modalSalesman.closeModal}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>

            <AnimatePresence initial={false} mode={'wait'}>
                {modalSalesmanFreeze.modal && (
                    <ModalComponent
                        key={'FreezeSalesman'}
                        open={modalSalesmanFreeze.openModal}
                        handleClose={modalSalesmanFreeze.closeModal}
                        width={'25'}
                        min_height={'200px'}
                    >
                        <FreezeSalesman handleClose={modalSalesmanFreeze.closeModal} />
                    </ModalComponent>
                )}
            </AnimatePresence>
            <AnimatePresence initial={false} mode={'wait'}>
                {modalSalesmanDelete.modal && (
                    <ModalComponent
                        key={'DeleteSalesmanAccount'}
                        open={modalSalesmanDelete.openModal}
                        handleClose={modalSalesmanDelete.closeModal}
                        width={'25'}
                        min_height={'200px'}
                    >
                        <DeleteSalesmanAccount
                            handleClose={modalSalesmanDelete.closeModal}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </>
    );
};

const TeamsWrapper = styled.div`
    //padding: 4% 8%;
    display: grid;
    gap: 2em;
    grid-template-columns: 1fr 1fr 1fr;
`;

const TeamTitle = styled.h3`
    font-weight: 500;
    font-size: 1em;
    color: #9ba298;
    font-family: 'Oswald', sans-serif;
`;

const EachSalesmanItem = styled.div`
    width: auto;
    height: 70px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 16px;
    padding: 0 0.5em 0 0;
    transition: all 0.1s ease-in-out;

    & > div:first-child {
        display: inline-flex;
        gap: 2em;
        align-items: center;
    }

    &:hover {
        background-color: #fcfcfc;
        border: 1px solid #eaeaea;
    }
`;

export const DeleteMenuItem = styled(MenuItem)`
    color: red !important;
    padding: 0.8em !important;

    &:hover {
        background: #ed434314 !important;
    }
`;

const SalesmanName = styled.h2`
    font-size: 1.2em;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    padding: 0;
    margin: 0;
`;

const SalesmanEmail = styled.p`
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #9ba298;
    letter-spacing: 0.1px;
    padding: 0;
    margin: 0;
`;

export default Salesman;
