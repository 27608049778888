import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import HeaderLeft from '../HeaderLeft';
import SideNav from '../sidenav/SideNav';
import HeaderRight from '../HeaderRight';
import { ButtonContainer, ButtonFooter, CustomTextInput } from '../../utils/GlobalStyles';
import { ReactComponent as EDIT_ROUND } from '../../static/icons/edit-round.svg';
import { ReactComponent as DELETE_ROUND } from '../../static/icons/delete-round.svg';
import { userObject } from '../../states/selectors';
import { useGetAccountDetails, useUpdateAccountDetails } from '../hooks/useAccounts';
import { toast } from 'react-toastify';
import { InputBox } from '../../utils/InputBox';

export const dummy =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfpIaGerw5l637QyCc2ZwUJH1KMMzipcYsMxAbDDg2_3zZE6kHxcHyG3caDBMHCFAl_c0&usqp=CAU';

export default function Profile() {
    const { data: user } = useGetAccountDetails();

    const [profile_image, setProfileImage] = useState<string | ArrayBuffer | null>(dummy);
    const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);

    const [displayName, setDisplayName] = useState('');

    const updateUserDetails = useUpdateAccountDetails();

    useEffect(() => {
        setDisplayName(user?.display_name);
    }, [user]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfileImage('');
        setSelectedImageFile(null);
        const reader = new FileReader();
        reader.onloadend = () => {
            setProfileImage(reader.result);
        };

        if (e.target.files) {
            reader.readAsDataURL(e.target.files[0]);
            setSelectedImageFile(e.target.files[0]);
        }
    };

    const handleChangeDetails = () => {
        const data = {
            display_name: displayName,
        };

        updateUserDetails.mutateAsync(data).then(() => {
            toast.success('Update Successful');
        });
    };

    return (
        <ProfileWrapper>
            <SideNav />
            <AccContent>
                <HeaderWrapper>
                    <HeaderLeft
                        title={user?.account_name}
                        subTitle={`${user?.workers_count} Workers & ${user?.hay_vision_fields_count} Fields`}
                    />
                    <HeaderRight text={'logout'} />
                </HeaderWrapper>

                <ProfileContentBox>
                    <ImageWrapper>
                        <ImgContent>
                            <img width={180} src={profile_image as string} alt="logo" />

                            <div className="edit_del">
                                <DeleteRound
                                    onClick={() => {
                                        if (
                                            window.confirm(
                                                'Are you sure you want to delete?'
                                            )
                                        ) {
                                            setProfileImage(dummy);
                                            setSelectedImageFile(null);
                                        }
                                    }}
                                />

                                <input
                                    id="upload-id"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="upload-id" className="input-file-text">
                                    <EditRound />
                                </label>
                            </div>
                        </ImgContent>
                    </ImageWrapper>

                    <InputBox
                        val={displayName}
                        setVal={setDisplayName}
                        label={'Full Name'}
                        type={'text'}
                    />

                    <ButtonContainer>
                        <ButtonFooter
                            onClick={() => {
                                // setIsClicked(false)
                            }}
                        >
                            Reset
                        </ButtonFooter>
                        <ChangeDetailsBtn onClick={() => handleChangeDetails()}>
                            Save
                        </ChangeDetailsBtn>
                    </ButtonContainer>
                </ProfileContentBox>
            </AccContent>
        </ProfileWrapper>
    );
}

const ProfileWrapper = styled.div`
    background-color: transparent;
    /* padding-left: 4%; */
    /* padding-right: 4%; */
    width: 100%;
    overflow-x: hidden;
    display: grid;
    height: 100vh;
    padding: 0;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'side-nav acc-content';
`;

const HeaderWrapper = styled.div`
    grid-area: acc-header;
    background-color: white;
    padding: 0 4em;
    height: 18vh;
    overflow-x: hidden;
    border-bottom: 1px solid #e9eced;
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 1fr;
    grid-template-areas: 'header-left-content . header-right-content';
`;

const AccContent = styled.div`
    grid-area: acc-content;
`;

const ProfileContentBox = styled.div`
    width: 300px;
    margin: 4em;
    display: grid;
`;

const ChangeDetailsBtn = styled.button`
    background: #5ea73c;
    padding: 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

const ImageWrapper = styled.div`
    width: 100%;
    position: relative;
    display: grid;
    justify-content: center;
`;

const ImgContent = styled.div`
    width: 180px;
    height: 180px;
    padding: 4px;
    margin-bottom: 1.5em;
    border-radius: 50%;

    .edit_del {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & > img {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        object-fit: cover;
    }
`;

const EditRound = styled(EDIT_ROUND)`
    cursor: pointer;

    &:hover {
        transition: 0.3s;
        transform: scale(1.1);
    }
`;
const DeleteRound = styled(DELETE_ROUND)`
    cursor: pointer;

    &:hover {
        transition: 0.3s;
        transform: scale(1.1);
    }
`;
