import React from 'react';
import { HaySwitchWrapper, SwitchHayVision } from './switchstyle';
import { SwitchProps } from './interfaces';

export default function HayVisionSwitch(props: SwitchProps) {
    const { hidebutton } = props;
    return (
        <HaySwitchWrapper style={{ opacity: hidebutton }}>
            <SwitchHayVision
                focusVisibleClassName=".Mui-focusVisible"
                disableRipple
                {...props}
            />
        </HaySwitchWrapper>
    );
}
