import React, { useState } from 'react';
import styled from 'styled-components';
import ADMIN_LOGO from '../../static/images/logo-admin.svg';
import MENU from '../../static/icons/nav-menu.svg';
import { ReactComponent as PROFILE } from '../../static/icons/profile.svg';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import { EditMenu, EditMenuItem } from '../dashboard/commonStyled';
import { DeleteMenuItem } from '../dashboard/AdminTeam';
import { MdLogout } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiArrowLeftSLine, RiSearchLine } from 'react-icons/ri';
import { AnimatePresence } from 'framer-motion';
import ModalComponent from '../modalComponents/config/ModalComponent';
import CreateUserAccount from '../modalComponents/CreateUser/CreateUserAccount';
import LogoutAccount from '../modalComponents/LogoutAccount';
import CreateAdminAccount from '../modalComponents/CreateUser/CreateAdminAccount';
import { useNavigate } from 'react-router-dom';
import CreateSalesmanAccount from '../modalComponents/CreateUser/CreateSalesmanAccount';
import { setSelectedUser } from '../../../states/actions';
import { useDispatch } from 'react-redux';

const NavBarWrapper = styled.div`
    background-color: transparent;
    overflow-x: hidden;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
`;

const RightContainer = styled.div`
    grid-area: right_header;
    display: inline-flex;
    gap: 2em;
    justify-content: center;
    align-items: center;

    //justify-content: right;
`;

const LeftContainer = styled.div`
    grid-area: left_header;
    display: flex;
    /*align-content: center;*/
    align-items: center;
    //padding: 2em 0;
    gap: 0.5em;
    margin: 0 1em;
    width: auto;
`;

const SearchInput = styled.input`
    background-color: #f4f4f4;
    border: 1px solid #e5e5e5;
    width: 30em;
    color: #374630;
    border-radius: 5px;
    padding: 0.8em 1em;
    transition: 0.2s ease all;
    font-family: 'Poppins', sans-serif;
`;

const TextInput = styled(TextField)`
    margin: 8px 0 !important;
`;

const CustomSearchInput = styled(TextInput)({
    '& label': {
        color: '#98A3AA',
        fontSize: 14,
        lineHeight: '1.5em',
        fontFamily: "'Poppins', sans-serif !important",
    },
    '& label.Mui-focused': {
        color: '#98A3AA',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
        backgroundColor: '#fff',
        color: '#111',
    },

    '& .MuiOutlinedInput-root': {
        overflow: 'hidden',
        borderRadius: 8,
        border: '1px solid #e5e5e5',
        height: 40,
        fontSize: 13,
        width: '35em',
        color: '#374630',
        fontWeight: 500,
        fontFamily: "'Poppins', sans-serif !important",
        backgroundColor: '#f4f4f4',

        '&.Mui-focused': {
            backgroundColor: '#f4f4f4',
        },
        '& fieldset': {
            borderColor: '#e5e5e5',
        },
        '&:hover fieldset': {
            borderColor: '#e5e5e5',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#e5e5e5',
        },
    },
});

const MenuIcon = styled.img`
    cursor: pointer;
    border-radius: 50px;
    margin-right: 1em;
    transition: all 0.1s ease-in-out;

    &:hover {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    }
`;

const BackArrow = styled(RiArrowLeftSLine)`
    font-size: 2em;
    padding: 0.2em;
    border: 1px solid white;
    position: absolute;
    left: 20px;

    &:hover {
        border-radius: 50%;
        border: 1px solid #e5e5e5;
        background-color: #fcfcfc;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
`;

interface NavProps {
    search_on?: boolean;
    searchInput?: string;
    setSearchInput?: React.Dispatch<React.SetStateAction<string>>;
}

NavBar.defaultProps = {
    search_on: true,
};

function NavBar(props: NavProps) {
    const navigate = useNavigate();

    const { searchInput, setSearchInput } = props;

    const dispatch = useDispatch();

    const [modalCreateAdmin, setModalCreateAdmin] = useState(false);
    const closeCreateModal = () => setModalCreateAdmin(false);
    const openCreateModal = () => setModalCreateAdmin(true);

    const [modalCreateSalesman, setModalCreateSalesman] = useState(false);
    const closeCreateSalesmanModal = () => setModalCreateSalesman(false);
    const openCreateSalesmanModal = () => setModalCreateSalesman(true);

    const [modalLogout, setModalLogout] = useState(false);
    const closeLogoutModal = () => setModalLogout(false);
    const openLogoutModal = () => setModalLogout(true);

    const [modalCreateUser, setModalCreateUser] = useState(false);
    const closeCreateUserModal = () => setModalCreateUser(false);
    const openCreateUserModal = () => setModalCreateUser(true);

    const [anchorEl, setAnchorEl] = React.useState<HTMLImageElement | null>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <NavBarWrapper>
            <LeftContainer>
                <BackArrow
                    onClick={() => {
                        dispatch(setSelectedUser(null));
                        navigate(-1);
                    }}
                />
                <img src={ADMIN_LOGO} alt="logo" />
            </LeftContainer>

            <RightContainer>
                {props.search_on && (
                    <>
                        <div>
                            {/*<SearchInput*/}
                            {/*    placeholder={'Search using keywords...'}*/}
                            {/*    value={searchInput}*/}
                            {/*    onChange={(e) =>*/}
                            {/*        setSearchInput*/}
                            {/*            ? setSearchInput(e.target.value)*/}
                            {/*            : null*/}
                            {/*    }*/}
                            {/*/>*/}

                            <CustomSearchInput
                                placeholder={'Search using keywords...'}
                                type="text"
                                value={searchInput}
                                onChange={(e: {
                                    target: {
                                        value: React.SetStateAction<string>;
                                    };
                                }) =>
                                    setSearchInput ? setSearchInput(e.target.value) : null
                                }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <RiSearchLine />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div>
                            <MenuIcon
                                src={MENU}
                                alt="menu"
                                onMouseDown={(e) => e.stopPropagation()}
                                onClick={(e) => handleClick(e)}
                            />
                        </div>
                    </>
                )}
            </RightContainer>

            <EditMenu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    style={{ width: '12em', padding: '0.8rem' }}
                    onClick={() => navigate('/profile')}
                >
                    <EditMenuItem>
                        <PROFILE style={{ fontSize: '1.1rem' }} />
                        My Profile
                    </EditMenuItem>
                </MenuItem>

                <MenuItem
                    style={{ width: '12em', padding: '0.8rem' }}
                    onClick={() => {
                        modalCreateUser ? closeCreateUserModal() : openCreateUserModal();

                        setAnchorEl(null);
                    }}
                >
                    <EditMenuItem>
                        <AiOutlinePlus style={{ fontSize: '1.1rem' }} />
                        New Account
                    </EditMenuItem>
                </MenuItem>
                <MenuItem
                    style={{ width: '12em', padding: '0.8rem' }}
                    onClick={() => {
                        modalCreateAdmin ? closeCreateModal() : openCreateModal();

                        setAnchorEl(null);
                    }}
                >
                    <EditMenuItem>
                        <AiOutlinePlus style={{ fontSize: '1.1rem' }} />
                        New Admin
                    </EditMenuItem>
                </MenuItem>
                <MenuItem
                    style={{ width: '12em', padding: '0.8rem' }}
                    onClick={() => {
                        modalCreateSalesman
                            ? closeCreateSalesmanModal()
                            : openCreateSalesmanModal();

                        setAnchorEl(null);
                    }}
                >
                    <EditMenuItem>
                        <AiOutlinePlus style={{ fontSize: '1.1rem' }} />
                        New Salesman
                    </EditMenuItem>
                </MenuItem>

                <DeleteMenuItem
                    onClick={() => {
                        modalLogout ? closeLogoutModal() : openLogoutModal();
                        setAnchorEl(null);
                    }}
                >
                    <EditMenuItem>
                        <MdLogout style={{ fontSize: '1.1rem' }} />
                        Log Out
                    </EditMenuItem>
                </DeleteMenuItem>
            </EditMenu>

            <AnimatePresence initial={false} mode={'wait'}>
                {modalCreateAdmin && (
                    <ModalComponent
                        key={'config-worker'}
                        open={openCreateModal}
                        handleClose={closeCreateModal}
                        width={'25'}
                        min_height={'200px'}
                    >
                        <CreateAdminAccount handleClose={closeCreateModal} />
                    </ModalComponent>
                )}
            </AnimatePresence>

            <AnimatePresence initial={false} mode={'wait'}>
                {modalCreateSalesman && (
                    <ModalComponent
                        key={'config-worker'}
                        open={openCreateModal}
                        handleClose={closeCreateSalesmanModal}
                        width={'25'}
                        min_height={'200px'}
                    >
                        <CreateSalesmanAccount handleClose={closeCreateSalesmanModal} />
                    </ModalComponent>
                )}
            </AnimatePresence>

            <AnimatePresence initial={false} mode={'wait'}>
                {modalCreateUser && (
                    <ModalComponent
                        key={'config-worker'}
                        open={openCreateUserModal}
                        handleClose={closeCreateUserModal}
                        width={'25'}
                        min_height={'200px'}
                    >
                        <CreateUserAccount handleClose={closeCreateUserModal} />
                    </ModalComponent>
                )}
            </AnimatePresence>

            <AnimatePresence initial={false} mode={'wait'}>
                {modalLogout && (
                    <ModalComponent
                        key={'config-worker'}
                        open={openLogoutModal}
                        handleClose={closeLogoutModal}
                        width={'25'}
                        min_height={'200px'}
                    >
                        <LogoutAccount handleClose={closeLogoutModal} />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </NavBarWrapper>
    );
}

export default NavBar;
