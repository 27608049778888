import React from 'react';

import styled from 'styled-components';
import HeaderLeft from '../HeaderLeft';
import SideNav from '../sidenav/SideNav';
import HeaderRight from '../HeaderRight';
import LOCATION from '../../static/images/map.png';
import { ReactComponent as MAIL_ICON } from '../../static/icons/mail.svg';
import { ReactComponent as PHONE_BG_ICON } from '../../static/icons/phone-bg.svg';
import { dummy } from './Profile';
import { userObject } from '../../states/selectors';
import { useGetAccountDetails } from '../hooks/useAccounts';

const ContactsWrapper = styled.div`
    background-color: transparent;
    width: 100%;
    overflow-x: hidden;
    display: grid;
    height: 100vh;
    padding: 0;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'side-nav acc-content';
`;

const HeaderWrapper = styled.div`
    grid-area: acc-header;
    background-color: white;
    padding: 0 4em;
    height: 18vh;
    overflow-x: hidden;
    border-bottom: 1px solid #e9eced;
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 1fr;
    grid-template-areas: 'header-left-content . header-right-content';
`;

const AccContent = styled.div`
    grid-area: acc-content;
`;

const ContactsContentBox = styled.div`
    width: 350px;
    margin: 2em 4em;
    display: grid;
    justify-content: center;
`;

const ContactPerson = styled.div`
    display: inline-flex;
`;

const LocationSection = styled.div`
    display: inline-flex;
`;

const ContactTeam = styled.div`
    width: 100%;
    margin: 2em 0.2em;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e7eaeb;
    background: #fafafa;
`;
const PersonContact = styled.div`
    font-family: 'Poppins', sans-serif;
    padding: 0 28px;
`;

const ImgContent = styled.div`
    width: max-content;
    padding: 4px;
`;

const Heading = styled.h1`
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 1px;
    margin-bottom: 0;
    font-family: 'Oswald', sans-serif;
`;

const SubHeading = styled.p`
    font-size: 14px;
    font-weight: 500;
    margin: 6px 0;
    color: #999;
`;

const HeadingSmall = styled(Heading)`
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    padding: 0;
`;

const HeadingLight = styled(Heading)`
    font-weight: 600;
    font-size: 14px;
    color: #9ba298;
`;

const SubHeadingDark = styled.p`
    font-size: 12px;
    font-weight: 400;
    margin: 6px 0;
    color: #414149;
`;

const Icon = styled.span`
    display: inline-flex;
    width: 100%;
    margin-top: 5px;

    & > svg {
        margin-right: 16px;
    }
`;

export default function Contacts() {
    const { data: user } = useGetAccountDetails();
    return (
        <ContactsWrapper>
            <SideNav />
            <AccContent>
                <HeaderWrapper>
                    <HeaderLeft
                        title={user?.account_name}
                        subTitle={`${user?.workers_count} Workers & ${user?.hay_vision_fields_count} Fields`}
                    />
                    <HeaderRight text={'social'} />
                </HeaderWrapper>

                <ContactsContentBox>
                    <ContactPerson>
                        <ImgContent>
                            <img width={155} src={dummy} alt="logo" />
                        </ImgContent>

                        <PersonContact>
                            <Heading>TONY ARRO</Heading>
                            <SubHeading>+1 (225) 555-0118</SubHeading>
                            <SubHeading>arro@energycurve.com</SubHeading>
                            <Icon>
                                <PHONE_BG_ICON /> <MAIL_ICON />
                            </Icon>
                        </PersonContact>
                    </ContactPerson>

                    <ContactTeam>
                        <HeadingSmall>CONTACT ECT TEAM</HeadingSmall>
                        <SubHeadingDark>
                            If you have any request that might concern our team directly
                            then feel free to contact us at:
                        </SubHeadingDark>
                        <span
                            style={{
                                display: 'inline-flex',
                                justifyContent: 'space-between',
                                width: '80%',
                            }}
                        >
                            <SubHeadingDark>
                                <strong>+1 (888) 673-2363 </strong>
                            </SubHeadingDark>
                            <SubHeadingDark>
                                <strong>support@energycurve.com</strong>
                            </SubHeadingDark>
                        </span>

                        <LocationSection>
                            <ImgContent>
                                <img width={90} src={LOCATION} alt="logo" />
                            </ImgContent>

                            <div style={{ margin: '0 16px' }}>
                                <HeadingLight>OFFICE LOCATION</HeadingLight>
                                <SubHeadingDark>
                                    <strong>
                                        302 S. Main St. <br />
                                        Archie, Missouri 64725
                                    </strong>
                                </SubHeadingDark>
                            </div>
                        </LocationSection>
                    </ContactTeam>
                </ContactsContentBox>
            </AccContent>
        </ContactsWrapper>
    );
}
