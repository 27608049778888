import React from 'react';
import styled from 'styled-components';
import { ButtonContainer, ButtonFooter } from '../../admin/utils/AdminGlobalStyles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/UserContextProvider';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

interface Props {
    dateValue: any;
    handleClose: () => void;
}

const NotifyWrapper = styled.div`
    padding: 1em;
    color: #414149;
`;

const Heading = styled.h1`
    margin: 0;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #9ba298;
`;

const Text = styled.p`
    margin: 1em 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    flex-direction: column;

    span {
        font-weight: 700;
    }
`;

const ContinueBtn = styled.div`
    background: #5ea73c;
    padding: 0.8rem 3rem;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: max-content;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

const ButtonCont = styled(ButtonContainer)`
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
`;

const NotifyUserQuickGerm = (props: Props) => {
    const { dateValue } = props;

    return (
        <NotifyWrapper>
            <Heading>Apply Quick Germ</Heading>
            <Text>
                Don’t forget to apply Quick Germ on{' '}
                <span>{format(new Date(dateValue), 'dd MMM, yyyy')}!</span>
            </Text>
            <ButtonCont>
                <ContinueBtn
                    onClick={() => {
                        props.handleClose();
                    }}
                >
                    Continue
                </ContinueBtn>
            </ButtonCont>
        </NotifyWrapper>
    );
};

export default NotifyUserQuickGerm;
