import React from 'react';
import styled from 'styled-components';

interface Props {
    message?: string;
    color?: string;
}

const Text = styled.p`
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: ${(props) => props.color || '#414149'};
`;

const ErrorDisplay: React.FC<Props> = (props) => {
    return <Text color={props.color}>{props.message}</Text>;
};

export default ErrorDisplay;
