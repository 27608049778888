import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CREATE_NEW_ADMIN, CREATE_NEW_SALESMAN, GET_ALL_ADMINS } from '../../config';
import axiosInstance from '../../services/axiosInstance';
import { toast } from 'react-toastify';

const getAllAdmin = () => {
    return axiosInstance.get(GET_ALL_ADMINS).then((res) => res.data);
};

const addAdmin = ({ addDataAdmin }: { addDataAdmin: any }) => {
    return axiosInstance.post(CREATE_NEW_ADMIN, addDataAdmin).then((res) => res.data);
};

export function useGetAllAdmins() {
    return useQuery('allAdmins', () => getAllAdmin(), {
        onError: (err) => {
            console.log(err);
        },
    });
}

export function useCreateAdmin() {
    const queryClient = useQueryClient();
    return useMutation(addAdmin, {
        onSuccess: () => {
            queryClient.invalidateQueries(['allAdmins']).then(() => {});
        },
        onError: (err) => {
            console.error(err);
            toast.error("Couldn't create Admin");
        },
    });
}
