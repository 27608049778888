import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import HeaderLeft from '../HeaderLeft';
import SideNav from '../sidenav/SideNav';
import HeaderRight from '../HeaderRight';
import {
    ButtonContainer,
    ButtonFooter,
    CustomNotifyInput,
} from '../../utils/GlobalStyles';
import { IconButton, InputAdornment } from '@mui/material';

import { ReactComponent as Subtract } from '../../static/icons/subtract.svg';
import { ReactComponent as Add } from '../../static/icons/add.svg';
import { selectedUser } from '../../states/selectors';
import { useGetAccountDetails, useUpdateAccountDetails } from '../hooks/useAccounts';
import { toast } from 'react-toastify';
import { InputBox } from '../../utils/InputBox';

export default function AccountSettings() {
    const selectedAccId = selectedUser();

    const { data: userDetails } = useGetAccountDetails();
    const updateUserDetails = useUpdateAccountDetails();

    const [accountName, setAccountName] = useState('');
    const [accountEmail, setAccountEmail] = useState('');
    const [notifyCounter, setNotifyCounter] = useState(0);

    useEffect(() => {
        if (userDetails) {
            setAccountName(userDetails.account_name || '');
            setAccountEmail(userDetails.email || '');
            setNotifyCounter(userDetails.notify_days || 0);
        }
    }, [userDetails]);

    function handleAdd(val: number) {
        if (val < 14) {
            setNotifyCounter((prevState) => prevState + 1);
        }
    }

    function handleSubtract() {
        if (notifyCounter > 1) {
            setNotifyCounter((prevState) => prevState - 1);
        }
    }

    const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
        event.preventDefault();
    };

    const handleSaveDetails = () => {
        const data = {
            account_name: accountName,
            notify_days: notifyCounter,
            uid: selectedAccId,
        };

        updateUserDetails.mutateAsync(data).then(() => {
            toast.success('Update Successful');
        });
    };

    return (
        <AccountSettingsWrapper>
            <SideNav />
            <AccContent>
                <HeaderWrapper>
                    <HeaderLeft
                        title={userDetails?.account_name}
                        subTitle={`${userDetails?.workers_count} Workers & ${userDetails?.hay_vision_fields_count} Fields`}
                    />
                    <HeaderRight text={'delete'} />
                </HeaderWrapper>

                <AccContentBox>
                    <InputBox
                        type={'text'}
                        itemProps={null}
                        label={'Account Name'}
                        val={accountName}
                        setVal={setAccountName}
                    />

                    <InputBox
                        type={'email'}
                        itemProps={null}
                        label={'Email'}
                        val={accountEmail}
                        // setVal={setAccountEmail}
                    />

                    <CustomNotifyInput
                        label="Notify members"
                        id="outlined-adornment"
                        disabled
                        value={'days before due date'}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <NotifyCount>
                                        {notifyCounter &&
                                            notifyCounter.toLocaleString('en-US', {
                                                minimumIntegerDigits: 2,
                                                useGrouping: false,
                                            })}
                                    </NotifyCount>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        disableTouchRipple={true}
                                        aria-label="toggle sub visibility"
                                        onClick={() => handleSubtract()}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        <SubtractButton />
                                    </IconButton>
                                    <IconButton
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        disableTouchRipple={true}
                                        aria-label="toggle add visibility"
                                        onClick={() => handleAdd(notifyCounter)}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        <AddButton />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                    />

                    <ButtonContainer>
                        <ButtonFooter
                            onClick={() => {
                                // setIsClicked(false)
                            }}
                        >
                            Reset
                        </ButtonFooter>
                        <ChangeDetailsBtn onClick={() => handleSaveDetails()}>
                            Save
                        </ChangeDetailsBtn>
                    </ButtonContainer>
                </AccContentBox>
            </AccContent>
        </AccountSettingsWrapper>
    );
}

const AccountSettingsWrapper = styled.div`
    background-color: transparent;
    width: 100%;
    overflow-x: hidden;
    display: grid;
    height: 100vh;
    padding: 0;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'side-nav acc-content';
`;

const HeaderWrapper = styled.div`
    grid-area: acc-header;
    background-color: white;
    padding: 0 4em;
    height: 18vh;
    overflow-x: hidden;
    border-bottom: 1px solid #e9eced;
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 1fr;
    grid-template-areas: 'header-left-content . header-right-content';
`;

const AccContent = styled.div`
    grid-area: acc-content;
`;

const AccContentBox = styled.div`
    width: 350px;
    margin: 4em;
    display: grid;
    justify-content: center;
`;

const ChangeDetailsBtn = styled.button`
    background: #5ea73c;
    padding: 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

export const SubtractButton = styled(Subtract)`
    &:hover > rect {
        fill: #eaeaea;
    }
`;

export const AddButton = styled(Add)`
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &:hover > rect {
        fill: #eaeaea;
    }
`;

export const NotifyCount = styled.p`
    font-weight: 600;
    color: black;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
`;
