import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    CREATE_NEW_ACCOUNT,
    DELETE_ADMIN_ACCOUNT,
    GET_ALL_ACCOUNTS,
    UPDATE_ACCOUNT_DETAILS,
} from '../../config';
import { toast } from 'react-toastify';

import { AccountListProps } from '../@types/user';
import axiosInstance from '../../services/axiosInstance';

export const freezeUnfreezeAccount = () => {
    return axios({}).then((res) => (res.status === 200 ? res.data : null));
};

const all_accounts = () => {
    return axiosInstance.get(GET_ALL_ACCOUNTS).then((res) => res.data);
};

const addAccounts = ({ addDataAcc }: { addDataAcc: any }) => {
    return axiosInstance.post(CREATE_NEW_ACCOUNT, addDataAcc).then((res) => res.data);
};

const update_account = ({ itemData }: { itemData: AccountListProps | any }) => {
    return axiosInstance.post(UPDATE_ACCOUNT_DETAILS, itemData).then((res) => res.data);
};

export function useGetAllAccounts() {
    return useQuery(['allAccounts'], all_accounts, {
        onError: (err) => {
            console.log(err);
        },
    });
}

export function useCreateAccounts() {
    const queryClient = useQueryClient();
    return useMutation(addAccounts, {
        onSuccess: () => {
            queryClient.invalidateQueries(['allAccounts']).then(() => {});
        },
        onError: (err) => {
            console.error(err);
            toast.error("Couldn't create Account");
        },
    });
}

export function useUpdateAccount() {
    const queryClient = useQueryClient();

    return useMutation(update_account, {
        onSuccess: () => {
            queryClient.invalidateQueries(['allAccounts']);
            queryClient.invalidateQueries(['allAdmins']);
        },
        onError: (err) => {
            console.error(err);
        },
    });
}

const deleteAccount = ({ data }: { data: any }) => {
    return axiosInstance.post(DELETE_ADMIN_ACCOUNT, data).then((res) => res.data);
};

export const useDeleteAccount = () => {
    const queryClient = useQueryClient();

    return useMutation(deleteAccount, {
        onSuccess: () => {
            queryClient.invalidateQueries(['allAccounts']);
            queryClient.invalidateQueries(['allAdmins']);
        },
        onError: (err) => {
            console.error(err);
            toast.error("Couldn't delete Account");
        },
    });
};
