import { Navigate, Outlet } from 'react-router-dom';
import { selectFirebaseToken } from '../states/selectors';

function ProtectedRoute() {
    if (selectFirebaseToken()) {
        return <Outlet />;
    } else {
        return <Navigate to="/" replace={true} />;
    }
}

export default ProtectedRoute;
