import * as actions from './actionTypes';

export const setFirebaseToken = (value: string | null) => {
    return {
        type: actions.FIREBASE_TOKEN,
        payload: {
            value: value || null,
        },
    };
};

export const setUserEmail = (value: string | null) => {
    return {
        type: actions.USER_EMAIL,
        payload: {
            value: value || null,
        },
    };
};

export const setUserObject = (value: any | null) => {
    return {
        type: actions.USER_DETAILS,
        payload: {
            value: value || null,
        },
    };
};

export const setSelectedUser = (value: string | null) => {
    return {
        type: actions.SELECTED_USER,
        payload: {
            value: value || null,
        },
    };
};
