import React, { useState } from 'react';
import styled from 'styled-components';
import {
    ButtonContainer,
    ButtonFooter,
    CustomTextInput,
} from '../../../utils/AdminGlobalStyles';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';

const DeleteAccountWrapper = styled.div`
    padding: 1em;
    color: #414149;
`;

const Heading = styled.h1`
    margin: 0;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #9ba298;
`;

const DeleteText = styled.p`
    margin: 1em 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
`;

const DeleteAccountBtn = styled.div`
    background: #ed4343;
    padding: 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

const ButtonCont = styled(ButtonContainer)`
    padding: 1rem 0 0 0;
`;

const Menu = styled(MenuItem)`
    font-family: 'Poppins', sans-serif;
`;

interface Props {
    handleClose: () => void;
}

const DeleteSalesmanAccount = (props: Props) => {
    const [deleteText, setDeleteText] = useState('');

    const [futureSalesman, setFutureSalesman] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setFutureSalesman(event.target.value as string);
    };

    return (
        <DeleteAccountWrapper>
            <Heading>Delete Salesman?</Heading>
            <DeleteText>
                You will have to contact our ECT Team within 30 days after you delete this
                account to recover it back! If you want to continue deleting this account,
                type in <strong>‘DELETE’</strong> in the field below.
            </DeleteText>
            <CustomTextInput
                fullWidth
                label="Type ‘DELETE’ here to continue"
                type="text"
                value={deleteText}
                onChange={(e) => setDeleteText(e.target.value.toUpperCase())}
            />
            <DeleteText>
                All the clients from current salesman will be transferred to “Future
                Salesman”, so please select the future salesman of your choice.
            </DeleteText>
            <FormControl fullWidth>
                <InputLabel id="select-label">Future Salesman</InputLabel>
                <Select
                    labelId="select-label"
                    id="select-label"
                    value={futureSalesman}
                    label="Future Salesman"
                    onChange={handleChange}
                >
                    <Menu value={'Buddy Sears 1'}>Buddy Sears 1</Menu>
                    <Menu value={'Buddy Sears 2'}>Buddy Sears 2</Menu>
                    <Menu value={'Buddy Sears 3'}>Buddy Sears 3</Menu>
                </Select>
            </FormControl>
            <ButtonCont>
                <ButtonFooter onClick={() => props.handleClose()}>Close</ButtonFooter>
                <DeleteAccountBtn onClick={() => props.handleClose()}>
                    Delete
                </DeleteAccountBtn>
            </ButtonCont>
        </DeleteAccountWrapper>
    );
};

export default DeleteSalesmanAccount;
